import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

type Props = {
  title: String,
  open: Boolean,
  onClose: Function,
  onConfirm: Function,
};

const ReccurrenceActionDialog = ({ title, open, onClose, onConfirm }: Props) => {
  const { t } = useTranslation();

  const [actionChoice, setActionChoice] = React.useState('occurrence');

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <RadioGroup
          aria-label="actionChoice"
          name="actionChoice"
          value={actionChoice}
          onChange={event => setActionChoice(event.target.value)}
          style={{ alignContent: 'center' }}
        >
          <FormControlLabel
            value="occurrence"
            control={<Radio color="primary" />}
            label={t('APP.ACTIVITY.MENU.OCCURRENCE')}
          />
          <FormControlLabel
            value="all"
            control={<Radio color="primary" />}
            label={t('APP.ACTIVITY.MENU.ALL')}
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions style={{ alignSelf: 'center' }}>
        <Button onClick={onClose} color="primary">
          {t('CANCEL')}
        </Button>
        <Button onClick={() => onConfirm(actionChoice)}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReccurrenceActionDialog;
