/* eslint-disable react/no-danger */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Grid from '@material-ui/core/Grid';
import WelloAvatar from '../Shared/WelloAvatar';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '500px',
    overflow: 'scroll',
  },
  block: {
    paddingBottom: theme.spacing(1),
  },
  buttonContainer: {
    padding: theme.spacing(3),
    paddingTop: 0,
    justifyContent: 'center',
  },
  button: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    justifyContent: 'center',
  },
  recap: {
    paddingBottom: theme.spacing(3),
  },
  list: {
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingLeft: '0px',
  },
  listItem: {
    marginRight: '3px',
    paddingLeft: '3px',
    minWidth: '40px',
  },
  avatar: {
    marginBottom: 0,
    marginRight: theme.spacing(1),
    width: '25px',
    height: '25px',
    display: 'inline-block',
  },
  orga: {
    paddingBottom: theme.spacing(4),
  },
}));

type Props = {
  title: String,
  organisation: Object,
  summaryItems: Array,
  onCancel: Function,
  onEdit: Function,
  onValidate: Function,
};

const QuotationSummaryCard = ({
  title,
  organisation,
  summaryItems,
  onCancel,
  onEdit,
  onValidate,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Typography
              align="center"
              variant="h5"
              component="h2"
              gutterBottom
              className={classes.recap}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item justify="center" alignItems="center" className={classes.orga}>
            <WelloAvatar
              avatarUrl={organisation?.holding_cover_url}
              avatarClass={classes.avatar}
              size={36}
            />
            <Typography variant="h6" align="center" style={{ display: 'inline-block' }}>
              {`${organisation?.holding_name} - ${organisation?.name}`}
            </Typography>
          </Grid>
          {summaryItems.map(item => (
            <Grid item xs={12} className={classes.block}>
              <ListItem className={classes.list}>
                <ListItemIcon className={classes.listItem}>{item.icon}</ListItemIcon>
                <ListItemText className={classes.listItem}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.label,
                    }}
                  />
                  {item.value}
                </ListItemText>
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </CardContent>
      <CardActions className={classes.buttonContainer}>
        {onCancel && (
          <Button className={classes.button} color="primary" onClick={onCancel}>
            {t('BACK')}
          </Button>
        )}
        {onEdit && (
          <Button variant="contained" className={classes.button} color="primary" onClick={onEdit}>
            {t('EDIT')}
          </Button>
        )}
        {onValidate && (
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            onClick={onValidate}
          >
            {t('SEND')}
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default QuotationSummaryCard;
