import React from 'react';
import Routes from '../../../Routing/Routes';
import Breadcrumb from './Breadcrumb';
import Grid from '@material-ui/core/Grid';

type Props = {
  article: Object,
};

const LeagueArticleBreadcrumb = ({ article }: Props) => {
  const rootName = Routes.currentLeague().rootName;
  let searchUrl = '/recherche/articles';

  const links = [
    { name: rootName, link: '/' },
    { name: 'Articles', link: searchUrl },
  ];

  const current = { name: article.title };

  return (
    <Grid>
      <Breadcrumb previousLinks={links} currentLink={current} />
    </Grid>
  );
};

export default LeagueArticleBreadcrumb;
