/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EventIcon from '@material-ui/icons/Event';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PersonIcon from '@material-ui/icons/Person';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import QuotationSummaryCard from '../../Block/QuotationSummaryCard';

type Props = {
  organisation: Object,
  departureAddress: String,
  arrivalAddress: String,
  departureDate: String,
  departureTime: String,
  helper: String,
  price: String,
  onCancel: Function,
  onEdit: Function,
  onValidate: Function,
};

const QuotationTransportSummaryCard = ({
  organisation,
  departureAddress,
  arrivalAddress,
  departureDate,
  departureTime,
  helper,
  price,
  onCancel,
  onEdit,
  onValidate,
}: Props) => {
  const { t } = useTranslation();
  return (
    <QuotationSummaryCard
      title={t('APP.ACTIVITY.ASK_FOR_TRANSPORT.SUMMARY')}
      organisation={organisation}
      summaryItems={[
        {
          icon: <LocationOnIcon />,
          label: t('APP.ACTIVITY.ASK_FOR_TRANSPORT.DEPARTURE_ADDRESS'),
          value: departureAddress,
        },
        {
          icon: <LocationOnIcon />,
          label: t('APP.ACTIVITY.ASK_FOR_TRANSPORT.ARRIVAL_ADDRESS'),
          value: arrivalAddress,
        },
        {
          icon: <EventIcon />,
          label: t('APP.ACTIVITY.ASK_FOR_TRANSPORT.DEPARTURE_DATE'),
          value: departureDate,
        },
        {
          icon: <ScheduleIcon />,
          label: t('APP.ACTIVITY.ASK_FOR_TRANSPORT.DEPARTURE_TIME'),
          value: departureTime,
        },
        {
          icon: <PersonIcon />,
          label: t('APP.ACTIVITY.ASK_FOR_TRANSPORT.HELPER'),
          value: `${helper?.fullname || ''}`,
        },
        {
          icon: <LocalOfferIcon />,
          label: t('APP.ACTIVITY.ASK_FOR_TRANSPORT.PRICE'),
          value: `${price} €`,
        },
      ]}
      onCancel={onCancel}
      onEdit={onEdit}
      onValidate={onValidate}
    />
  );
};

export default QuotationTransportSummaryCard;
