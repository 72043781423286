import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import IconButton from '@material-ui/core/IconButton';

import Divider from '@material-ui/core/Divider';

type Props = {
  classes: Object,
};

const styles = theme => ({
  nobullets: {
    listStyleType: 'none',
  },
  footerLayout: {
    backgroundColor: grey[200],
    padding: theme.spacing(4),
  },
  link: {
    textDecorationLine: 'none',
    color: grey[600],
  },
  fab: {
    margin: theme.spacing(1),
  },
});

function FooterUna({ classes }: Props) {
  const { t } = useTranslation();

  return (
    <footer className={classes.footerLayout}>
      <Grid item md={12}>
        <Typography variant="h4" component="h2" gutterBottom>
          {t('UNA.FOOTER.TITLE')}
        </Typography>
      </Grid>
      <Grid container justify="space-around" spacing={3}>
        <Grid item md={4} sm={4} xs={12}>
          <Grid item md={12}>
            <Typography variant="h6" component="h3">
              {t('UNA.FOOTER.CONTENT_1.TITLE')}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <a href="/nos-offres/aide-lever-coucher" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Aide au lever
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/nos-offres/aide-a-la-toilette" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Aide à la toilette
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/nos-offres/aide-au-menage" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Aide au ménage
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/nos-offres/aide-au-repas" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Aide au repas
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/nos-offres/aide-aux-courses" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Aide aux courses
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/nos-offres/aide-autonomie" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Aide à l&apos;autonomie
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/nos-offres/accompagnement-et-sortie" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Aide à l&apos;accompagnement
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/nos-conseils/allocation" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Allocation personnalisée d&apos;autonomie
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/nos-conseils/handicap" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Aide aux personnes en situation de handicap
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/nos-conseils/personne-agee" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Aide aux personnes âgèes
              </Typography>
            </a>
          </Grid>
        </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <Grid item md={12}>
            <Typography variant="h6" component="h3">
              {t('UNA.FOOTER.CONTENT_2.TITLE')}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <a href="http://www.una.fr" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                En savoir plus sur le réseau UNA
              </Typography>
            </a>
          </Grid>
          {/* <Grid item md={12}>
            <a href="mailto:hello@besoinaideadomicile.fr" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Contacter l&apos;UNA Pays de la Loire
              </Typography>
            </a>
          </Grid> */}
          <Divider light style={{ margin: '10px', background: 'none' }} />
          <Grid item md={12}>
            <Typography variant="h6" component="h3">
              {t('UNA.FOOTER.CONTENT_4.TITLE')}
            </Typography>
          </Grid>
          <IconButton
            href="https://www.facebook.com/besoinaideadomicile.fr/"
            target="_blank"
            className={classes.fab}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton className={classes.fab}>
            <TwitterIcon />
          </IconButton>
          <IconButton
            className={classes.fab}
            href="https://www.instagram.com/besoinaideadomicile/"
            target="_blank"
          >
            <InstagramIcon />
          </IconButton>
        </Grid>
        <Grid item md={4} sm={4} xs={12} component="nav" className={classes.nobullets}>
          <Grid item md={12} component="li">
            <Typography variant="h6" component="h3">
              {t('UNA.FOOTER.CONTENT_3.TITLE')}
            </Typography>
          </Grid>

          <Grid item md={12}>
            <a href="/anaf/anaf" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                ANAF
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/aide-a-domicile-pour-tous/nantes" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                ADT 44
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/adar/adar" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                ADAR 44
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/domus/domus" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Domus
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/aid-a-dom/aid-a-dom" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Aid&apos;a dom
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a
              href="/anjou-accompagnement/anjou-soins-services-accompagnement"
              className={classes.link}
            >
              <Typography variant="caption" color="textSecondary">
                Anjou Soins Services Accompagnement
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/assia/rennes" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Assia Rennes
              </Typography>
            </a>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}

export default withStyles(styles)(FooterUna);
