import React from 'react';
import Routes from '../../../Routing/Routes';
import Breadcrumb from './Breadcrumb';
import Grid from '@material-ui/core/Grid';

type Props = {
  article: Object,
};

const HoldingArticleBreadcrumb = ({ article }: Props) => {
  const rootName = Routes.currentLeague().rootName;

  const links = [
    { name: article.postable_name, link: `/${article.postable_slug}` },
    { name: 'Articles', link: `/${article.postable_slug}/articles` },
  ];

  if (Routes.currentNamespace() !== 'CHB') {
    links.unshift({ name: rootName, link: '/' });
  }

  const current = { name: article.title };

  return (
    <Grid>
      <Breadcrumb previousLinks={links} currentLink={current} />
    </Grid>
  );
};

export default HoldingArticleBreadcrumb;
