import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { booleanPointInPolygon, point, polygon as tpolygon } from '@turf/turf';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import CardActionArea from '@material-ui/core/CardActionArea';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import AddIcon from '@material-ui/icons/Add';
import MuiLink from '@material-ui/core/Link';
import LinearProgress from '@material-ui/core/LinearProgress';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import CardContent from '@material-ui/core/CardContent';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

import moment from 'moment';
import OrganisationTwigs from '../../../Components/Organisations/Home/OrganisationTwigs';
import TasksActions from '../../../Redux/TasksRedux';
import ToasterActions from '../../../Redux/ToasterRedux';
import LayoutStyles from '../../../Components/Layout/Styles/LayoutStyles';
import Images from '../../../Themes/Images';
import { getPendingRole, getRole } from '../../../Acl/Controls';
import { isClientSide } from '../../../Config/ServerConfig';
import Routes from '../../../Routing/Routes';
import Seo from '../../../Routing/Seo';
import NoMatch404 from '../../NoMatch404';
import CardTaskRegisterSuccess from '../../../Components/Organisations/Shared/CardTaskRegisterSuccess';
import { TURF_POLYGON } from '../../../PagesLeagues/Mormal/MormalHelper';
import { getExpertiseStyle } from '../../../Themes/Expertises';
import { CheckRoleRule } from '../../../Acl/Rules';
import Roles, { getFormattedRole } from '../../../Acl/Roles';
import OrganisationSignUp from '../../Authentication/Organisation/SignUp';
import ContactSuccess from '../../../Components/Organisations/Shared/ContactSuccess';
import RowTwigMembers from '../../../Components/Organisations/Schedule/RowTwigMembers';
import SectionTaskComments from '../../../Components/Organisations/Schedule/SectionTaskComments';
import { getCdnUrl } from '../../../Services/ImageResizer';
import RegisterMember from '../../../Components/Organisations/Schedule/RegisterMember';
import { getTwigEndDate, getSeoDescription } from '../../../Services/DataHelper';
import ListTaskInfo from '../../../Components/Organisations/Schedule/ListTaskInfo';
import CardHoldingSimplified from '../../../Components/Organisations/Shared/CardHoldingSimplified';
import ConnexionDialog from '../../../Components/Shared/ConnexionDialog';
import TaskBreadcrumb from '../../../Components/Shared/Breadcrumbs/TaskBreadcrumb';
import { getLeagueSearchResultTabs } from '../../../Services/AlgoliaHelper';

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 600,
  },
  media: {
    objectFit: 'cover',
    maxHeight: '260px',
    borderRadius: '3px',
  },
  description: {
    whiteSpace: 'pre-line',
  },
  headerContainer: {
    backgroundColor: '#fff',
    boxShadow:
      '0px 2px 3px -1px rgba(0, 0, 0, 0.12), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 1px 0px 0px rgba(0, 0, 0, 0.12)',
  },
  headerLayout: {
    ...LayoutStyles.fullWidthLayout,
    width: '100%',
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 !important',
    },
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    marginTop: theme.spacing(1),
    display: 'flex',
    position: 'relative',
    alignItems: 'flex-start',
  },
  leftContent: {
    order: 2,
    [theme.breakpoints.up(`md`)]: {
      order: 1,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 0 !important',
    },
  },
  rightContent: {
    order: 1,
    [theme.breakpoints.up(`md`)]: {
      position: 'sticky',
      top: '4rem',
      order: 2,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 0 0 !important',
    },
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chip: {
    margin: '10px 10px 0px -3px',
  },
  roleChip: {
    marginLeft: theme.spacing(0.5),
  },
  moreInfo: {
    fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.57,
    letterSpacing: '0.00714em',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  leafLetContainer: {
    height: '250px',
    [theme.breakpoints.only('xs')]: {
      maxHeight: 200,
    },
  },
  leafLetPopup: {
    maxWidth: '200px',
  },
  registrantAvatar: {
    height: '32px',
    width: '32px',
    fontSize: '0.9rem',
  },
  registerTitle: {
    margin: theme.spacing(1, 0, 2),
  },
  signInButton: {
    marginTop: theme.spacing(1),
  },
  buttonLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    textTransform: 'none',
    padding: 0,
    marginLeft: theme.spacing(1),
    marginBottom: 3,
  },
  buttonLinkHeader: {
    textTransform: 'none',
    padding: theme.spacing(0, 0, 2),
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  userSignUp: {
    margin: theme.spacing(1, 0),
  },
  buttonPlanning: {
    margin: theme.spacing(2, 0),
  },
  actions: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  serviceIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: theme.spacing(2),
    height: 50,
    width: 50,
    '&>*': {
      height: 50,
      width: 50,
    },
  },
  wrap: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  button: {
    backgroundColor: theme.current.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.current.primary.dark,
    },
  },
}));

const ShowTwig = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    holding_slug: holdingSlug,
    organisation_slug: organisationSlug,
    family_slug: familySlug,
    event_date: urlEventStartDate,
    task_slug: taskSlug,
  } = match.params;
  const task = useSelector(state => state.tasks.task);
  const isFetching = useSelector(state => state.tasks.isFetching.getTask);
  const errors = useSelector(state => state.tasks.errors.getTask);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const currentUser = useSelector(state => state.auth.currentUser);
  const registeredHelper = useSelector(state => state.tasks.registeredHelper);

  const [openImagePreview, setOpenImagePreview] = React.useState(false);
  const [openRegisterDialog, setOpenRegisterDialog] = React.useState(false);
  const [openSignUp, setOpenSignUp] = React.useState(false);
  const [openSignUpPending, setOpenSignUpPending] = React.useState(false);
  const [email, setEmail] = React.useState(null);
  const [registrationAskedFromThisPage, setRegistrationAskedFromThisPage] = React.useState(false);
  const [openRegisterMemberDialog, setOpenRegisterMemberDialog] = React.useState(false);

  const registrants = task?.twig_for_date?.helpers || [];
  const registrantsCount = task?.twig_for_date?.helpers_count || 0;
  const notInteresteds = task?.twig_for_date?.helpers_not_interested || [];
  const taskCoverUrl = getCdnUrl(task?.cover_url, 800);
  const eventStartDate = new Date(task?.twig_for_date?.event_date);
  const eventEndDate = new Date(getTwigEndDate({ event_date: eventStartDate, task: task }));
  const eventLastDate = task?.until_date ? new Date(task?.until_date) : eventEndDate;
  const fallbackCoverUrl = getExpertiseStyle(task?.expertise)?.cover || Images.badge.ERROR;
  const role = getRole(currentUser, task?.holding_slug, task?.organisation_slug, null);
  const pendingRole = getPendingRole(currentUser, task?.holding_slug, task?.organisation_slug);
  const isFamilyTwig = Boolean(!task?.holding_slug);
  const showBreadcrumb =
    task &&
    taskSlug === task.slug &&
    !isFamilyTwig &&
    getLeagueSearchResultTabs(Routes.currentNamespace()).includes('twigs');

  const canRegister = React.useMemo(() => {
    if (role === Roles.VISITOR && !isFamilyTwig) {
      return task?.registrable_roles?.includes(Roles.PRIMARY);
    }
    return (
      CheckRoleRule(isFamilyTwig ? Roles.FAMILY_VISITOR : role, 'tasks:register') &&
      (isFamilyTwig || task?.registrable_roles?.includes(role))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, role]);

  const taskRegistrable =
    registrantsCount < task?.max_participants &&
    (!currentUser || !registrants.find(h => h.id === currentUser.id)) &&
    eventStartDate > Date.now();

  const orderRegistrants = () => {
    const currentRegistrant = registrants.find(r => r.id === currentUser.id);
    const otherRegistrants = registrants.filter(r => r.id !== currentUser.id);
    if (currentRegistrant) {
      otherRegistrants.unshift(currentRegistrant);
    }
    return otherRegistrants;
  };

  const orderNotInterested = () => {
    const currentRegistrant = notInteresteds.find(r => r.id === currentUser.id);
    const otherRegistrants = notInteresteds.filter(r => r.id !== currentUser.id);
    if (currentRegistrant) {
      otherRegistrants.unshift(currentRegistrant);
    }
    return otherRegistrants;
  };

  const getPlanningPath = () => {
    if (familySlug) {
      return `/families/${familySlug}/schedule`;
    }

    return `/${holdingSlug}/${organisationSlug}/activites`;
  };

  const canAskTransport = React.useMemo(() => {
    if (
      Routes.currentLeague().namespace === 'MORMAL' &&
      task?.coordinates &&
      booleanPointInPolygon(
        point([task.coordinates.latitude, task.coordinates.longitude]),
        tpolygon(TURF_POLYGON),
      )
    ) {
      return true;
    }

    return false;
  }, [task]);

  React.useEffect(() => {
    dispatch(TasksActions.resetRegisteredHelper());
    return () => {
      if (registeredHelper) {
        dispatch(TasksActions.resetRegisteredHelper());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setRegistrationAskedFromThisPage(true);
    if (registeredHelper && !canAskTransport && registrationAskedFromThisPage) {
      dispatch(ToasterActions.showToaster({ id: 'APP.ACTIVITY.REGISTERED' }, 'success', 5000));
      dispatch(TasksActions.resetRegisteredHelper());
    }
  }, [dispatch, registeredHelper, canAskTransport, registrationAskedFromThisPage]);

  React.useEffect(() => {
    dispatch(
      TasksActions.getTaskRequest(
        holdingSlug,
        organisationSlug,
        familySlug,
        taskSlug,
        urlEventStartDate,
      ),
    );
  }, [dispatch, holdingSlug, organisationSlug, familySlug, taskSlug, urlEventStartDate]);

  const handleOpenSignUp = () => {
    setOpenSignUp(true);
    setOpenRegisterDialog(false);
  };

  const handleCloseSuccess = () => {
    dispatch(TasksActions.resetRegisteredHelper());
  };

  const handleAddCurrentHelper = (event, selectedHelper, helpedId) => {
    dispatch(
      TasksActions.registerHelperOnTaskRequest(
        holdingSlug,
        organisationSlug,
        familySlug,
        taskSlug,
        selectedHelper.id,
        urlEventStartDate,
        helpedId,
        null,
      ),
    );
    event.preventDefault();
    setOpenRegisterMemberDialog(false);

    window.dataLayer.push({
      event: 'gtm_register_task',
      task_expertise: task.expertise.slug,
      orga_id: task.organisation_id,
      role: role,
    });
  };

  const handleRemoveCurrentHelper = event => {
    dispatch(
      TasksActions.unregisterHelperOnTaskRequest(
        holdingSlug,
        organisationSlug,
        familySlug,
        taskSlug,
        currentUser.id,
        urlEventStartDate,
      ),
    );
    event.preventDefault();
  };

  const handleSubmitClick = (userEmail, userAttributes) => {
    setEmail(userEmail);
    setOpenSignUp(false);
    setOpenSignUpPending(true);

    dispatch(
      TasksActions.registerHelperOnTaskRequest(
        holdingSlug,
        organisationSlug,
        familySlug,
        taskSlug,
        null,
        urlEventStartDate,
        null,
        userAttributes,
      ),
    );

    window.dataLayer.push({
      event: 'gtm_create_account',
      origin: 'task',
      orga_id: task.organisation_id,
    });

    window.dataLayer.push({
      event: 'gtm_register_task',
      task_expertise: task.expertise.slug,
      orga_id: task.organisation_id,
      role: role,
    });
  };

  const handleRegister = () => {
    if (isLoggedIn && (role !== Roles.VISITOR || pendingRole !== Roles.VISITOR || isFamilyTwig)) {
      setOpenRegisterMemberDialog(true);
    } else if (
      !isFamilyTwig &&
      isLoggedIn &&
      role === Roles.VISITOR &&
      pendingRole === Roles.VISITOR
    ) {
      setOpenSignUp(true);
    } else {
      setOpenRegisterDialog(true);
    }
  };

  const renderMap = () => {
    if (!isClientSide()) return <></>;
    const latitude = task?.coordinates?.latitude;
    const longitude = task?.coordinates?.longitude;
    const formattedAddress = task?.formatted_address;
    const position = [latitude, longitude];
    // eslint-disable-next-line global-require
    const L = require('react-leaflet');
    return (
      <>
        {latitude && longitude && (
          <L.MapContainer
            center={position}
            scrollWheelZoom={false}
            zoom={13}
            className={classes.leafLetContainer}
          >
            <L.TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <L.Marker position={position}>
              <L.Popup className={classes.leafLetPopup}>{formattedAddress}</L.Popup>
            </L.Marker>
          </L.MapContainer>
        )}
      </>
    );
  };
  const renderHeaderSection = () => {
    return (
      <Grid container style={{ alignItems: 'flex-end' }}>
        {/* TITLE PANEL */}
        <Grid item sm={8} xs={12} className={classes.wrap}>
          <Avatar
            alt={task?.expertise?.label}
            className={classes.serviceIcon}
            style={{
              color: getExpertiseStyle(task?.expertise).color,
              backgroundColor: 'white',
            }}
          >
            {getExpertiseStyle(task?.expertise).icon}
          </Avatar>
          <Grid>
            <Typography variant="h3" component="h1" className={classes.bold}>
              {task?.description}
            </Typography>
            <Typography variant="subtitle1" component="div">
              {`Le ${moment(eventStartDate).format('dddd LL')} ${
                task?.twig_for_date?.event_start_time
              } - ${task?.twig_for_date?.event_end_time}`}
            </Typography>
          </Grid>
        </Grid>
        {/* REGISTER PANEL */}
        <Grid item sm={4} xs={12}>
          {currentUser &&
            notInteresteds.find(h => h.id === currentUser.id) &&
            eventStartDate >= Date.now() && (
              <div className={classes.buttonContainer}>
                {t('APP.ACTIVITY.PARTICIPANTS.NOT_INTERESTED')}
              </div>
            )}
          {task.registration && taskRegistrable && (
            <div className={classes.buttonContainer}>
              {canRegister && (
                <Button variant="contained" onClick={handleRegister} className={classes.button}>
                  {isFamilyTwig ? t('APP.ACTIVITY.ACTION.REGISTER') : t('ASK_REGISTER')}
                </Button>
              )}
            </div>
          )}
          {!task.registration && eventStartDate > Date.now() && (
            <div className={classes.buttonContainer}>{t('APP.ACTIVITY.NO_REGISTERATION')}</div>
          )}
          {registrantsCount === task?.max_participants &&
            !registrants.find(h => h.id === currentUser?.id) && (
              <div className={classes.buttonContainer}>{t('APP.ACTIVITY.PARTICIPANTS.FULL')}</div>
            )}
          {currentUser &&
            registrants.find(h => h.id === currentUser.id) &&
            eventStartDate >= Date.now() && (
              <div className={classes.buttonContainer}>
                {isFamilyTwig
                  ? t('APP.ACTIVITY.PARTICIPANTS.REGISTERED')
                  : t('APP.ACTIVITY.PARTICIPANTS.PRE_REGISTERED')}
                <Button color="primary" onClick={handleRemoveCurrentHelper}>
                  {t('APP.ACTIVITY.PARTICIPANTS.UNREGISTERED')}
                </Button>
              </div>
            )}
          {eventStartDate <= Date.now() && (
            <div className={classes.buttonContainer}>{t('APP.ACTIVITY.DATE.OVER')}</div>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderDescription = () => {
    return (
      <>
        <CardActionArea onClick={() => setOpenImagePreview(true)}>
          <CardMedia
            className={classes.media}
            component="img"
            src={taskCoverUrl || fallbackCoverUrl}
          />
        </CardActionArea>
        <div className={classes.section}>
          <Typography variant="h4" component="h2" gutterBottom>
            {t('Description')}
          </Typography>
          <Divider className={classes.divider} />
          <Typography paragraph variant="body1" className={classes.description} gutterBottom>
            {task?.excerpt}
          </Typography>

          {/* EXTERNAL LINK PANEL */}
          {task?.external_link && (
            <MuiLink target="_blank" className={classes.moreInfo} href={task?.external_link}>
              {task?.external_link}
            </MuiLink>
          )}
        </div>
        <Dialog open={openImagePreview} onClose={() => setOpenImagePreview(false)}>
          <img src={taskCoverUrl || fallbackCoverUrl} alt="preview" />
        </Dialog>
      </>
    );
  };

  const renderParticipant = () => {
    return (
      <>
        {isLoggedIn && task.registration && (
          <div className={classes.section}>
            <Typography variant="h4" component="h2">
              {t('APP.ACTIVITY.PARTICIPANTS')}
            </Typography>
            <Divider className={classes.divider} />
            <Grid container className={classes.actions}>
              <Grid>
                <Typography variant="body1" component="h3">
                  {t('APP.ACTIVITY.SEAT_AVAILABLE.NUMBER', {
                    count: Math.max(task?.max_participants - registrantsCount, 0),
                    context: task?.max_participants - registrantsCount > 1 && 'PLURAL',
                  })}
                </Typography>
                {task?.registrable_roles?.length > 0 && (
                  <Typography variant="body1" component="h3">
                    {t('APP.ACTIVITY.ROLES')}
                    {task?.registrable_roles
                      ?.filter(registrableRole => registrableRole !== 'admin')
                      .map(registrableRole => (
                        <Chip
                          key={registrableRole}
                          label={`${getFormattedRole(registrableRole, t)}s`}
                          size="small"
                          className={classes.roleChip}
                        />
                      ))}
                  </Typography>
                )}
              </Grid>
              {taskRegistrable && canRegister && (
                <Chip
                  label={t('ASK_REGISTER')}
                  className={classes.chip}
                  color="primary"
                  onClick={handleRegister}
                  deleteIcon={<AddIcon />}
                />
              )}
            </Grid>
            <TableContainer style={{ maxHeight: 200 }}>
              <Table>
                <TableBody>
                  {orderRegistrants().map(helper => {
                    return (
                      <RowTwigMembers
                        key={helper?.id}
                        helper={helper}
                        twig={task?.twig_for_date}
                        currentUser={currentUser}
                        showTwig
                      />
                    );
                  })}
                  {orderNotInterested().map(helper => {
                    return (
                      <RowTwigMembers
                        key={helper?.id}
                        helper={helper}
                        twig={task?.twig_for_date}
                        currentUser={currentUser}
                        showTwig
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {isFetching && <LinearProgress className={classes.progress} />}
      {!isFetching && errors?.status === 404 && <NoMatch404 />}
      {!isFetching && task && taskSlug === task.slug && (
        <>
          <Helmet>
            {Seo.title(`${task?.description}${t(`${Routes.currentNamespace()}.SEO.SUFFIX`)}`)}
            {Seo.description(getSeoDescription(task?.excerpt))}
            {Seo.image(taskCoverUrl || fallbackCoverUrl)}
            {Seo.canonical(task?.holding?.league_id, match.url)}
          </Helmet>
          {holdingSlug && (
            <div itemScope itemProp="event" itemType="http://schema.org/Event">
              <meta itemProp="name" content={task.description} />
              <meta
                itemProp="description"
                content={
                  task.excerpt && Boolean(task.excerpt?.trim()) ? task.excerpt : task.description
                }
              />
              <meta itemProp="image" content={taskCoverUrl || fallbackCoverUrl} />
              <meta
                itemProp="url"
                content={`/${holdingSlug}/${organisationSlug}/activites/${task.slug}/date/${task.start_date}`}
              />
              <div itemProp="location" itemScope itemType="http://schema.org/Place">
                <meta itemProp="name" content={task.formatted_address} />
                <meta itemProp="address" content={task.formatted_address} />
                {task.coordinates && (
                  <div itemProp="geo" itemScope itemType="http://schema.org/GeoCoordinates">
                    <meta itemProp="latitude" content={task.coordinates?.latitude} />
                    <meta itemProp="longitude" content={task.coordinates?.longitude} />
                  </div>
                )}
              </div>
              <meta itemProp="performer" content={task.description} />
              <meta itemProp="maximumAttendeeCapacity" content={task.max_participants} />
              <meta
                itemProp="startDate"
                content={task?.twig_for_date?.event_date ? eventStartDate.toISOString() : null}
              />
              <meta
                itemProp="endDate"
                content={task?.twig_for_date?.event_date ? eventEndDate.toISOString() : null}
              />
              <div itemProp="organizer" itemScope itemType="http://schema.org/Person">
                <meta itemProp="name" content={task.helper_name} />
                <meta itemProp="url" content={`/${holdingSlug}/${organisationSlug}`} />
              </div>
              <div itemProp="offers" itemScope itemType="http://schema.org/Offer">
                <meta itemProp="price" content="0" />
                <meta itemProp="priceCurrency" content="EUR" />
                <meta itemProp="availability" content="http://schema.org/LimitedAvailability" />
                <meta
                  itemProp="url"
                  content={`/${holdingSlug}/${organisationSlug}/activites/${task.slug}/date/${task.start_date}`}
                />
                <meta itemProp="validFrom" content={eventStartDate} />
              </div>
              <meta itemProp="typicalAgeRange" content="60+" />
              <meta itemProp="eventStatus" content="https://schema.org/EventScheduled" />
              <meta
                itemProp="eventAttendanceMode"
                content="https://schema.org/MixedEventAttendanceMode"
              />
              <div itemProp="performer" itemScope itemType="http://schema.org/Organization">
                <meta itemProp="name" content={task.organisation_name} />
              </div>
              <div itemProp="organizer" itemScope itemType="http://schema.org/Organization">
                <meta itemProp="name" content={task.organisation_name} />
                <meta
                  itemProp="url"
                  content={`${t(`${Routes.currentNamespace()}.SEO.HOMEPAGE.URL`)}/${
                    task.holding_slug
                  }/${task.organisation_slug}`}
                />
              </div>
            </div>
          )}
          {/* HEADER SECTION */}
          <Grid container className={classes.headerContainer}>
            <Grid className={classes.headerLayout}>
              <Button
                color="primary"
                component={Link}
                to={getPlanningPath()}
                className={classes.buttonLinkHeader}
                disableTouchRipple
              >
                <ArrowLeftIcon /> {t('APP.ACTIVITY.GO_TO_PLANNING')}
              </Button>
              {renderHeaderSection()}
            </Grid>
          </Grid>
          {/* CONTENT SECTION */}
          <Grid container spacing={8} className={classes.layout}>
            {/* CONTENT PANEL */}
            <Grid item md={8} xs={12} className={classes.leftContent}>
              {/* DESCRIPTION PANEL */}
              {renderDescription()}

              {/* PARTICIPANT PANEL */}
              {renderParticipant()}

              {/* COMMENT PANEL */}
              {isLoggedIn && (
                <Grid className={classes.section}>
                  <SectionTaskComments task={task} showPostForm />
                </Grid>
              )}
            </Grid>
            {/* RESUME PANEL */}
            <Grid item md={4} xs={12} className={classes.rightContent}>
              <Paper>
                <>
                  {renderMap()}
                  {
                    <CardContent className={classes.itemsContent}>
                      <ListTaskInfo
                        task={{
                          ...task,
                          end_date: eventEndDate,
                          twig_for_date: task.twig_for_date,
                        }}
                        registrantsCount={registrantsCount}
                        eventStartDate={eventStartDate}
                        eventLastDate={eventLastDate}
                      />
                    </CardContent>
                  }
                </>
              </Paper>
              {!isFamilyTwig && (
                <>
                  <Typography paragraph variant="body1" style={{ margin: '20px 0' }}>
                    {t('APP.ACTIVITY.ORGA')}
                  </Typography>
                  <CardHoldingSimplified
                    holding={task.holding}
                    holdingSlug={holdingSlug}
                    organisationSlug={organisationSlug}
                    orgaName={task.organisation_name}
                  />
                </>
              )}
            </Grid>
          </Grid>
          {/* OTHER SECTION */}
          <Grid container spacing={8} className={classes.layout}>
            {/* SHOW OTHER TASK */}
            <Grid item md={12} sm={12} xs={12}>
              <OrganisationTwigs
                twigs={task?.sampled_twigs?.filter(
                  twig =>
                    twig.task.id !== task?.id ||
                    (twig.task.id === task?.id &&
                      twig.event_date !== task?.twig_for_date?.event_date),
                )}
                title="APP.ACTIVITY.DISCOVER.TITLE"
                subTitle="APP.ACTIVITY.DISCOVER.SUBTITLE"
                displayShowMoreLink={false}
                withoutTaskId={task?.id}
              />
              {showBreadcrumb && <TaskBreadcrumb task={task} />}
            </Grid>
          </Grid>

          <Dialog open={!!registeredHelper && canAskTransport} onClose={handleCloseSuccess}>
            <CardTaskRegisterSuccess
              task={task}
              currentUser={currentUser}
              helper={registeredHelper}
              onClose={handleCloseSuccess}
            />
          </Dialog>

          <Dialog
            open={openRegisterMemberDialog}
            onClose={() => setOpenRegisterMemberDialog(false)}
          >
            <RegisterMember
              currentUser={{ ...currentUser, role, pendingRole }}
              handleClickInvite={handleAddCurrentHelper}
              showTwig
              allowedRoles={task?.registrable_roles}
              seatsAvailable={task?.max_participants - task?.twig_for_date?.helpers?.length}
              onCancel={() => setOpenRegisterMemberDialog(false)}
            />
          </Dialog>

          <Dialog open={openRegisterDialog} onClose={() => setOpenRegisterDialog(false)}>
            <ConnexionDialog
              dialogTitle={t('APP.ACTIVITY.ACTION.REGISTER')}
              dialogContent={t('APP.ACTIVITY.SIGN_IN_TO_REGISTER')}
              handleOpenSignUp={handleOpenSignUp}
            />
          </Dialog>

          <Dialog open={openSignUp} onClose={() => setOpenSignUp(false)}>
            <OrganisationSignUp
              organisation={{
                slug: organisationSlug,
                name: task?.organisation_name,
                holding_slug: holdingSlug,
                holding: { name: task?.holding?.name },
              }}
              isDialog
              onSubmitClick={handleSubmitClick}
              signUpRole={Roles.PRIMARY}
              subtitle={
                isLoggedIn
                  ? t('WELLO.SIGN_UP.SUBTITLE.TASK.IS_LOGGED', {
                      organisationName: `${task?.holding?.name} - ${task?.organisation_name}`,
                    })
                  : t('WELLO.SIGN_UP.SUBTITLE.TASK.IS_NOT_LOGGED', {
                      organisationName: `${task?.holding?.name} - ${task?.organisation_name}`,
                    })
              }
              preventSubmitSignUp
              hideFieldsIfCurrentUser
            />
          </Dialog>
          <Dialog open={openSignUpPending} onClose={() => setOpenSignUpPending(false)}>
            <ContactSuccess
              organisation={{
                id: task?.organisation_id,
                slug: organisationSlug,
                name: task?.organisation_name,
                banner_url: task?.organisation_banner_url || Images.wello.orga_empty_banner,
                helper_count: 0,
                holding_slug: holdingSlug,
                holding: {
                  name: task?.holding?.name,
                  cover_url: task?.holding?.cover_url,
                  short_description: task?.holding?.short_description,
                },
              }}
              title={t('WELLO.PENDING_SIGN_UP.TITLE')}
              description={!isLoggedIn && t('WELLO.PENDING_SIGN_UP.DESCRIPTION', { email })}
              subtitle={t('WELLO.PENDING_SIGN_UP.SUB_TITLE')}
            />
          </Dialog>
        </>
      )}
    </>
  );
};

// Solve leafleat marker issue : https://github.com/PaulLeCam/react-leaflet/issues/453
/* eslint-disable no-underscore-dangle */
/* eslint-disable global-require */
if (isClientSide()) {
  const L = require('leaflet');
  delete L.Icon.Default.prototype._getIconUrl;
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  });
}
/* eslint-enable global-require */
/* eslint-enable no-underscore-dangle */

const component = isClientSide() ? withMobileDialog({ breakpoint: 'xs' })(ShowTwig) : ShowTwig;
export default component;
