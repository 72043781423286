import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Button, Divider, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { green, grey, red } from '@material-ui/core/colors';
import ChipInput from 'material-ui-chip-input';

import { handleAddChip, handleRemoveChip } from '../../Services/FormHelpers';

type Props = {
  expertises: Array,
  keyWords: Array,
  selectedWords: Array,
  excludedWords: Array,
  setKeyWords: Function,
  setSelectedWords: Function,
  setExcludedWords: Function,
  onClose: Function,
};

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    maxWidth: 500,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  listItem: {
    backgroundColor: grey[200],
  },
  itemText: {
    marginRight: theme.spacing(1),
  },
  validatedSelectedWord: {
    color: green[600],
  },
  selectedWord: {
    backgroundColor: grey[400],
    borderRadius: 12,
    color: 'white',
  },
  validatedExcludedWord: {
    color: red[600],
  },
  excludedWord: {
    backgroundColor: grey[400],
    borderRadius: 12,
    color: 'white',
  },
  expertiseSelected: {
    color: green[600],
  },
  expertiseExcluded: {
    color: red[600],
  },
  chipRoot: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: '4px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  chip: {
    padding: 0,
    margin: 0,
    marginTop: theme.spacing(0.7),
    marginBottom: theme.spacing(0.7),
    marginRight: theme.spacing(0.7),
    height: 'inherit',
  },
  chipContainer: {
    maxWidth: 300,
    minHeight: 'inherit',
    margin: 0,
  },
}));

const ExpertisesSelection = ({
  expertises,
  keyWords,
  selectedWords,
  excludedWords,
  setKeyWords,
  setSelectedWords,
  setExcludedWords,
  onClose,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChangeSelectedWords = exp => {
    let newSelectedWords = selectedWords.slice();
    let newExcludedWords = excludedWords.slice();

    if (selectedWords.includes(exp)) {
      newSelectedWords = selectedWords.filter(e => e !== exp);
    } else if (newExcludedWords.includes(exp)) {
      newExcludedWords = excludedWords.filter(e => e !== exp);
      newSelectedWords.push(exp);
    } else {
      newSelectedWords.push(exp);
    }
    setSelectedWords(newSelectedWords);
    setExcludedWords(newExcludedWords);
  };

  const handleChangeExcludedWords = exp => {
    let newSelectedWords = selectedWords.slice();
    let newExcludedWords = excludedWords.slice();

    if (excludedWords.includes(exp)) {
      newExcludedWords = excludedWords.filter(e => e !== exp);
    } else if (newSelectedWords.includes(exp)) {
      newSelectedWords = selectedWords.filter(e => e !== exp);
      newExcludedWords.push(exp);
    } else {
      newExcludedWords.push(exp);
    }

    setSelectedWords(newSelectedWords);
    setExcludedWords(newExcludedWords);
  };

  const renderExpertisesList = expertise => {
    const selectedExpertise =
      selectedWords?.includes(expertise.label) || excludedWords?.includes(expertise.label);
    const expertiseSelected = selectedWords?.includes(expertise.label);
    return (
      <ListItem
        dense={selectedExpertise}
        key={expertise.label}
        className={selectedExpertise ? classes.listItem : null}
      >
        <ListItemIcon>{expertise.icon}</ListItemIcon>
        <ListItemText
          className={classes.itemText}
          primary={<Typography variant="body1">{expertise.label}</Typography>}
          secondary={
            selectedExpertise ? (
              <Typography
                variant="body2"
                className={
                  expertiseSelected ? classes.expertiseSelected : classes.expertiseExcluded
                }
              >
                {t(
                  expertiseSelected
                    ? 'APP.POST.EXPERTISE.SELECTED_WORD'
                    : 'APP.POST.EXPERTISE.EXCLUDED_WORD',
                )}
              </Typography>
            ) : null
          }
        />
        <ListItemIcon>
          <IconButton
            aria-label="check"
            size="small"
            onClick={() => handleChangeSelectedWords(expertise.label)}
          >
            <CheckCircleIcon
              className={expertiseSelected ? classes.validatedSelectedWord : classes.selectedWord}
            />
          </IconButton>
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => handleChangeExcludedWords(expertise.label)}
          >
            <CancelIcon
              className={
                excludedWords?.includes(expertise.label)
                  ? classes.validatedExcludedWord
                  : classes.excludedWord
              }
            />
          </IconButton>
        </ListItemIcon>
      </ListItem>
    );
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant="h4" gutterBottom>
        {t('APP.CARENOTEBOOKS.SHOW.CREATE_POST.EXPERTISE')}
      </Typography>
      <Typography variant="body2">
        {t('APP.CARENOTEBOOKS.SHOW.CREATE_POST.EXPERTISE.SUBTITLE')}
      </Typography>
      <Divider light className={classes.divider} />
      <List>{expertises.map(expertise => renderExpertisesList(expertise))}</List>
      <ChipInput
        fullWidth
        autoFocus
        disableUnderline
        value={keyWords}
        onDelete={chip => setKeyWords(handleRemoveChip(chip, keyWords))}
        onAdd={chip => setKeyWords(handleAddChip(chip, keyWords))}
        placeholder={t('APP.POST.EXPERTISE.ADD')}
        classes={{
          root: classes.chipRoot,
          chip: classes.chip,
          chipContainer: classes.chipContainer,
        }}
      />
      <Grid align="center">
        <Button variant="contained" color="primary" onClick={onClose}>
          {t('OK')}
        </Button>
      </Grid>
    </Paper>
  );
};

export default ExpertisesSelection;
