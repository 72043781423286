import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import fp from 'lodash/fp';

import DoneIcon from '@material-ui/icons/Done';
import { Helmet } from 'react-helmet';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import ToasterActions from '../../Redux/ToasterRedux';
import LeaguesActions from '../../Redux/LeaguesRedux';
import { isLongerThan, isInList } from '../../Services/FormHelpers';
import Seo from '../../Routing/Seo';
import Routes from '../../Routing/Routes';
import { GENDER } from '../../Services/LeadHelper';
import AutoCompletePlaces from '../../Components/Shared/AutoCompletePlaces';
import { checkPhoneValidity } from '../../Services/DataHelper';

type Props = {
  classes: Object,
  league: Object,
  hideToaster: Function,
  createLeagueCandidate: Function,
  isFetching: Boolean,
  errors: Object,
  t: Function,
};

const styles = theme => ({
  layoutContainer: {
    ...LayoutStyles.fullWidthLayout,
  },
  doneIcon: {
    margin: `${theme.spacing(1)}px 0 -5px ${theme.spacing(1)}px`,
    fontSize: 32,
  },
  cardContainer: {
    margin: `${theme.spacing(6)}px 0`,
  },
  stepperContainer: {
    backgroundColor: 'white',
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  buttonWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  formTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formLegend: {
    color: 'black',
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
});

const AVAILABLE_WISHED_JOB = [
  'Les familles (entretien du linge et du logement)',
  'Les seniors (entretien du linge et du logement, repas, courses)',
  'Les personnes en perte d’autonomie (aide à aux actes essentiels, accompagnement social, aide aux aidants)',
];

class LeagueCandidate extends React.Component<Props> {
  constructor(props) {
    super(props);
    const customStateOnConnected = {};

    this.state = fp.defaults({
      activeStep: 0,
      phoneError: null,

      // Step 0
      gender: '',
      fullName: '',
      formattedAddress: '',
      latitude: '',
      longitude: '',
      phone: '',
      email: '',
      fileInput: React.createRef(),

      // Step 1
      wishedJobs: [],
      education: '',
      workingTime: '',

      // Step 2
      disponibilityNow: '',
      transportation: '',
      location: '',
      description: '',
    })(customStateOnConnected);
  }

  componentDidMount() {
    const { hideToaster } = this.props;
    hideToaster();
  }

  componentDidUpdate(prevProps) {
    const { isFetching: prevPropsIsFetching } = prevProps;
    const { isFetching, errors } = this.props;
    if (prevPropsIsFetching !== isFetching && isFetching === false && errors === null) {
      this.goToFinalStep();
    }
  }

  goToFinalStep = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleSubmit = event => {
    const { createLeagueCandidate, league } = this.props;

    const {
      gender,
      fullName,
      formattedAddress,
      latitude,
      longitude,
      phone,
      email,
      wishedJobs,
      education,
      workingTime,
      disponibilityNow,
      transportation,
      location,
      description,
      fileInput,
    } = this.state;

    createLeagueCandidate(
      league.id,
      {
        gender,
        full_name: fullName,
        formatted_address: formattedAddress,
        latitude,
        longitude,
        phone,
        email,
        wished_jobs: wishedJobs,
        education,
        working_time: workingTime,
        disponibility_now: disponibilityNow,
        transportation,
        location,
        description,
      },
      fileInput.current.files[0],
    );
    event.preventDefault();
  };

  handleOnBlurMobilePhone = event => {
    const { t } = this.props;
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    if (name === 'phone' && !checkPhoneValidity(value)) {
      this.setState({
        phoneError: t('WELLO.ALL_PHONE.ERRORS'),
      });
    }
  };

  handleInputChange = event => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    if (name === 'phone') {
      this.setState({
        phoneError: null,
      });
    }

    this.setState({
      [name]: value,
    });
  };

  handleMultiCheckboxChange = (event, value) => {
    const {
      target: { name },
    } = event;
    const { [name]: multiCheckboxValues } = this.state;

    if (multiCheckboxValues.includes(value)) {
      this.setState(state => ({
        [name]: state[name].filter(item => item !== value),
      }));
    } else {
      this.setState(state => ({
        [name]: [...state[name], value],
      }));
    }
  };

  getStep0Content = () => {
    const { classes, t } = this.props;
    const { phone, phoneError, email, gender, fullName } = this.state;

    return (
      <>
        <Typography variant="h5" align="center" className={classes.formTitle}>
          {t('UNA.CANDIDATE.STEP_0.TITLE')}
        </Typography>

        <FormControl component="fieldset" className={classes.formControl} fullWidth>
          <RadioGroup
            aria-label="gender"
            name="gender"
            className={classes.group}
            value={gender}
            onChange={this.handleInputChange}
            row
          >
            <FormControlLabel
              value="female"
              control={<Radio color="primary" />}
              label={t('FEMALE')}
            />
            <FormControlLabel value="male" control={<Radio color="primary" />} label={t('MALE')} />
          </RadioGroup>

          <TextField
            name="fullName"
            value={fullName}
            onChange={this.handleInputChange}
            label={t('UNA.CANDIDATE.YOUR_FULL_NAME')}
            margin="normal"
            variant="outlined"
            fullWidth
            required
            InputLabelProps={{
              shrink: true,
            }}
          />

          <FormControl
            margin="normal"
            component="fieldset"
            fullWidth
            style={{ border: 1 }}
            required
          >
            <InputLabel
              variant="outlined"
              shrink
              className={classes.selectLabel}
              style={{
                zIndex: '1011',
              }}
            >
              {t('UNA.CANDIDATE.YOUR_ADDRESS')}
            </InputLabel>
            <AutoCompletePlaces
              onSelect={value => {
                this.setState({
                  formattedAddress: value.formatted_address,
                  latitude: value.coordinates?.lat,
                  longitude: value.coordinates?.lng,
                });
              }}
              onClear={() => {
                this.setState({
                  formattedAddress: '',
                  latitude: '',
                  longitude: '',
                });
              }}
              placeHolder=" "
              id="candidate"
            />
          </FormControl>

          <TextField
            name="phone"
            value={phone}
            onChange={this.handleInputChange}
            onBlur={this.handleOnBlurMobilePhone}
            label={t('UNA.CANDIDATE.MOBILE_PHONE')}
            helperText={phoneError}
            error={phoneError !== null}
            margin="normal"
            variant="outlined"
            fullWidth
            required
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            name="email"
            value={email}
            onChange={this.handleInputChange}
            label={t('UNA.CANDIDATE.EMAIL')}
            margin="normal"
            variant="outlined"
            fullWidth
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      </>
    );
  };

  getStep1Content = () => {
    const { classes, t } = this.props;
    const { education, workingTime, wishedJobs } = this.state;

    return (
      <>
        <Typography variant="h5" align="center" className={classes.formTitle}>
          {t('UNA.CANDIDATE.STEP_1.TITLE')}
        </Typography>

        <FormControl component="fieldset" className={classes.formControl} fullWidth>
          {AVAILABLE_WISHED_JOB.map(availableWishedJob => (
            <FormControlLabel
              key={availableWishedJob}
              label={availableWishedJob}
              control={
                <Checkbox
                  onClick={event => this.handleMultiCheckboxChange(event, availableWishedJob)}
                  checked={wishedJobs.includes(availableWishedJob)}
                  name="wishedJobs"
                  color="primary"
                />
              }
            />
          ))}
        </FormControl>

        <FormControl component="fieldset" className={classes.formControl} fullWidth>
          <FormLabel component="legend" className={classes.formLegend}>
            {t('UNA.CANDIDATE.EDUCATION')}
          </FormLabel>

          <TextField
            name="education"
            value={education}
            onChange={this.handleInputChange}
            label={t('UNA.CANDIDATE.EDUCATION.PLACEHOLDER')}
            margin="normal"
            variant="outlined"
            fullWidth
            required
          />
        </FormControl>

        <FormControl component="fieldset" className={classes.formControl} fullWidth>
          <FormLabel component="legend" className={classes.formLegend}>
            {t('UNA.CANDIDATE.WORKING_TIME')}
          </FormLabel>

          <TextField
            name="workingTime"
            value={workingTime}
            onChange={this.handleInputChange}
            label={t('UNA.CANDIDATE.WORKING_TIME.PLACEHOLDER')}
            margin="normal"
            variant="outlined"
            fullWidth
            required
          />
        </FormControl>
      </>
    );
  };

  getStep2Content = () => {
    const { classes, t } = this.props;
    const { disponibilityNow, fileInput, transportation, location, description } = this.state;

    return (
      <>
        <Typography variant="h5" align="center" className={classes.formTitle}>
          {t('UNA.CANDIDATE.STEP_2.TITLE')}
        </Typography>

        <FormControl component="fieldset" className={classes.formControl} fullWidth>
          <FormLabel component="legend" className={classes.formLegend}>
            {t('UNA.CANDIDATE.AVAILABLE_NOW')}
          </FormLabel>
          <RadioGroup
            aria-label="disponibilityNow"
            name="disponibilityNow"
            className={classes.group}
            value={disponibilityNow}
            onChange={this.handleInputChange}
            row
            required
          >
            <FormControlLabel value="1" control={<Radio color="primary" />} label={t('YES')} />
            <FormControlLabel value="0" control={<Radio color="primary" />} label={t('NO')} />
          </RadioGroup>

          <TextField
            name="transportation"
            value={transportation}
            onChange={this.handleInputChange}
            label={t('UNA.CANDIDATE.TRANSPORTATION')}
            margin="normal"
            variant="outlined"
            fullWidth
            required
          />

          <TextField
            name="location"
            value={location}
            onChange={this.handleInputChange}
            label={t('UNA.CANDIDATE.LOCATION')}
            margin="normal"
            variant="outlined"
            fullWidth
            required
          />
        </FormControl>

        <FormControl component="fieldset" className={classes.formControl} fullWidth>
          <FormLabel component="legend" className={classes.formLegend}>
            {t('UNA.CANDIDATE.MESSAGE')}
          </FormLabel>
          <TextField
            name="description"
            value={description}
            onChange={this.handleInputChange}
            label={t('UNA.CANDIDATE.MESSAGE.PLACEHOLDER')}
            margin="normal"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            required
          />
        </FormControl>

        <FormControl component="fieldset" className={classes.formControl} fullWidth>
          <FormLabel component="legend" className={classes.formLegend}>
            {t('UNA.CANDIDATE.UPLOAD_FILE')}
          </FormLabel>
          <input name="file" type="file" ref={fileInput} />
        </FormControl>
      </>
    );
  };

  isValidFormStep = activeStep => {
    switch (activeStep) {
      case 0:
        return (
          this.isGenderValid() &&
          this.isFullNameValid() &&
          this.isFullAddressValid() &&
          this.isMobilePhoneValid()
        );
      case 1:
        return this.isWishedJobsValid() && this.isEducationValid() && this.isWorkingTimeValid();
      case 2:
        return (
          this.isDisponibilityNowValid() && this.isTransportationValid() && this.isLocationValid()
        );
      default:
        return false;
    }
  };

  isGenderValid = () => {
    const { gender } = this.state;
    const valid = isInList([GENDER.MALE, GENDER.FEMALE])(gender);
    return valid;
  };

  isFullNameValid = () => {
    const { fullName } = this.state;
    const valid = isLongerThan(0)(fullName);
    return valid;
  };

  isFullAddressValid = () => {
    const { formattedAddress } = this.state;
    const valid = isLongerThan(0)(formattedAddress);
    return valid;
  };

  isMobilePhoneValid = () => {
    const { phoneError, phone } = this.state;
    const valid = isLongerThan(0)(phone) && phoneError === null;
    return valid;
  };

  isWishedJobsValid = () => {
    const { wishedJobs } = this.state;
    const valid = isLongerThan(0)(wishedJobs);
    return valid;
  };

  isEducationValid = () => {
    const { education } = this.state;
    const valid = isLongerThan(0)(education);
    return valid;
  };

  isWorkingTimeValid = () => {
    const { workingTime } = this.state;
    const valid = isLongerThan(0)(workingTime);
    return valid;
  };

  isDisponibilityNowValid = () => {
    const { disponibilityNow } = this.state;
    const valid = isInList(['1', '0'])(disponibilityNow);
    return valid;
  };

  isTransportationValid = () => {
    const { transportation } = this.state;
    const valid = isLongerThan(0)(transportation);
    return valid;
  };

  isLocationValid = () => {
    const { location } = this.state;
    const valid = isLongerThan(0)(location);
    return valid;
  };

  getStepSuccessContent = () => {
    const { classes, t } = this.props;

    return (
      <>
        <Typography variant="h5" align="center" className={classes.successTitle} gutterBottom>
          {t('UNA.CANDIDATE.STEP_3.TITLE')}
          <DoneIcon className={classes.doneIcon} />
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          {t('UNA.CANDIDATE.STEP_3.SUBTITLE')}
        </Typography>

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.cardContainer}
        >
          <Grid item md xs />
          <Grid item md={6} xs>
            <Typography variant="subtitle1" align="center" gutterBottom>
              {t('UNA.CANDIDATE.STEP_3.SUCCESS')}
            </Typography>
          </Grid>
          <Grid item md xs />
        </Grid>
      </>
    );
  };

  getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return this.getStep0Content();
      case 1:
        return this.getStep1Content();
      case 2:
        return this.getStep2Content();
      case 3:
        return this.getStepSuccessContent();
      default:
        return 'Unknown stepIndex';
    }
  };

  render() {
    const { classes, isFetching, t } = this.props;
    const { activeStep } = this.state;
    const steps = [
      'UNA.CANDIDATE.STEP_0.STEPPER',
      'UNA.CANDIDATE.STEP_1.STEPPER',
      'UNA.CANDIDATE.STEP_2.STEPPER',
      'UNA.CANDIDATE.STEP_3.STEPPER',
    ];

    return (
      <>
        <Helmet>
          {Seo.title(`${t('UNA.CANDIDATE.TITLE')} ${t(`${Routes.currentNamespace()}.SEO.SUFFIX`)}`)}
          {Seo.description(`${t('UNA.CANDIDATE.DESCRIPTION')}`)}
        </Helmet>
        <div className={classes.stepperContainer}>
          <Stepper activeStep={activeStep} alternativeLabel className={classes.layoutContainer}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{t(label)}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>

        <Grid container className={classes.layoutContainer}>
          <Grid item md={2} />
          <Grid item md={8} xs={12}>
            <Paper className={classes.container}>
              <form onSubmit={this.handleSubmit}>
                <div className={classes.instructions}>{this.getStepContent(activeStep)}</div>
                <div className={classes.buttonContainer}>
                  {activeStep === 0 && (
                    <Button component={Link} to="/pro" className={classes.backButton}>
                      {t('CANCEL')}
                    </Button>
                  )}
                  {(activeStep === 1 || activeStep === 2) && (
                    <Button
                      disabled={activeStep === 0}
                      onClick={this.handleBack}
                      className={classes.backButton}
                    >
                      {t('BACK')}
                    </Button>
                  )}
                  {(activeStep === 0 || activeStep === 1) && (
                    <Button
                      disabled={!this.isValidFormStep(activeStep)}
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                    >
                      {t('NEXT')}
                    </Button>
                  )}
                  {activeStep === 2 && (
                    <div className={classes.buttonWrapper}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isFetching || !this.isValidFormStep(activeStep)}
                      >
                        {t('SEND')}
                      </Button>
                      {isFetching && (
                        <CircularProgress size={24} className={classes.buttonProgress} />
                      )}
                    </div>
                  )}
                  {activeStep === 3 && (
                    <Button color="primary" component={Link} to="/">
                      {t('UNA.CANDIDATE.FINISHED')}
                    </Button>
                  )}
                </div>
              </form>
            </Paper>
          </Grid>
          <Grid item md={2} />
        </Grid>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.leagues.isFetching.createCandidateLeague,
  candidate: state.leagues.candidate,
  errors: state.toaster.message,
  league: state.leagues.league,
});

const mapDispatchToProps = dispatch => ({
  hideToaster: () => dispatch(ToasterActions.hideToaster()),
  createLeagueCandidate: (leagueId, candidate, file) =>
    dispatch(LeaguesActions.createCandidateLeagueRequest(leagueId, candidate, file)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(withTranslation()(LeagueCandidate))));
