import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import TaskPostForm from '../../Posts/TaskForm';
import WelloAvatar from '../../Shared/WelloAvatar';

const useStyles = makeStyles(theme => ({
  section: {
    marginTop: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cardHeader: {
    padding: theme.spacing(1),
  },
}));

type Props = {
  task: Object,
  showPostForm: Boolean,
};

const SectionTaskComments = ({ task, showPostForm }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h4" component="h2">
        {task?.posts?.length > 0
          ? t('APP.POST.COMMENT_COUNT', {
              count: task?.posts?.length,
              context: `${task?.posts?.length}`,
            })
          : t('APP.POST.COMMENT_COUNT_zero')}
      </Typography>
      <Divider className={classes.divider} />
      {task?.posts &&
        (task?.posts?.sort((a, b) => a.id - b.id) || []).map(post => (
          <Card
            key={post.id}
            elevation={0}
            style={{ backgroundColor: 'transparent' }}
            itemScope
            itemProp="review"
            itemType="http://schema.org/Review"
          >
            <CardHeader
              avatar={
                <WelloAvatar
                  avatarUrl={post.helper?.avatar_url}
                  firstName={post.helper?.first_name}
                  lastName={post.helper?.last_name}
                  backgroundColor={post.helper?.avatar_background_color}
                  size={36}
                />
              }
              title={
                <>
                  <b>{post.helper_first_name}</b> le {moment(post.created_at).format('LLLL')}
                </>
              }
              subheader={post.raw}
              className={classes.cardHeader}
            />
            <meta itemProp="author" content={post.helper_first_name} />
            <meta itemProp="description" content={post.raw} />
            <meta itemProp="datePublished" content={post.created_at} />
          </Card>
        ))}
      {showPostForm && (
        <Grid container className={classes.section}>
          <TaskPostForm
            key={`comment-twig-form-${task?.id}-${task?.posts?.length}`}
            holdingSlug={task.holding_slug}
            organisationSlug={task.organisation_slug}
            postableId={task?.id}
            postableType={task?.type}
          />
        </Grid>
      )}
    </>
  );
};

export default SectionTaskComments;
