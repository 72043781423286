import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  registerTitle: {
    margin: theme.spacing(1, 0, 2),
  },
  signInButton: {
    marginTop: theme.spacing(1),
  },
  buttonLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    textTransform: 'none',
    padding: 0,
    marginLeft: theme.spacing(1),
    marginBottom: 3,
  },
  userSignUp: {
    margin: theme.spacing(2, 0),
  },
}));

type Props = {
  dialogTitle: String,
  dialogContent: String,
  handleOpenSignUp: Function,
};

const ConnexionDialog = ({ dialogTitle, dialogContent, handleOpenSignUp }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <DialogTitle align="center">
        <Typography component="p" variant="h5" className={classes.registerTitle}>
          {dialogTitle}
        </Typography>
        <Typography>{dialogContent}</Typography>
      </DialogTitle>
      <DialogContent align="center">
        <Button
          variant="contained"
          component={Link}
          to="/signin?history=goBack"
          className={classes.signInButton}
        >
          {t('WELLO.SIGN_IN.TITLE')}
        </Button>
        <Typography className={classes.userSignUp}>
          {t('WELLO.NEW_USER')}
          <Button
            onClick={handleOpenSignUp}
            className={classes.buttonLink}
            style={{ backgroundColor: 'transparent' }}
          >
            {t('WELLO.SIGN_UP')}
          </Button>
        </Typography>
      </DialogContent>
    </>
  );
};

export default ConnexionDialog;
