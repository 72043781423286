import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

type Props = {
  restrictions: Object,
  selectedRestrictions: Object,
  setSelectedRestrictions: Function,
  onClose: Function,
  handleSubmit: Function,
};

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    maxWidth: '400px',
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  listItem: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const RestrictionsSelection = ({
  restrictions,
  selectedRestrictions,
  setSelectedRestrictions,
  onClose,
  handleSubmit,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const noSelectedRestriction =
    restrictions &&
    (Object.keys(selectedRestrictions)?.length === 0 ||
      Object.keys(selectedRestrictions)?.every(
        id => Object.values(selectedRestrictions[id])?.length === 0,
      ));

  const handleOrgaMultiCheckboxChange = (event, restriction) => {
    const restrictionId = restriction.id.toString();
    const newRestrictions = { ...selectedRestrictions };
    if (
      Object.keys(selectedRestrictions).includes(restrictionId) &&
      restriction?.restrictionRoles?.length === selectedRestrictions[restrictionId]?.length
    ) {
      newRestrictions[restrictionId] = [];
    } else {
      newRestrictions[restrictionId] = restriction?.restrictionRoles.map(role => role[1]);
    }
    setSelectedRestrictions(newRestrictions);
  };

  const handleMultiCheckboxChange = (event, restriction, value) => {
    const restrictionId = restriction.id.toString();
    const newRestrictions = { ...selectedRestrictions };
    if (
      Object.keys(selectedRestrictions).includes(restrictionId) &&
      Object.values(selectedRestrictions[restrictionId]).includes(value)
    ) {
      newRestrictions[restrictionId] = newRestrictions[restrictionId].filter(
        role => role !== value,
      );
    } else {
      newRestrictions[restrictionId] = (newRestrictions[restrictionId] || []).concat(value);
    }
    setSelectedRestrictions(newRestrictions);
  };

  const onClick = () => {
    if (noSelectedRestriction) return;
    onClose();
    handleSubmit();
  };

  const renderRestriction = restriction => {
    const selectedRestrictionLength = selectedRestrictions[restriction?.id]?.length;
    const restrictionRolesLength = restriction?.restrictionRoles?.length;
    return (
      <List key={restriction.id} className={classes.marginTop}>
        <ListItem
          dense
          button
          onClick={event => handleOrgaMultiCheckboxChange(event, restriction)}
          className={classes.listItem}
        >
          <Grid item className={classes.avatar}>
            {restriction.avatar}
          </Grid>
          <ListItemText primary={<Typography variant="h6">{t(restriction.name)}</Typography>} />
          <ListItemIcon>
            <Checkbox
              checked={
                selectedRestrictionLength > 0 &&
                restrictionRolesLength === selectedRestrictionLength
              }
              indeterminate={
                selectedRestrictionLength > 0 &&
                restrictionRolesLength !== selectedRestrictionLength
              }
              color="primary"
              onChange={event => handleOrgaMultiCheckboxChange(event, restriction)}
            />
          </ListItemIcon>
        </ListItem>

        <List>
          {restriction?.restrictionRoles?.map(restrictionDescriptor => (
            <ListItem
              key={restrictionDescriptor[1]}
              dense
              button
              onClick={event =>
                handleMultiCheckboxChange(event, restriction, restrictionDescriptor[1])
              }
            >
              <ListItemIcon>
                <Checkbox
                  checked={
                    Object.keys(selectedRestrictions).includes(restriction.id.toString()) &&
                    Object.values(selectedRestrictions[restriction.id.toString()]).includes(
                      restrictionDescriptor[1],
                    )
                  }
                  color="primary"
                />
              </ListItemIcon>
              <ListItemText primary={restrictionDescriptor[0]} />
            </ListItem>
          ))}
        </List>
      </List>
    );
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant="h4" gutterBottom>
        {t('APP.CARENOTEBOOKS.SHOW.CREATE_POST.PUBLIC')}
      </Typography>
      <Typography variant="body2">
        {t('APP.CARENOTEBOOKS.SHOW.CREATE_POST.PUBLIC.SUBTITLE')}
      </Typography>
      <Divider light className={classes.divider} />
      {restrictions?.map(restriction => renderRestriction(restriction))}
      <Grid align="center">
        <Button color="primary" style={{ marginRight: 8 }} onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onClick}
          disabled={noSelectedRestriction}
        >
          {t('SEND')}
        </Button>
      </Grid>
    </Paper>
  );
};

export default RestrictionsSelection;
