import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import CardTask from '../Shared/CardTask';
import { getTwigEndDate } from '../../../Services/DataHelper';

const useStyles = makeStyles(theme => ({
  gridActivity: {
    marginBottom: '20px',
  },
  link: {
    color: theme.current.primary.main,
  },
  bold: {
    fontWeight: 600,
  },
}));

type Props = {
  twigs: Array,
  title: String,
  subTitle: String,
  showMoreLink: String,
};

const OrganisationTwigs = ({ twigs, title, subTitle, showMoreLink }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {twigs && twigs.length !== 0 && (
        <>
          <Divider style={{ margin: '40px 0 40px' }} />
          <Grid container>
            <Grid item md={12}>
              <Typography variant="h3" component="h2" className={classes.bold}>
                {t(title)}
              </Typography>
              <Typography paragraph variant="subtitle2" gutterBottom>
                {t(subTitle)}
              </Typography>
              <Grid container spacing={2} className={classes.gridActivity}>
                {twigs.map(twig => (
                  <Grid item xs={12} sm={6} md={3} key={`${twig.task.id}-${twig.event_date}`}>
                    <CardTask
                      task={{
                        ...twig.task,
                        start_date: twig.event_date,
                        end_date: getTwigEndDate(twig),
                        twig_for_date: twig,
                      }}
                      isReferenced
                    />
                  </Grid>
                ))}
              </Grid>
              {showMoreLink && (
                <Typography variant="h6" align="left" gutterBottom>
                  <MuiLink href={`${showMoreLink}`} className={classes.link}>
                    {t('APP.ACTIVITY.MORE.LINK')}
                  </MuiLink>
                </Typography>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default OrganisationTwigs;
