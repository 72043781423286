/* eslint-disable react/no-danger */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { askTransport, MORMAL_ZONES } from '../../../PagesLeagues/Mormal/MormalHelper';
import UserActions from '../../../Redux/UsersRedux';
import LeadsActions from '../../../Redux/LeadsRedux';
import OrganisationActions from '../../../Redux/OrganisationRedux';
import QuotationTransportSummaryCard from './QuotationTransportSummaryCard';
import { coordinatesArrayToString, getTripInfo, getTripPrice } from '../../../Services/DataHelper';
import AutoCompletePlaces from '../../Shared/AutoCompletePlaces';
import { getCdnUrl } from '../../../Services/ImageResizer';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '500px',
    overflow: 'scroll',
  },
  media: {
    height: 180,
  },
  doneIcon: {
    fontSize: 40,
    color: 'green',
    marginLeft: theme.spacing(-3),
  },
  block: {
    paddingBottom: theme.spacing(2),
  },
  lastBlock: {
    paddingLeft: theme.spacing(1),
  },
  title: {
    textTransform: 'none',
    fontWeight: '700',
  },
  buttonContainer: {
    padding: theme.spacing(3),
    justifyContent: 'center',
  },
  button: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  places: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: 0,
    zIndex: 2000,
  },
  container: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    justifyContent: 'center',
  },
  selectLabel: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff',
  },
}));

type Props = {
  task: Object,
  currentUser: Object,
  helper: Object,
  onClose: Function,
};

const framSlug = 'familles-rurales-avesnois-mormal';
const framHoldingSlug = 'familles-rurales';

const CardTaskRegisterSuccess = ({ task, currentUser, helper, onClose }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [adress, setAdress] = React.useState();
  const [openSummary, setOpenSummary] = React.useState(false);
  const [tripInfo, setTripInfo] = React.useState(null);

  const organisation = useSelector(state => state.organisation.organisation);
  const isOrganisationReady =
    organisation?.slug === framSlug && organisation?.holding_slug === framHoldingSlug;

  const [arrivalAddress, departureDate, departureTime, reason, askForMe] = React.useMemo(() => {
    return [
      {
        formattedAddress: task?.formatted_address,
        latitude: task?.coordinates?.latitude,
        longitude: task?.coordinates?.longitude,
      },
      moment(task?.twig_for_date?.event_date).format('L'),
      moment(task?.twig_for_date?.event_date).subtract(1, 'hour').format('LT'),
      t('APP.ACTIVITY.ASK_FOR_TRANSPORT.REASON', { activityName: task?.description }),
      currentUser?.id === helper?.id,
    ];
  }, [task, currentUser, helper, t]);

  const upsertForm = form =>
    dispatch(
      UserActions.upsertForm({
        ...form,
        reason,
        helped_first_name: askForMe ? '' : helper.first_name,
        helped_last_name: askForMe ? '' : helper.last_name,
        ask_for: askForMe ? 'me' : 'other',
      }),
    );

  const transportService = React.useMemo(() => {
    if (organisation?.services) {
      return organisation.services.find(
        os => os.service && os.service.service_category.slug === 'transport',
      );
    }
    return null;
  }, [organisation]);

  React.useEffect(() => {
    if (adress && arrivalAddress) {
      getTripInfo(adress, arrivalAddress, false).then(data => setTripInfo(data));
    }
  }, [adress, arrivalAddress]);

  const price = React.useMemo(() => {
    if (organisation) {
      const selectedServices = organisation.services
        .map(os => os.service)
        .filter(serv => serv.service_category.slug === 'transport');
      if (!selectedServices || selectedServices.length === 0) return null;

      return getTripPrice(selectedServices[0], tripInfo, null, null);
    }
    return 0;
  }, [organisation, tripInfo]);

  const handleAskClick = () => {
    if (!isOrganisationReady) {
      dispatch(OrganisationActions.getOrganisationRequest(framHoldingSlug, framSlug, 'full'));
    }
    setOpenSummary(true);
  };

  const handleEditAskForTransport = () => {
    askTransport(
      adress,
      arrivalAddress,
      moment(task?.twig_for_date?.event_date),
      moment(task?.twig_for_date?.event_date).subtract(1, 'hours'),
      upsertForm,
      () => console.log('error'),
      () => history.push('/familles-rurales/familles-rurales-avesnois-mormal/quotation/1305'),
    );
    onClose();
  };
  const handleCreateLead = () => {
    const departureDateTime = `${moment(departureDate).format('YYYY-MM-DD')} ${departureTime}:00`;
    const leadToCreate = {
      organisation_id: organisation.id,
      ask_for: askForMe ? 'me' : 'other',
      latitude: currentUser?.coordinates?.latitude || 0,
      longitude: currentUser?.coordinates?.longitude || 0,
      locality: currentUser?.locality || '',
      service_id: transportService.service_id,
      description: reason,
      recurrence: null,
      phone: askForMe ? currentUser?.phone : helper?.phone,
      email: currentUser?.email,
      gender: currentUser?.gender,
      helper_first_name: currentUser?.first_name,
      helper_last_name: currentUser?.last_name,
      helper_pathology: '',
      birthday: askForMe ? currentUser?.birthday : '',
      helped_first_name: askForMe ? '' : helper?.first_name,
      helped_last_name: askForMe ? '' : helper?.last_name,
      helped_pathology: '',
      formatted_address: currentUser?.formatted_address || '',
      departure_from: { ...adress, formatted_address: adress.formattedAddress },
      arrival_to: { ...arrivalAddress, formatted_address: arrivalAddress.formattedAddress },
      meta: {
        departure_date: departureDateTime,
        departureFormattedAddress: adress.formattedAddress,
        arrivalFormattedAddress: arrivalAddress.formattedAddress,
        departure_locality: adress.locality,
        arrival_locality: task?.locality,
        round_trip: false,
        passager_count: 1,
        need_accompagnement: false,
        duration: null,
        estimatedPrice: price,
        trip_duration: tripInfo.duration,
        distance: tripInfo.distance,
        geolocs: coordinatesArrayToString(tripInfo.geolocs),
      },
    };
    dispatch(LeadsActions.createQualifiedLeadRequest(framHoldingSlug, framSlug, leadToCreate));

    onClose();
  };

  if (openSummary && isOrganisationReady && transportService) {
    return (
      <QuotationTransportSummaryCard
        organisation={organisation}
        departureAddress={adress?.formattedAddress}
        arrivalAddress={arrivalAddress.formattedAddress}
        departureDate={departureDate}
        departureTime={departureTime}
        helper={helper}
        price={price}
        onCancel={() => setOpenSummary(false)}
        onEdit={handleEditAskForTransport}
        onValidate={handleCreateLead}
      />
    );
  }

  return (
    <Card className={classes.root}>
      <CardMedia
        component="img"
        className={classes.media}
        src={getCdnUrl(task?.cover_url, 280)}
        title={task?.description}
      />
      <CardContent>
        <Grid
          container
          spacing={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justify="center"
          className={classes.block}
        >
          <Grid item xs={12}>
            <Typography align="center" variant="h5" component="h2">
              {t('APP.ACTIVITY.REGISTERED_CONGRATS')}
            </Typography>{' '}
            <Typography align="center" gutterBottom variant="body1" component="h6">
              {t('APP.ACTIVITY.PARTICIPANTS.REGISTERED')}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.block}>
          <Typography align="center" gutterBottom color="textSecondary" variant="h5" component="h2">
            {task?.description}
          </Typography>
          <Typography
            gutterBottom
            align="center"
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {`Le ${moment(task?.twig_for_date?.event_date).format('dddd LL')} ${
              task?.twig_for_date?.event_start_time
            }`}
          </Typography>
          <Typography align="center" variant="body2" color="textSecondary" component="p">
            {task?.formatted_address}
          </Typography>
        </Grid>
        <Divider style={{ margin: '20px' }} />
        <Grid className={classes.lastBlock}>
          <Typography gutterBottom variant="h5" component="h6" className={classes.title}>
            {t('APP.ACTIVITY.ASK_FOR_TRANSPORT.TITLE')}
          </Typography>
          <Typography variant="body2" component="p">
            {t('APP.ACTIVITY.ASK_FOR_TRANSPORT.DESCRIPTION')}
          </Typography>
          <FormControl margin="normal" component="fieldset" fullWidth style={{ border: 1 }}>
            <AutoCompletePlaces
              onSelect={value => {
                setAdress({
                  formattedAddress: value.formatted_address,
                  latitude: value.coordinates?.lat,
                  longitude: value.coordinates?.lng,
                  locality: value.locality,
                  postCode: value.postcode,
                });
              }}
              onClear={() => {
                setAdress({
                  formattedAddress: '',
                  latitude: '',
                  longitude: '',
                  locality: '',
                  postCode: '',
                });
              }}
              placeHolder={t('APP.ACTIVITY.ADDRESS.PLACE_HOLDER')}
              defaultValue={adress?.formattedAddress}
              id="helperAddress"
              geographicLimits={MORMAL_ZONES.start}
            />
          </FormControl>
        </Grid>
      </CardContent>
      <CardActions className={classes.buttonContainer}>
        <Button className={classes.button} color="primary" onClick={onClose}>
          {t('NO_THANKS')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          disabled={!adress?.formattedAddress || openSummary}
          onClick={handleAskClick}
        >
          {t('APP.ACTIVITY.ASK_FOR_TRANSPORT.BUTTON')}
        </Button>
      </CardActions>
    </Card>
  );
};

export default CardTaskRegisterSuccess;
