import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Notes from '@material-ui/icons/Notes';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import LinearProgress from '@material-ui/core/LinearProgress';

import { Images, muiTheme } from '../../Themes';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import Seo from '../../Routing/Seo';
import Routes from '../../Routing/Routes';
import { getCdnUrl } from '../../Services/ImageResizer';
import SectionCta from '../Pages/Components/SectionCta';
import { organisationUnaCTA } from '../../Services/OrganisationHelper';
import NoMatch404 from '../NoMatch404';
import NoMatch401 from '../NoMatch401';
import ArticlesActions from '../../Redux/ArticlesRedux';
import { getLeagueSearchResultTabs } from '../../Services/AlgoliaHelper';
import LeagueArticleBreadcrumb from '../../Components/Shared/Breadcrumbs/LeagueArticleBreadcrumb';
import HoldingArticleBreadcrumb from '../../Components/Shared/Breadcrumbs/HoldingArticleBreadcrumb';

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  media: {
    objectFit: 'cover',
    maxHeight: '260px',
    borderRadius: '3px',
  },
  minHeight: { minHeight: '90vh' },
  headerContainer: {
    backgroundColor: '#fff',
    boxShadow:
      '0px 2px 3px -1px rgba(0, 0, 0, 0.12), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 1px 0px 0px rgba(0, 0, 0, 0.12)',
  },
  headerLayout: {
    ...LayoutStyles.fullWidthLayout,
    width: '100%',
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 !important',
    },
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    paddingTop: '40px',
    display: 'flex',
    position: 'relative',
    alignItems: 'flex-start',
    flexGrow: 1,
  },
  leftContent: {
    order: 2,
    [theme.breakpoints.up(`md`)]: {
      order: 1,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 0 !important',
    },
  },
  rightContent: {
    order: 1,
    [theme.breakpoints.up(`md`)]: {
      position: 'sticky',
      top: '4rem',
      order: 2,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 0 0 !important',
    },
  },
  chips: {
    paddingTop: theme.spacing(1),
  },
  chip: {
    height: 20,
    marginRight: theme.spacing(1),
    backgroundColor: muiTheme.current.primary.main,
    color: '#fff',
  },
  attachments: {
    paddingLeft: 0,
  },
  goodToKnow: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2, 2, 1),
    borderRadius: '4px',
    backgroundColor: '#fff',
  },
  goodToKnowHeader: {
    alignItems: 'center',
  },
  notes: {
    marginRight: theme.spacing(1),
  },
  file: {
    marginTop: theme.spacing(3),
  },
  subtitle: {
    paddingLeft: 2,
  },
  link: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(-1),
  },
  description: {
    textAlign: 'justify',
    wordBreak: 'break-word',
  },
}));

const ShowArticle = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { t } = useTranslation();
  const location = useLocation();
  const { article_slug: articleSlug } = match.params;
  const queryParam = new URLSearchParams(location.search);
  const urlParams = {
    goBack: queryParam.get('goBack') || '',
  };

  const article = useSelector(state => state.articles.article);
  const isFetching = useSelector(state => state.articles.isFetching.getArticle);
  const errors = useSelector(state => state.articles.errors.getArticle);

  const articleFetch =
    !isFetching &&
    (!errors || errors.article_slug !== articleSlug) &&
    article?.slug === articleSlug;

  const fallbackCoverUrl = Images.news.news6;
  const isLeagueArticle = article?.postable_type === 'League';
  const isHoldingArticle = article?.postable_type === 'Holding';
  const showLeagueBreadcrumb =
    article &&
    articleSlug === article.slug &&
    isLeagueArticle &&
    getLeagueSearchResultTabs(Routes.currentNamespace()).includes('articles');

  const showHoldingBreadcrumb = article && articleSlug === article.slug && isHoldingArticle;

  React.useEffect(() => {
    if (articleSlug) {
      dispatch(ArticlesActions.getArticleRequest(articleSlug));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleSlug]);

  if (!isFetching && errors?.status === 404 && errors?.article_slug === articleSlug) {
    return <NoMatch404 />;
  }

  if (!isFetching && errors?.status === 401 && errors?.article_slug === articleSlug) {
    return <NoMatch401 />;
  }

  const renderHeaderSection = () => {
    return (
      <>
        {/* TITLE PANEL */}
        <Grid item sm={12} xs={12}>
          {urlParams.goBack === 'search' && (
            <a href="/recherche/articles" className={classes.link}>
              <ArrowLeftIcon />
              {t('APP.ARTICLES.SHOW.BACK')}
            </a>
          )}
          <Typography variant="h2" component="h1" className={classes.bold}>
            {article.title}
          </Typography>
          <Typography variant="subtitle2" paragraph className={classes.subtitle}>
            {t('APP.ARTICLES.SHOW.CREATE_AT', {
              date: moment(article.created_at).format('LL'),
              time: moment(article.created_at).format('LT'),
            })}{' '}
            {`${t(isLeagueArticle ? 'APP.ARTICLES.LEAGUE.BY' : 'APP.ARTICLES.HOLDING.BY')} `}
            {isHoldingArticle ? (
              <Link to={`/${article.postable_slug}`}>{article.postable_name}</Link>
            ) : (
              article.postable_name
            )}
          </Typography>
          <Typography
            variant="subtitle2"
            paragraph
            className={classes.subtitle}
            style={{ marginTop: -16 }}
          >
            {t('APP.ARTICLES.VISIBILITY')}{' '}
            {t(`APP.ARTICLES.VISIBILITY.${article.visibility?.toUpperCase()}`)?.toLowerCase()}
          </Typography>
          {renderChips()}
        </Grid>
      </>
    );
  };

  const renderDescription = () => {
    return (
      <>
        <Typography
          paragraph
          variant="body1"
          gutterBottom
          dangerouslySetInnerHTML={{ __html: article.content }}
          className={classes.description}
        />
      </>
    );
  };

  const renderGoodToKnow = () => {
    return (
      <Grid className={classes.goodToKnow}>
        <Grid container className={classes.goodToKnowHeader}>
          <Notes fontSize="small" className={classes.notes} />
          <Typography component="p" variant="h6" className={classes.GtnTitle}>
            {t('APP.ARTICLES.GOOD_TO_KNOW')}
          </Typography>
        </Grid>
        <Typography
          paragraph
          variant="body1"
          gutterBottom
          dangerouslySetInnerHTML={{ __html: article.good_to_know }}
        />
      </Grid>
    );
  };

  const renderAttachments = () => {
    return (
      <Grid className={classes.file}>
        {article.attachments?.length > 0 && (
          <Typography variant="h4" className={classes.bold}>
            {t('APP.ARTICLES.SHOW.FILES.TITLE')}
          </Typography>
        )}
        {article.attachments?.map(attachment => (
          <a
            href={attachment.download_url}
            key={attachment.id}
            target="_blank"
            without="true"
            rel="noopener noreferrer"
          >
            <Grid className={classes.attachments}> {attachment.filename}</Grid>
          </a>
        ))}
      </Grid>
    );
  };

  const renderChips = () => {
    return (
      <Grid className={classes.chips}>
        {article.related_words?.map(word => (
          <Chip key={word} className={classes.chip} label={word} />
        ))}
      </Grid>
    );
  };

  return (
    <>
      {isFetching && <LinearProgress />}
      {articleFetch && (
        <>
          <Helmet>
            {Seo.title(`${article.title}${t(`${Routes.currentNamespace()}.SEO.SUFFIX`)}`)}
            {Seo.description(article.content)}
            {Seo.image(article.cover_image_url || fallbackCoverUrl)}
            {Seo.canonical(article.creation_league_id, match.url)}
          </Helmet>

          <Grid container direction="column" className={classes.minHeight}>
            {/* HEADER SECTION */}
            <Grid container className={classes.headerContainer}>
              <Grid container className={classes.headerLayout}>
                {renderHeaderSection()}
              </Grid>
            </Grid>
            {/* CONTENT SECTION */}
            <Grid container spacing={8} className={classes.layout}>
              {/* RESUME PANEL */}
              {article?.visibility === 'public' &&
                ['UNA', 'SLA'].includes(Routes.currentNamespace()) && (
                  <Grid item md={4} xs={12} className={classes.rightContent} align="center">
                    {Routes.currentNamespace() === 'UNA' && (
                      <Grid>
                        <SectionCta
                          title={`${Routes.currentNamespace()}.PAGE.ORDER1.TITLE1`}
                          subTitle={`${Routes.currentNamespace()}.PAGE.ORDER1.SUBTITLE1`}
                          organisationCTA={organisationUnaCTA}
                          buttonLabel="APP.ASK_CONTACT.NAME"
                          descriptionPlaceHolder="APP.UNA.ASK_CONTACT.TEXT_FIELD.LABEL.NEEDS.PLACEHOLDER"
                          withEmail
                        />
                        <SectionCta
                          title={`${Routes.currentNamespace()}.PAGE.ORDER1.TITLE2`}
                          subTitle={`${Routes.currentNamespace()}.PAGE.ORDER1.SUBTITLE2`}
                          searchPanel
                          select
                        />
                      </Grid>
                    )}
                    {Routes.currentNamespace() === 'SLA' && (
                      <Grid>
                        <SectionCta
                          title={`${Routes.currentNamespace()}.PAGE.ORDER1.TITLE1`}
                          subTitle={`${Routes.currentNamespace()}.PAGE.ORDER1.SUBTITLE1`}
                          // organisationCTA={organisationSlaCTA}
                          buttonLabel="APP.ASK_CONTACT.NAME"
                          descriptionPlaceHolder="APP.SLA.ASK_CONTACT.TEXT_FIELD.LABEL.OTHER.PLACEHOLDER"
                          withEmail
                          withPhone
                        />
                        <SectionCta
                          title={`${Routes.currentNamespace()}.PAGE.ORDER2.TITLE1`}
                          subTitle={`${Routes.currentNamespace()}.PAGE.ORDER2.SUBTITLE1`}
                          searchPanel
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              {/* CONTENT PANEL */}
              <Grid item md={8} xs={12} className={classes.leftContent}>
                {/* DESCRIPTION PANEL */}
                {article.cover_image_url && (
                  <Paper>
                    <CardMedia
                      className={classes.media}
                      component="img"
                      src={getCdnUrl(article.cover_image_url, 800) || fallbackCoverUrl}
                    />
                  </Paper>
                )}
                {renderDescription()}
                {article.good_to_know &&
                  article.good_to_know.replace(/<[^>]*>/g, '') !== '' &&
                  renderGoodToKnow()}
                {renderAttachments()}
              </Grid>
            </Grid>
            {showLeagueBreadcrumb && <LeagueArticleBreadcrumb article={article} />}
            {showHoldingBreadcrumb && <HoldingArticleBreadcrumb article={article} />}
          </Grid>
        </>
      )}
    </>
  );
};

export default ShowArticle;
