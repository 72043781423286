import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ScheduleIcon from '@mui/icons-material/Schedule';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';

import { green, orange, red, grey } from '@material-ui/core/colors';
import moment from 'moment';
import TasksActions from '../../../Redux/TasksRedux';
import { getRole, getHelpedsFamilies } from '../../../Acl/Controls';
import { CheckRoleRule } from '../../../Acl/Rules';
import { getCurrentDate } from '../../../Services/DataHelper';
import Roles, { getFormattedPendingRole, getFormattedRole } from '../../../Acl/Roles';
import WelloAvatar from '../../Shared/WelloAvatar';
import ConfirmationDialog from '../../Shared/ConfirmationDialog';

const useStyles = makeStyles(theme => ({
  greenCheck: {
    color: green[600],
  },
  redCheck: {
    color: red[600],
  },
  greyCheck: {
    color: grey[500],
  },
  chip: {
    backgroundColor: orange[500],
    color: '#fff',
  },
  avatarSize: {
    height: 20,
    width: 20,
    backgroundColor: orange[500],
    color: '#fff',
    position: 'absolute',
  },
  btn: {
    marginRight: theme.spacing(1),
  },
  badge: {
    '&>*': {
      padding: 0,
      bottom: 0,
      right: 0,
    },
  },
  role: {
    lineHeight: 1,
  },
}));

type Props = {
  helper: Object,
  twig: Object,
  currentUser: Object,
  showTwig: Boolean,
};

const RowTwigMembers = ({ helper, twig, currentUser, showTwig }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { holding_slug: holdingSlug, organisation_slug: orgaSlug } = match.params;

  const [helperId, setHelperId] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openUnregisterDialog, setOpenUnregisterDialog] = React.useState(false);
  const [openConfirmValidationDialog, setOpenConfirmValidationDialog] = React.useState(false);
  const openMenu = Boolean(anchorEl);
  const isFamilyTwig = Boolean(!twig?.task?.holding_slug);
  const role = isFamilyTwig
    ? getRole(currentUser, holdingSlug, orgaSlug, twig?.task?.organisation_slug)
    : getRole(currentUser, twig?.task?.holding_slug, twig?.task?.organisation_slug, null);
  const validatedOnTwig = helper?.validated_on_twig;
  const notInterested = helper?.not_interested;
  const helperIsCurrentUser = helper?.id === currentUser?.id;
  const currentUserIsMainHelper =
    getHelpedsFamilies(currentUser) &&
    getHelpedsFamilies(currentUser).length > 0 &&
    getHelpedsFamilies(currentUser).find(family => family.admin.id === helper.id);

  const canSeeHelperInfo =
    CheckRoleRule(role, 'tasks:manage') || helperIsCurrentUser || currentUserIsMainHelper;
  const canManage = CheckRoleRule(role, 'tasks:manage');
  const twigOver = twig.event_date < getCurrentDate();

  const helpedName = canSeeHelperInfo
    ? `${helper?.helped?.last_name?.toUpperCase()} ${helper?.helped?.first_name}`
    : helper?.helped?.first_name;
  const helperName = canSeeHelperInfo
    ? `${helper?.last_name?.toUpperCase()} ${helper?.first_name}`
    : helper?.first_name;

  const handleActionsMenu = (event, hId) => {
    setAnchorEl(event.currentTarget);
    setHelperId(hId);
  };

  const handleValidateHelperRegister = () => {
    if (helper.validated_on_orga) {
      onValidateMember();
    } else setOpenConfirmValidationDialog(true);

    setAnchorEl(null);
  };

  const onValidateMemberClick = () => {
    setOpenConfirmValidationDialog(false);
    onValidateMember();
  };

  const onValidateMember = () => {
    dispatch(
      TasksActions.validateHelperOnTaskRequest(
        twig.task.holding_slug,
        twig.task.organisation_slug,
        twig.task.slug,
        helperId,
        twig.event_date,
      ),
    );
    setHelperId(null);
  };

  const handleRemoveHelper = event => {
    dispatch(
      TasksActions.unregisterHelperOnTaskRequest(
        twig.task.holding_slug,
        twig.task.organisation_slug,
        isFamilyTwig && twig.task.organisation_slug,
        twig.task.slug,
        helperId || helper.id,
        twig.event_date,
      ),
    );
    setAnchorEl(null);
    setHelperId(null);
    event.preventDefault();
  };

  const handleRemoveCurrentHelper = event => {
    dispatch(
      TasksActions.unregisterHelperOnTaskRequest(
        twig.task.holding_slug,
        twig.task.organisation_slug,
        isFamilyTwig && twig.task.organisation_slug,
        twig.task.slug,
        currentUser.id,
        twig.event_date,
      ),
    );
    setAnchorEl(null);
    event.preventDefault();
  };

  const handleCloseUnregisterDialog = () => {
    setOpenUnregisterDialog(false);
    setAnchorEl(null);
  };

  return (
    <>
      <TableRow>
        {!showTwig && (
          <>
            <TableCell />
            <TableCell />
          </>
        )}
        <TableCell component="th" scope="row">
          {validatedOnTwig && !isFamilyTwig && !notInterested && (
            <CheckIcon className={classes.greenCheck} />
          )}
          {!validatedOnTwig && !isFamilyTwig && !notInterested && (
            <ScheduleIcon className={classes.greyCheck} />
          )}
          {notInterested && <CloseIcon className={classes.redCheck} />}
        </TableCell>
        <TableCell>
          {!twig?.task?.registrable_roles.includes(Roles.PRIMARY_HELPED) && helper.helped ? (
            <Tooltip
              title={t('APP.ACTIVITY.REGISTER_MEMBER.NO_REGISTER.PRIMARY_HELPED', {
                helped: helpedName,
              })}
              placement="bottom"
            >
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                className={classes.badge}
                badgeContent={
                  <Avatar
                    alt={t('APP.ACTIVITY.REGISTER_MEMBER.NO_REGISTER.PRIMARY_HELPED')}
                    className={classes.avatarSize}
                  />
                }
              >
                <WelloAvatar
                  avatarUrl={helper?.avatar_url}
                  firstName={helper?.first_name}
                  lastName={helper?.last_name}
                  backgroundColor={helper?.avatar_background_color}
                  avatarStyle={{ height: 30, width: 30, fontSize: '0.90rem' }}
                  helper={canSeeHelperInfo ? helper : null}
                />
              </Badge>
            </Tooltip>
          ) : (
            <WelloAvatar
              avatarUrl={helper?.avatar_url}
              firstName={helper?.first_name}
              lastName={helper?.last_name}
              backgroundColor={helper?.avatar_background_color}
              avatarStyle={{ height: 30, width: 30, fontSize: '0.90rem' }}
              helper={canSeeHelperInfo ? helper : null}
            />
          )}
        </TableCell>
        <TableCell style={{ textTransform: 'capitalize' }}>
          {helperName}
          {CheckRoleRule(role, 'tasks:manage') && (
            <Typography variant="subtitle2" className={classes.role}>
              {helper.validated_on_orga
                ? getFormattedRole(helper?.role, t)
                : getFormattedPendingRole(helper?.role, t)}
            </Typography>
          )}
        </TableCell>
        <TableCell>{canManage && helper?.phone}</TableCell>
        <TableCell>
          {canSeeHelperInfo && !validatedOnTwig && !isFamilyTwig && !notInterested && (
            <Chip
              className={classes.chip}
              size="small"
              label={t('APP.ACTIVITY.ACTION.REGISTER_TO_VALIDATE')}
            />
          )}
        </TableCell>
        <TableCell align="right">
          {!showTwig && canSeeHelperInfo && (
            <>
              <IconButton
                onClick={event => handleActionsMenu(event, helper?.id)}
                aria-label="More"
                aria-owns={openMenu ? 'actions-menu' : undefined}
                aria-haspopup="true"
                disabled={twigOver}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="actions-menu"
                open={openMenu}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
              >
                {canManage && !isFamilyTwig && !notInterested && (
                  <MenuItem onClick={handleValidateHelperRegister} disabled={validatedOnTwig}>
                    <ListItemText>
                      {!validatedOnTwig
                        ? t('APP.ACTIVITY.ACTION.VALIDATE_REGISTER')
                        : t('APP.ACTIVITY.ACTION.VALIDATED_REGISTER')}
                    </ListItemText>
                  </MenuItem>
                )}
                {canManage && (
                  <MenuItem onClick={handleRemoveHelper}>
                    <ListItemText>
                      {t(
                        notInterested
                          ? 'APP.ACTIVITY.ACTION.REMOVE'
                          : 'APP.ACTIVITY.ACTION.UNREGISTER',
                      )}
                    </ListItemText>
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
          {showTwig && (helperIsCurrentUser || currentUserIsMainHelper) && !notInterested && (
            <Button
              color="primary"
              size="small"
              onClick={() => setOpenUnregisterDialog(true)}
              disabled={twigOver}
            >
              {t(
                helperIsCurrentUser
                  ? 'APP.ACTIVITY.PARTICIPANTS.UNREGISTERED'
                  : 'APP.ACTIVITY.ACTION.UNREGISTER',
              )}
            </Button>
          )}
        </TableCell>
      </TableRow>
      <Dialog open={openUnregisterDialog} onClose={handleCloseUnregisterDialog}>
        <DialogTitle align="center">
          <Typography>
            {helperIsCurrentUser
              ? t('APP.ACTIVITY.PARTICIPANTS.CONFIRMATION_UNREGISTERED', {
                  description: twig?.task?.description,
                  startDate: `${moment(twig?.event_date).format('dddd LL')} ${
                    twig?.event_start_time
                  }`,
                })
              : t('APP.ACTIVITY.PARTICIPANTS.CONFIRMATION_UNREGISTERED.PRIMARY_HELPED', {
                  user: helper?.fullname,
                  description: twig?.task?.description,
                  startDate: `${moment(twig?.event_date).format('dddd LL')} ${
                    twig?.event_start_time
                  }`,
                })}
          </Typography>
        </DialogTitle>
        <DialogContent align="center">
          <Button variant="outlined" className={classes.btn} onClick={handleCloseUnregisterDialog}>
            {t('CANCEL')}
          </Button>
          <Button
            variant="outlined"
            onClick={helperIsCurrentUser ? handleRemoveCurrentHelper : handleRemoveHelper}
          >
            {t(
              helperIsCurrentUser
                ? 'APP.ACTIVITY.PARTICIPANTS.UNREGISTERED'
                : 'APP.ACTIVITY.ACTION.UNREGISTER',
            )}
          </Button>
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        openDialog={openConfirmValidationDialog}
        onCancel={() => setOpenConfirmValidationDialog()}
        onClickConfirm={onValidateMemberClick}
        dialogTitle={t('APP.ACTIVITY.MEMBERS.VALIDATE.TITLE')}
        dialogContent={t('APP.ACTIVITY.MEMBERS.VALIDATE.CONTENT')}
      />
    </>
  );
};

export default RowTwigMembers;
