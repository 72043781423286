import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { checkEmailValidity, checkPostCodeIsValid } from '../../Services/DataHelper';
import Routes from '../../Routing/Routes';
import PhoneControl from '../../Components/Shared/PhoneControl';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(0, 1),
  },
}));

type Props = {
  handleSubmit: Function,
  withPostCode: Boolean,
  hideFieldsIfCurrentUser: Boolean,
};

const SignUpForm = ({ handleSubmit, withPostCode, hideFieldsIfCurrentUser }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isFetching = useSelector(state => state.auth.isFetching);
  const isFetchingCreateUser = useSelector(state => state.users.isFetching.createUser);
  const currentUser = useSelector(state => state.auth.currentUser);

  const [firstName, setFirstName] = React.useState(currentUser?.first_name || '');
  const [lastName, setLastName] = React.useState(currentUser?.last_name || '');
  const [phone, setPhone] = React.useState(currentUser?.phone || '');
  const [countryCode, setCountryCode] = React.useState(currentUser?.country_code || 'fr');
  const [phoneError, setPhoneError] = React.useState(null);
  const [email, setEmail] = React.useState(currentUser?.email || '');
  const [emailError, setEmailError] = React.useState(null);
  const [description, setDescription] = React.useState(
    currentUser?.description ||
      t('APP.USER.TEXT_FIELD.DEFAULT_VALUE.DESCRIPTION', {
        league: t(`${Routes.currentNamespace()}.SEO.SITENAME`),
      }),
  );
  const [postCode, setPostCode] = React.useState('');
  const [postCodeError, setPostCodeError] = React.useState(null);
  const isUnvalidForm =
    !firstName ||
    !lastName ||
    phoneError ||
    !email ||
    emailError ||
    (withPostCode && (!postCode || postCodeError !== null));

  const showFields = !currentUser || !hideFieldsIfCurrentUser;
  return (
    <form
      className={classes.form}
      onSubmit={event =>
        handleSubmit(event, firstName, lastName, phone, countryCode, phoneError, email, description)
      }
    >
      <Grid container>
        {showFields && (
          <>
            <Grid item xs={12} sm={6} className={classes.padding}>
              <TextField
                name="firstName"
                value={firstName}
                onChange={event => setFirstName(event.target.value)}
                label={t('WELLO.SIGN_UP.FIRST_NAME')}
                InputProps={{
                  autoComplete: 'firstName',
                  autoFocus: true,
                  readOnly: !!currentUser,
                  disabled: !!currentUser,
                }}
                margin="normal"
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.padding}>
              <TextField
                name="lastName"
                value={lastName}
                onChange={event => setLastName(event.target.value)}
                label={t('WELLO.SIGN_UP.LAST_NAME')}
                InputProps={{
                  autoComplete: 'lastName',
                  readOnly: !!currentUser,
                  disabled: !!currentUser,
                }}
                margin="normal"
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            {((!!currentUser && phone) || !currentUser) && (
              <Grid item xs={12} sm={withPostCode ? 6 : 12} className={classes.padding}>
                <PhoneControl
                  phone={phone}
                  phoneError={phoneError}
                  countryCode={countryCode}
                  setPhone={setPhone}
                  setPhoneError={setPhoneError}
                  setCountryCode={setCountryCode}
                  readOnly={!!currentUser}
                  variant="standard"
                  disabled={!!currentUser}
                />
              </Grid>
            )}
            {withPostCode && (
              <Grid item xs={12} sm={6} className={classes.padding}>
                <TextField
                  required
                  name="postCode"
                  value={postCode}
                  type="text"
                  label={t('APP.ASK_CALL.TEXT_FIELD.LABEL.DEPT')}
                  onChange={event => setPostCode(event.target.value)}
                  onBlur={() => setPostCodeError(checkPostCodeIsValid(postCode))}
                  helperText={t(postCodeError)}
                  error={postCodeError !== null}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            )}
            {((!!currentUser && email) || !currentUser) && (
              <Grid item xs={12} className={classes.padding}>
                <TextField
                  name="email"
                  value={email}
                  onChange={event => setEmail(event.target.value?.trim())}
                  label={t('WELLO.SIGN_UP.EMAIL')}
                  InputProps={{
                    type: 'email',
                    autoComplete: 'email',
                    readOnly: !!currentUser,
                    disabled: !!currentUser,
                  }}
                  margin="normal"
                  fullWidth
                  required={!currentUser}
                  onBlur={() => setEmailError(!checkEmailValidity(email))}
                  error={emailError}
                  helperText={emailError && t('INCORRECT_EMAIL')}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            )}
            <Grid item xs={12} className={classes.padding}>
              <TextField
                name="description"
                value={description}
                onChange={event => setDescription(event.target.value)}
                label={t('WELLO.SIGN_UP.DESCRIPTION')}
                InputProps={{
                  type: 'description',
                  autoComplete: 'description',
                  readOnly: !!currentUser,
                  disabled: !!currentUser,
                }}
                margin="normal"
                variant="outlined"
                fullWidth
                multiline
                minRows={3}
                required
              />
            </Grid>
          </>
        )}
        <Button
          disabled={isFetching || isFetchingCreateUser || isUnvalidForm}
          fullWidth
          type="submit"
        >
          {t('VALIDATE')}
        </Button>
      </Grid>
    </form>
  );
};

export default SignUpForm;
