import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { CircularProgress } from '@material-ui/core';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import WelloAvatar from './WelloAvatar';
import { getFormattedPendingRole, getFormattedRole } from '../../Acl/Roles';

const useStyles = makeStyles(theme => ({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  names: {
    alignSelf: 'center',
    paddingLeft: theme.spacing(2),
  },
  namesText: {
    fontWeight: '700',
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

type Props = {
  members: Array,
  onSuggestionSelected: Function,
  allowedRoles: Array,
  registeredMembersIds: Array,
};

const AutocompleteHelper = ({
  members,
  onSuggestionSelected,
  allowedRoles,
  registeredMembersIds,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isFetching = useSelector(
    state => state.organisationMembership.isFetching.getOrganisationMembership,
  );
  const handleOnChange = (event, suggestion) => {
    onSuggestionSelected(suggestion);
  };

  return (
    <Autocomplete
      fullWidth
      id="member-select"
      options={members}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      getOptionLabel={suggestion =>
        `${suggestion.last_name} ${suggestion.first_name} ${suggestion.pseudo}`
      }
      renderOption={(suggestion, { inputValue }) => {
        const matches = match(`${suggestion.last_name} ${suggestion.first_name}`, inputValue);
        const parts = parse(`${suggestion.last_name} ${suggestion.first_name}`, matches);
        return (
          <Grid container className={classes.text}>
            <Grid item xs={12} sm={1}>
              <WelloAvatar
                avatarUrl={suggestion?.helper?.avatar_url || suggestion?.avatar_url}
                firstName={suggestion?.helper?.first_name || suggestion?.first_name}
                lastName={suggestion?.helper?.last_name || suggestion?.last_name}
                backgroundColor={
                  suggestion?.helper?.avatar_background_color || suggestion?.avatar_background_color
                }
                size={36}
              />
            </Grid>
            <Grid item xs={12} sm={11} className={classes.names}>
              <Typography variant="body1" component="p">
                {parts.map(part => (
                  <span
                    key={JSON.stringify(part)}
                    style={{ fontWeight: part.highlight ? 500 : 300 }}
                  >
                    {part.text}
                  </span>
                ))}
                {allowedRoles && !allowedRoles?.includes(suggestion.role) && (
                  <span style={{ fontWeight: 300 }}> (Non autorisé)</span>
                )}
                {registeredMembersIds && registeredMembersIds?.includes(suggestion.id) && (
                  <span style={{ fontWeight: 300 }}> (Déjà inscrit)</span>
                )}
              </Typography>
              <Typography variant="subtitle2" component="p">
                {suggestion.validated
                  ? getFormattedRole(suggestion.role, t)
                  : getFormattedPendingRole(suggestion.role, t)}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
      renderInput={params => <TextField {...params} label={t('SEARCH')} variant="outlined" />}
      noOptionsText={
        <Grid>
          {isFetching && <CircularProgress size={16} style={{ marginRight: 8 }} />}
          {isFetching ? t('APP.ACTIVITY.FETCHING_MEMBERS') : t('APP.ACTIVITY.NO_MEMBERS')}
        </Grid>
      }
      onChange={(event, suggestion) => handleOnChange(event, suggestion)}
      getOptionDisabled={option =>
        (allowedRoles && !allowedRoles.includes(option.role)) ||
        (registeredMembersIds && registeredMembersIds.includes(option.id))
      }
    />
  );
};

export default AutocompleteHelper;
