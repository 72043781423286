import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import values from 'lodash/values';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Routes from '../../Routing/Routes';
import ToasterActions from '../../Redux/ToasterRedux';
import AutoCompleteCity from '../../Components/Shared/AutoCompleteCity';
import AutoCompletePlaces from '../../Components/Shared/AutoCompletePlaces';
import { DEPARTMENT_CODES } from './UnaHelper';

type Props = {
  city: String,
  service: String,
  history: Object,
  classes: Object,
  className: Object,
  select: Boolean,
  address: String,
  locality: String,
  latitude: String,
  longitude: String,
  bbox: Array,
};

const styles = theme => ({
  heroForm: {
    marginTop: '1.5em',
  },
  submitButton: {
    backgroundColor: theme.current.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.current.primary.dark,
    },
  },
  formControl: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
  },
  cityError: {
    border: '1px solid #F44335',
  },
});

function SearchPanel({
  city: cityName,
  service: serviceSlug,
  className,
  classes,
  history,
  select,
  address: inFormattedAddress,
  locality,
  latitude,
  longitude,
  bbox,
}: Props) {
  const inAddress = {
    formattedAddress: inFormattedAddress,
    locality,
    latitude,
    longitude,
    bbox,
  };
  const [service, setService] = React.useState(serviceSlug || '');
  const [serviceError, setServiceError] = React.useState(null);
  const [city, setCity] = React.useState(cityName);
  const [cityError, setCityError] = React.useState(null);
  const [address, setAddress] = React.useState(inAddress);
  const buttonRef = React.useRef();

  const handleSubmit = () => {
    // const leagueId = 1;
    if (Routes.currentNamespace() === 'UNA') {
      if (_.isEmpty(city)) {
        setCityError(true);
      }
      if (!_.isEmpty(city)) {
        if (_.isEmpty(service)) {
          setService('aide-au-menage');
          history.push(`/cities/${city}/services/aide-au-menage`);
        }
        if (!_.isEmpty(service)) {
          history.push(`/cities/${city}/services/${service}`);
        }
      }
    }
    if (Routes.currentNamespace() === 'SLA') {
      if (address && address.formattedAddress && address.latitude && address.longitude) {
        let historyToPush = `/recherche/structures?city=${address.locality}&address=${address.formattedAddress}&lat=${address.latitude}&long=${address.longitude}`;
        if (address.bbox?.length > 0) {
          const urlBbox = address.bbox.reduce((acc, item) => {
            if (acc.length === 0) {
              return `${item}`;
            }
            return `${acc};${item}`;
          }, '');
          historyToPush += `&bbox=${urlBbox}`;
        }
        if (!_.isEmpty(historyToPush)) {
          history.push(historyToPush);
        }
      }
    }
  };

  return (
    <Grid container justifyContent="center" spacing={2} className={className || classes.heroForm}>
      {select && (
        <Grid item xs={12}>
          <Select
            align="left"
            fullWidth
            displayEmpty
            value={service}
            className={classes.formControl}
            onChange={e => {
              setService(e.target.value);
              setServiceError(null);
            }}
            input={<OutlinedInput labelWidth={0} name="activity" id="activity-simple" />}
            variant="outlined"
            error={serviceError !== null}
          >
            <MenuItem value="" disabled>
              Sélectionner le service
            </MenuItem>
            <MenuItem value="aide-autonomie">Aide à l&apos;autonomie</MenuItem>
            <MenuItem value="accompagnement-sortie">Accompagnement sortie</MenuItem>
            <MenuItem value="aide-au-menage">Aide au ménage</MenuItem>
          </Select>
        </Grid>
      )}
      <Grid item xs={12} align="left">
        {Routes.currentNamespace() === 'UNA' && (
          <AutoCompleteCity
            value={city}
            fullWidth
            classes={cityError !== null ? classes.cityError : {}}
            className={cityError !== null ? classes.cityError : {}}
            allowedZipCodes={values(DEPARTMENT_CODES)}
            onSuggestionSelected={value => {
              setCity(value);
              setCityError(null);
            }}
            error={cityError !== null}
          />
        )}
        {Routes.currentNamespace() === 'SLA' && (
          <AutoCompletePlaces
            onSelect={value => {
              setAddress({
                formattedAddress: value.formatted_address,
                latitude: value.coordinates?.lat,
                longitude: value.coordinates?.lng,
                locality: value.locality,
                postcode: value.postcode,
                bbox: value.bbox,
              });
              buttonRef.current.focus();
            }}
            onClear={() => {
              setAddress({
                formattedAddress: '',
                latitude: '',
                longitude: '',
                locality: '',
                postcode: '',
                bbox: [],
              });
            }}
            placeHolder="Votre ville"
            defaultValue={address.formattedAddress}
            id="searchBarddress"
            addressTypes="place"
            cssClass="mapbox-autocomplete-searchBar"
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.submitButton}
          onClick={handleSubmit}
          ref={buttonRef}
        >
          Rechercher
        </Button>
      </Grid>
    </Grid>
  );
}

const mapDispatchToProps = dispatch => ({
  showToaster: message => dispatch(ToasterActions.showToaster(message, 'error', 5000)),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(withRouter(SearchPanel)));
