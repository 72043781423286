import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TasksActions from '../../Redux/TasksRedux';
import PostForm from './Form';

type Props = {
  holdingSlug: String,
  organisationSlug: String,
  postableId: Number,
  postableType: String,
};

const TaskPostForm = ({ holdingSlug, organisationSlug, postableId, postableType }: Props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.currentUser);
  const creatingPost = useSelector(state => state.tasks.isFetching.createTaskPost);

  const createPost = post => {
    dispatch(TasksActions.createTaskPostRequest(holdingSlug, organisationSlug, postableId, post));
  };

  return (
    <PostForm
      currentUser={currentUser}
      postableType={postableType}
      createPost={createPost}
      creatingPost={creatingPost}
      sendOnEnter
    />
  );
};

export default TaskPostForm;
