import React from 'react';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer',
    ...theme.typography.h6,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  typo: {
    ...theme.typography.h6,
    fontFamily: '"Lato", "Helvetica", "Arial", sans-serif !important',
    fontWeight: '700 !important',
    fontSize: '0.9rem !important',
    lineHeight: '1.2 !important',
    letterSpacing: '0.0075em !important',
  },
}));

type Props = {
  previousLinks: Array,
  currentLink: Object,
};

const Breadcrumb = ({ previousLinks, currentLink }: Props) => {
  const classes = useStyles();

  return (
    <>
      <List
        itemScope
        itemType="https://schema.org/BreadcrumbList"
        style={{ display: 'flex', paddingLeft: 16, paddingBottom: 16, flexFlow: 'wrap' }}
        className={classes.breadcrumb}
      >
        {previousLinks.map((link, i) => (
          <>
            <ListItem
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              key={i}
              style={{ width: 'auto' }}
            >
              <Link
                itemProp="item"
                href={link.link.replaceAll(' ', '%20')}
                className={classes.link}
                underline="hover"
              >
                <Typography component="h2" className={classes.typo} itemProp="name" color="primary">
                  {link.name}
                </Typography>
              </Link>
              <meta itemProp="position" content={i.toString()} />
            </ListItem>
            <div
              style={{
                width: 'auto',
                marginLeft: -8,
                marginRight: -8,
                alignSelf: 'center',
                fontWeight: 'bold',
              }}
              key={`${i}bis`}
            >
              {'>'}
            </div>
          </>
        ))}

        <ListItem
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
          key={previousLinks.length}
          style={{ width: 'auto' }}
        >
          <Link itemProp="item" href={null} underline="none" disabled>
            <Typography component="h2" className={classes.typo} itemProp="name">
              {currentLink.name}
            </Typography>
          </Link>
          <meta itemProp="position" content={previousLinks.length.toString()} />
        </ListItem>
      </List>
      {/* <Breadcrumbs
        separator="›"
        aria-label="breadcrumb"
        className={classes.breadcrumb}
        itemScope
        itemType="https://schema.org/BreadcrumbList"
      >
        {previous.map(link => (
          <Link
            underline="hover"
            key="1"
            onClick={() => history.push(link.link)}
            component="h4"
            className={classes.link}
            itemScope
            itemType="https://schema.org/ListItem"
            itemProp="itemListElement"
          >
            {link.name}
          </Link>
        ))}
        <Typography variant="h6" component="h5" key="3" className={classes.typo}>
          {current.name}
        </Typography>
      </Breadcrumbs> */}
    </>
  );
};

export default Breadcrumb;
