import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import StepConnector from '@material-ui/core/StepConnector';

import { findStep, getItemsByType, getItemUrl } from './PageHelper';
import Routes from '../../Routing/Routes';

const getItemsParams = type => getItemsByType(type)[Routes.currentNamespace()];

const getCurrentItemParam = (item, type) => findStep(Routes.currentNamespace(), item, type);

const getSteps = type => {
  const steps = [];
  _.forOwn(getItemsParams(type), value => {
    steps.push(value);
  });
  return steps;
};

type PropsIcon = {
  active: boolean,
  completed: boolean,
  step: Object,
};

/*
 * STEPPER ICONS
 */

const useColorlibStepIconStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    zIndex: 1,
    color: '#ccc',
    width: 35,
    height: 35,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    '&>*': {
      width: '35px',
      height: '35px',
    },
  },
  offer: {
    backgroundColor: '#ca0202',
  },
  active: {
    backgroundColor: 'white',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    color: theme.current.primary.main,
  },
  completed: {
    backgroundColor: '#1f88e5',
  },
}));

function ColorlibStepIcon({ active, completed, step }: PropsIcon) {
  const classes = useColorlibStepIconStyles();

  if (!step) {
    return null;
  }
  return (
    <div
      className={cx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {step.stepIcon}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

/*
 * STEPPER
 */
const ColorlibConnector = withStyles(theme => ({
  alternativeLabel: {
    top: 17,
  },
  active: {
    '& $line': {
      backgroundColor: theme.current.primary.main,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: theme.current.primary.main,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
}))(StepConnector);

type PropsStepper = {
  item: String,
  type: String,
  history: Object,
};

function ItemSteppers({ item, type, history }: PropsStepper) {
  // State
  const [activeStep, setActiveStep] = React.useState(getCurrentItemParam(item, type).step);
  useEffect(() => {
    // refresh current step on component update
    if (activeStep >= 0 && activeStep !== getCurrentItemParam(item, type).step) {
      setActiveStep(getCurrentItemParam(item, type).step);
    }
  }, [activeStep, item, type]);

  const handleStep = step => () => {
    const newItem = _.get(getItemsParams(type), `[${step}].pages[0]`);
    if (newItem) {
      history.push(getItemUrl(newItem, type));
    } else {
      console.log(`step ${step} not found...`);
    }
  };

  const classes = useStyles();
  const steps = getSteps(type);
  return (
    <div className={classes.root}>
      <Stepper
        style={{ backgroundColor: 'transparent' }}
        nonLinear
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((step, index) => (
          <Step key={step.stepLabel}>
            <StepButton onClick={handleStep(index)}>
              <StepLabel StepIconComponent={ColorlibStepIcon} StepIconProps={{ step }}>
                {step.stepLabel}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

export default withRouter(ItemSteppers);
