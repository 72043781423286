import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import DeleteIcon from '@material-ui/icons/Delete';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Images from '../../../Themes/Images';
import { getExpertiseStyle } from '../../../Themes/Expertises';
import TasksActions from '../../../Redux/TasksRedux';
import { getRole } from '../../../Acl/Controls';
import { getOrgaKey } from '../../../Services/DataHelper';
import { CheckRoleRule } from '../../../Acl/Rules';
import Routes from '../../../Routing/Routes';
import ReccurrenceActionDialog from '../Schedule/ReccurrenceActionDialog';
import ListTaskInfo from '../Schedule/ListTaskInfo';
import { getCdnUrl } from '../../../Services/ImageResizer';

const useStyles = makeStyles(theme => ({
  media: {
    objectFit: 'cover',
    borderRadius: '0px',
  },
  mediaTextArea: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '110px',
    background: 'linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 50%)',
  },
  avatarContainer: {
    margin: '-45px 0 0 10px',
    marginBottom: '-5px',
  },
  avatar: {
    backgroundColor: 'white',
    height: 45,
    width: 45,
    margin: 8,
    boxShadow: `0 0 0 2px white`,
    '&>*': {
      height: 45,
      width: 45,
    },
  },
  description: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    maxHeight: '5.8em',
    whiteSpace: 'normal',
  },
  itemsContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  excerptContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
    height: '70px',
  },
  excerptTypo: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',
    maxHeight: '5.8em',
    whiteSpace: 'normal',
  },
  titleContent: {
    alignItems: 'start',
    display: 'inline-flex',
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    height: '65px',
  },
  orgaTypo: {
    fontSize: 12,
    color: 'grey',
    fontWeight: 400,
  },
  iconButton: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: '-18px',
    marginRight: '-18px',
  },
}));

type Props = {
  task: Object,
  showIsOver: Boolean,
  isReferenced: Boolean,
  connectedMode: Boolean,
  onClose: Function,
  hideTaskInfo: Boolean,
  targetBlank: Boolean,
};

const CardTask = ({
  task,
  showIsOver,
  isReferenced,
  connectedMode,
  onClose,
  hideTaskInfo,
  targetBlank,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const match = useRouteMatch();
  const {
    holding_slug: holdingSlug,
    organisation_slug: organisationSlug,
    family_slug: familySlug,
  } = match.params;

  const currentUser = useSelector(state => state.auth.currentUser);
  const [anchorEl, setAnchorEl] = React.useState();
  const fallbackCoverUrl = getExpertiseStyle(task.expertise)?.cover || Images.badge.ERROR;
  const eventStartDate = new Date(task.start_date);
  const eventLastDate = task.until_date ? new Date(task.until_date) : task?.end_date;
  const dateValid = !isNaN(eventStartDate.getTime()) && !isNaN(eventLastDate.getTime());
  const date = Date.now();
  const taskOver = showIsOver && eventStartDate <= date;
  const registrants = task?.twig_for_date?.helpers || [];
  const registrantsCount = task?.twig_for_date?.helpers_count || 0;
  const taskFull = task?.max_participants > 0 && registrantsCount === task?.max_participants;
  const userRegistered = currentUser && !!registrants.find(h => h.id === currentUser.id);
  const validated = registrants?.find(h => h.id === currentUser.id)?.validated_on_twig;
  const notInterested =
    currentUser &&
    !!task?.twig_for_date?.helpers_not_interested?.find(h => h.id === currentUser.id);
  const [action, setAction] = React.useState(null);

  const checkCanEdit =
    connectedMode &&
    CheckRoleRule(
      getRole(currentUser, holdingSlug, organisationSlug, familySlug),
      'tasks:editdelete',
      {
        userId: currentUser?.id,
        taskOwnerId: task.helper_id,
        taskOrgaKey: getOrgaKey(task?.holding_slug, task?.organisation_slug),
        pageOrgaKey: getOrgaKey(familySlug ? null : holdingSlug, familySlug || organisationSlug),
      },
    );

  const taskLink = React.useMemo(() => {
    if (!task) return '';

    if (familySlug) {
      return `/families/${familySlug}/schedule/${task.slug}`;
    }

    return `/${task.holding_slug}/${task.organisation_slug}/activites/${task.slug}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdingSlug, organisationSlug, familySlug, task]);

  const handleClose = () => {
    setAnchorEl(null);
    setAction(null);
    if (onClose) onClose();
  };
  const handleSkipOccurrence = () => {
    dispatch(
      TasksActions.skipOccurenceRequest(
        task.holding_slug,
        organisationSlug,
        familySlug,
        task.slug,
        eventStartDate,
      ),
    );
    handleClose();
  };

  const handleDestroySerie = () => {
    dispatch(
      TasksActions.destroyTaskRequest(task.holding_slug, organisationSlug, familySlug, task.slug),
    );
    handleClose();
  };

  const handleRecurrenceDialogConfirm = actionChoice => {
    if (action === 'delete') {
      if (actionChoice === 'occurrence') handleSkipOccurrence();
      else if (actionChoice === 'all') handleDestroySerie();
    }
  };

  const renderCardContent = () => (
    <Grid style={{ height: hideTaskInfo ? 200 : 350 }}>
      <div className={classes.avatarContainer}>
        <Avatar className={classes.avatar} alt={task.expertise?.label}>
          {getExpertiseStyle(task.expertise).icon}
        </Avatar>
      </div>
      <CardHeader
        disableTypography
        classes={{ root: classes.titleContent, action: classes.action }}
        title={
          <Typography variant="h4" component="h3" className={classes.description}>
            {task.description}
          </Typography>
        }
        subheader={
          <>
            {taskOver && (
              <Typography variant="subtitle2" component="div" className={classes.orgaTypo}>
                {t('APP.ACTIVITY.DATE.OVER')}
              </Typography>
            )}
            {!taskOver && userRegistered && validated && (
              <Typography variant="subtitle2" component="div" className={classes.orgaTypo}>
                {t('APP.ACTIVITY.PARTICIPANTS.REGISTERED')}
              </Typography>
            )}
            {!taskOver && userRegistered && !validated && (
              <Typography variant="subtitle2" component="div" className={classes.orgaTypo}>
                {t('APP.ACTIVITY.PARTICIPANTS.REGISTERED.PENDING')}
              </Typography>
            )}
            {!taskOver && notInterested && (
              <Typography variant="subtitle2" component="div" className={classes.orgaTypo}>
                {t('APP.ACTIVITY.PARTICIPANTS.NOT_INTERESTED')}
              </Typography>
            )}
            {!taskOver && !userRegistered && taskFull && (
              <Typography variant="subtitle2" component="div" className={classes.orgaTypo}>
                {t('APP.ACTIVITY.PARTICIPANTS.FULL')}
              </Typography>
            )}
          </>
        }
        action={
          checkCanEdit && (
            <>
              <IconButton
                onClick={event => setAnchorEl(event.target)}
                aria-label="More"
                aria-owns={anchorEl ? 'actions-menu' : undefined}
                aria-haspopup="true"
                className={classes.iconButton}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu id="actions-menu" open={!!anchorEl} anchorEl={anchorEl} onClose={handleClose}>
                <MenuItem
                  component={Link}
                  to={`${taskLink}/edit`}
                  disabled={eventStartDate < Date.now()}
                >
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  <ListItemText>{t('EDIT')}</ListItemText>
                </MenuItem>
                <MenuItem component={Link} to={`${taskLink}/copy`}>
                  <ListItemIcon>
                    <ContentCopyIcon />
                  </ListItemIcon>
                  <ListItemText>{t('DUPLICATE')}</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={
                    task?.recurrence_type !== 'NONE'
                      ? () => setAction('delete')
                      : handleSkipOccurrence
                  }
                  disabled={eventStartDate < Date.now()}
                >
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText>{t('DELETE')}</ListItemText>
                </MenuItem>
              </Menu>
            </>
          )
        }
      />
      <CardContent className={classes.excerptContent}>
        <Typography variant="body2" className={classes.excerptTypo}>
          {task.excerpt}
        </Typography>
      </CardContent>
      {!hideTaskInfo && (
        <CardContent className={classes.itemsContent}>
          <ListTaskInfo
            task={task}
            registrantsCount={registrantsCount}
            eventStartDate={eventStartDate}
            eventLastDate={eventLastDate}
          />
        </CardContent>
      )}
    </Grid>
  );

  return (
    <>
      {isReferenced && task.holding_slug && (
        <div itemScope itemProp="event" itemType="http://schema.org/Event">
          <meta itemProp="name" content={task.description} />
          <meta
            itemProp="description"
            content={
              task.excerpt && Boolean(task.excerpt?.trim()) ? task.excerpt : task.description
            }
          />
          <meta itemProp="image" content={task?.cover_url} />
          <meta itemProp="url" content={`${taskLink}/date/${task.start_date}`} />
          <div itemProp="location" itemScope itemType="http://schema.org/Place">
            <meta itemProp="name" content={task.formatted_address} />
            <meta itemProp="address" content={task.formatted_address} />
            <div itemProp="geo" itemScope itemType="http://schema.org/GeoCoordinates">
              <meta itemProp="latitude" content={task.coordinates?.latitude} />
              <meta itemProp="longitude" content={task.coordinates?.longitude} />
            </div>
          </div>
          <meta itemProp="startDate" content={dateValid ? eventStartDate.toISOString() : null} />
          <meta itemProp="endDate" content={dateValid ? eventLastDate.toISOString() : null} />
          <div itemProp="offers" itemScope itemType="http://schema.org/Offer">
            <meta itemProp="price" content="0" />
            <meta itemProp="priceCurrency" content="EUR" />
            <meta itemProp="availability" content="http://schema.org/LimitedAvailability" />
            <meta itemProp="url" content={`${taskLink}/date/${task.start_date}`} />
            <meta itemProp="validFrom" content={dateValid ? eventStartDate.toISOString() : null} />
          </div>
          <div itemProp="performer" itemScope itemType="http://schema.org/Organization">
            <meta itemProp="name" content={task.organisation_name} />
          </div>
          <div itemProp="organizer" itemScope itemType="http://schema.org/Organization">
            <meta itemProp="name" content={task.organisation_name} />
            <meta
              itemProp="url"
              content={`${t(`${Routes.currentNamespace()}.SEO.HOMEPAGE.URL`)}/${
                task.holding_slug
              }/${task.organisation_slug}`}
            />
          </div>
          <meta itemProp="eventStatus" content="https://schema.org/EventScheduled" />
          <meta
            itemProp="eventAttendanceMode"
            content="https://schema.org/MixedEventAttendanceMode"
          />
          <meta itemProp="typicalAgeRange" content="60+" />
        </div>
      )}
      <Card>
        <CardActionArea
          component={Link}
          to={`${taskLink}/date/${task.start_date}`}
          target={targetBlank ? '_blank' : '_self'}
        >
          <CardMedia
            classes={{
              root: classes.mediaTextArea,
              media: classes.media,
            }}
            component="img"
            src={getCdnUrl(task.cover_url, 280, 110) || fallbackCoverUrl}
            alt={task.excerpt}
            title={task.excerpt}
          />
          {!checkCanEdit && renderCardContent()}
        </CardActionArea>
        {checkCanEdit && renderCardContent()}
      </Card>
      <ReccurrenceActionDialog
        open={action}
        title={action === 'delete' ? t('APP.ACTIVITY.MENU.WHAT_TO_DELETE') : ''}
        onClose={handleClose}
        onConfirm={handleRecurrenceDialogConfirm}
      />
    </>
  );
};

export default CardTask;
