import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import ButtonCallMeBackInModal from '../../../Components/Shared/ButtonCallMeBackInModal';
import SearchPanel from '../../../PagesLeagues/Una/SearchPanel';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    marginBottom: 15,
  },
  button: {
    backgroundColor: theme.current.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.current.primary.dark,
    },
  },
}));

type Props = {
  title: String,
  subTitle: String,
  organisationCTA: Object,
  buttonLabel: String,
  descriptionPlaceHolder: String,
  searchPanel: Boolean,
  select: Boolean,
  withPhone: Boolean,
  withEmail: Boolean,
};

function SectionCta({
  title,
  subTitle,
  organisationCTA,
  buttonLabel,
  descriptionPlaceHolder,
  searchPanel,
  select,
  withPhone,
  withEmail,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.root}>
      <Typography
        align="left"
        variant="h4"
        component="h2"
        gutterBottom
        dangerouslySetInnerHTML={{
          __html: t(title),
        }}
      />
      <Divider light style={{ margin: '10px', background: 'none' }} />
      <Typography
        align="left"
        gutterBottom
        dangerouslySetInnerHTML={{
          __html: t(subTitle),
        }}
      />
      <Divider light style={{ margin: '20px', background: 'none' }} />
      {descriptionPlaceHolder && (
        <ButtonCallMeBackInModal
          withContainedButton
          organisation={organisationCTA}
          withPostCode
          withEmail={withEmail}
          withPhone={withPhone}
          buttonLabel={buttonLabel}
          descriptionPlaceHolder={descriptionPlaceHolder}
          gtmOrigin="article"
        />
      )}
      {searchPanel && <SearchPanel className={classes.searchBar} select={select} />}
    </Paper>
  );
}

export default SectionCta;
