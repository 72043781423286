import React, { Suspense } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import TextField from '@material-ui/core/TextField';
import Hidden from '@material-ui/core/Hidden';
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import Popper from '@material-ui/core/Popper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ImageIcon from '@material-ui/icons/Image';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Divider from '@material-ui/core/Divider';
import ChipInput from 'material-ui-chip-input';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Typography } from '@material-ui/core';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import WelloAvatar from '../Shared/WelloAvatar';
import { handleAddChip, handleRemoveChip } from '../../Services/FormHelpers';
import { isClientSide } from '../../Config/ServerConfig';
import {
  CARENOTEBOOK_TYPE,
  getRestrictions,
  getDefaultRestrictions,
  allowCarenotebookHealthcarePost,
  getPostType,
} from '../../Services/FamilyHelper';
import RestrictionsSelection from '../CareNotebook/RestrictionsSelection';
import ExpertisesSelection from '../CareNotebook/ExpertisesSelection';

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginImageExifOrientation);
registerPlugin(FilePondPluginFilePoster);
registerPlugin(FilePondPluginImageTransform);
registerPlugin(FilePondPluginImageResize);
registerPlugin(FilePondPluginFileValidateType);

const useStyles = makeStyles(theme => ({
  fabContainer: { justifyContent: 'center', paddingBottom: theme.spacing(2) },
  fab: {
    color: theme.current.primary.main,
    backgroundColor: 'white',
    '&.MuiFab-root': { boxShadow: theme.shadows[2] },
  },
  postContainer: { position: 'relative', flexGrow: 1 },
  avatarComment: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(1.5),
    fontSize: '1rem',
  },
  avatarMessage: {
    width: 48,
    height: 48,
    marginRight: theme.spacing(1),
  },
  messageContainer: {
    flexWrap: 'nowrap',
    position: 'relative',
    alignItems: 'center',
  },
  top: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: 4,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  topComment: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: 4,
    padding: theme.spacing(0.5),
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputLabel-formControl': {
      top: -5,
    },
    '& .MuiOutlinedInput-multiline': {
      padding: theme.spacing(1),
    },
  },
  sendBtn: {
    marginLeft: theme.spacing(1),
  },
  wrapContainer: {
    flexWrap: 'nowrap',
    [theme.breakpoints.only('xs')]: {
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    },
  },
  wrap: {
    flexWrap: 'nowrap',
  },
  input: {
    display: 'none',
  },
  iconButton: {
    color: 'black',
    margin: theme.spacing(1, 1, 1, 0),
  },
  chipRoot: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: '4px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  chip: {
    padding: 0,
    margin: 0,
    marginTop: theme.spacing(0.7),
    marginBottom: theme.spacing(0.7),
    marginRight: theme.spacing(0.7),
    height: 'inherit',
  },
  chipContainer: {
    maxWidth: 300,
    minHeight: 'inherit',
    margin: 0,
  },
  filepond: {
    paddingBottom: theme.spacing(1),
  },
  buttons: {
    paddingTop: theme.spacing(1),
    textAlign: 'center',
  },
  disabled: {
    opacity: '50%',
    backgroundColor: 'white',
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  popover: {
    '& .MuiPopover-paper': { height: 250 },
  },
  switch: {
    marginLeft: 0,
    // avoid css bug at first loading
    '& .MuiButtonBase-root': {
      position: 'absolute',
      padding: 9,
      color: 'white',
    },
  },
}));

type Props = {
  post: Object,
  currentUser: Object,
  postableId: Number,
  postableType: String,
  createPost: Function,
  creatingPost: Boolean,
  updatePost: Function,
  onClose: Function,
  dialogConfirm: Object,
  isMessage: Boolean,
  iconBar: Boolean,
  sendOnEnter: Boolean,
  isDialog: Boolean,
  showPostType: Boolean,
  withExpertises: Boolean,
  family: Object,
};

const PostForm = ({
  post,
  currentUser,
  postableId,
  postableType,
  createPost,
  creatingPost,
  updatePost,
  onClose,
  dialogConfirm,
  isMessage,
  iconBar,
  sendOnEnter,
  isDialog,
  showPostType,
  withExpertises,
  family,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [raw, setRaw] = React.useState(post?.raw || '');
  const [open, setOpen] = React.useState(false);
  const [waitingTime, setWaitingTime] = React.useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = React.useState(null);
  const [images, setImages] = React.useState([]);
  const [imagesCompressed, setImagesCompressed] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const allowHealthcarePost = showPostType && allowCarenotebookHealthcarePost(family, currentUser);
  const [postType, setPostType] = React.useState(
    allowHealthcarePost ? null : getPostType(post?.type) || 'dailylife',
  );
  const [isImportant, setIsImportant] = React.useState(post?.is_important || false);
  const restrictions = postType && getRestrictions(postType, family, t);
  const defaultSelectedRestrictions = getDefaultRestrictions(postType, restrictions);
  const [selectedRestrictions, setSelectedRestrictions] = React.useState(
    defaultSelectedRestrictions || {},
  );
  const [openRestrictionsSelection, setOpenRestrictionsSelection] = React.useState(false);
  const [openExpertisesDialog, setOpenExpertisesDialog] = React.useState(null);
  const expertises = CARENOTEBOOK_TYPE[postType]?.keyWords;
  const expertisesLabel = expertises?.map(exp => exp.label);
  const keyWordsExpertises = post?.related_words?.filter(word => !expertisesLabel?.includes(word));
  const selectedWordsExpertises = post?.related_words?.filter(word =>
    expertisesLabel?.includes(word),
  );
  const [keyWords, setKeyWords] = React.useState(keyWordsExpertises || []);
  const [selectedWords, setSelectedWords] = React.useState(selectedWordsExpertises || []);
  const [excludedWords, setExcludedWords] = React.useState(post?.excluded_words || []);
  const isEdition = Boolean(post?.id);
  const timer = React.useRef();
  // eslint-disable-next-line camelcase
  const isTransmission = postableType === 'Transmission';

  React.useEffect(() => {
    setSelectedRestrictions(defaultSelectedRestrictions || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postType]);

  React.useEffect(() => {
    if (!isEdition) {
      setRaw('');
      setKeyWords([]);
      setSelectedWords([]);
      setExcludedWords([]);
      setImages([]);
      setFiles([]);
      setIsImportant(false);
    }
  }, [postType, isEdition]);

  React.useEffect(() => {
    const attachments = post?.attachments?.filter(a => !a.preview_url);
    const imgs = post?.attachments?.filter(a => a.preview_url);
    if (post?.cover_image_url) imgs.push({ preview_url: post?.cover_image_url });
    if (imgs?.length > 0) {
      setImages(
        imgs.map(im => ({
          source: im.preview_url,
          options: {
            type: 'local',
            file: { id: im.id, name: im.preview_url, size: 0 },
            metadata: { poster: im.preview_url },
          },
        })),
      );
    }
    if (attachments) {
      setFiles(
        attachments.map(attachment => ({
          source: attachment.download_url,
          options: {
            type: 'local',
            file: {
              id: attachment.id,
              name: attachment.filename,
              size: 0,
              url: attachment.download_url,
            },
            metadata: { poster: attachment },
          },
        })),
      );
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const time = timer.current;
    return () => {
      clearTimeout(time);
    };
    // eslint-disable-next-line
  }, []);

  const handleClickPostType = carenotebookPostType => {
    setPostType(carenotebookPostType);
    setAnchorEl(null);
  };

  const handleEmojiClick = (event, emojiObject) => {
    setRaw(raw + emojiObject.emoji);
  };

  const handleClickSubmit = () => {
    if (dialogConfirm) {
      setOpen(true);
    } else if (restrictions) {
      setOpenRestrictionsSelection(true);
    } else {
      handleSubmit();
    }
  };

  const serializePost = () => {
    const p = {
      raw,
      postable_type: postableType,
      related_words: keyWords.concat(selectedWords),
      excluded_words: excludedWords,
      image: [],
      holding_slug: post?.organisation?.holding_slug,
      organisation_slug: post?.organisation?.slug,
      is_important: isImportant,
      type: postableType === 'Organisation' ? CARENOTEBOOK_TYPE[postType]?.postableType : null,
      restrictions: selectedRestrictions,
    };

    if (post) return { ...p, id: post.id };

    return p;
  };

  const handleSubmit = event => {
    event?.preventDefault();

    if (
      (raw.trim() === '' &&
        keyWords?.length === 0 &&
        selectedWords?.length === 0 &&
        excludedWords?.length === 0 &&
        images?.length === 0 &&
        files?.length === 0) ||
      creatingPost ||
      waitingTime
    )
      return;

    const p = serializePost();

    const im = images.map(i => imagesCompressed.find(ic => ic.name === i.name) || i);
    const allFiles = files.concat(im || []);

    if (createPost) createPost(p, allFiles, postableId);
    if (updatePost) updatePost(p, allFiles, postableId);

    setRaw('');
    setImages([]);
    setFiles([]);
    setKeyWords([]);
    setSelectedWords([]);
    setExcludedWords([]);
    setWaitingTime(true);
    timer.current = setTimeout(() => {
      setWaitingTime(false);
    }, 500);
    setSelectedRestrictions(defaultSelectedRestrictions || {});
    setIsImportant(false);
    setPostType(allowHealthcarePost ? null : getPostType(post?.type) || 'dailylife');

    if (onClose) onClose();
  };

  const renderDialog = () => (
    <>
      <DialogTitle>{dialogConfirm.title}</DialogTitle>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button disabled={creatingPost || waitingTime} onClick={handleSubmit}>
          {(creatingPost || waitingTime) && <CircularProgress />}
          {!(creatingPost || waitingTime) && <span>{dialogConfirm.noButtonLabel}</span>}
        </Button>
        <Button
          disabled={creatingPost}
          onClick={event => dialogConfirm.onConfirm(event, serializePost())}
        >
          {dialogConfirm.yesButtonLabel}
        </Button>
      </DialogActions>
    </>
  );

  const renderEmojiPicker = () => {
    if (!isClientSide()) return <></>;
    const EmojiPicker = React.lazy(() => import('emoji-picker-react'));

    return (
      <Suspense fallback={t('LOADING')}>
        <EmojiPicker
          onEmojiClick={handleEmojiClick}
          disableAutoFocus
          groupNames={{
            smileys_people: t('APP.POST.EMOJIS.PEOPLE'),
            animals_nature: t('APP.POST.EMOJIS.ANIMALS'),
            food_drink: t('APP.POST.EMOJIS.FOOD'),
            travel_places: t('APP.POST.EMOJIS.TRAVEL'),
            activities: t('APP.POST.EMOJIS.ACTIVITIES'),
            objects: t('APP.POST.EMOJIS.OBJECTS'),
            symbols: t('APP.POST.EMOJIS.SYMBOLS'),
            flags: t('APP.POST.EMOJIS.FLAGS'),
            recently_used: t('APP.POST.EMOJIS.RECENT'),
          }}
        />
      </Suspense>
    );
  };

  return (
    <>
      {allowHealthcarePost && (
        <Grid container className={classes.fabContainer}>
          <Fab
            variant="extended"
            aria-label="add"
            onClick={event => setAnchorEl(event.target)}
            className={classes.fab}
          >
            <AddIcon style={{ marginRight: 4 }} />
            {postType ? t(`APP.POST.NEW.${postType}`) : t('APP.POST.NEW_POST')}
            <ArrowDropDownIcon style={{ marginLeft: 16 }} />
          </Fab>
          <Menu
            id="actions-menu"
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={() => handleClickPostType('dailylife')}>
              <ListItemText>{t('APP.POST.NEW_POST.DAILYLIFE')}</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleClickPostType('healthcare')}>
              <ListItemText>{t('APP.POST.NEW_POST.HEALTHCARE')}</ListItemText>
            </MenuItem>
          </Menu>
        </Grid>
      )}

      <Grid className={classes.postContainer}>
        <Grid
          container
          className={classes.messageContainer}
          style={isTransmission ? {} : { paddingBottom: 16 }}
        >
          {!isTransmission && (
            <WelloAvatar
              avatarUrl={
                isEdition || post?.helper ? post?.helper?.avatar_url : currentUser?.avatar_url
              }
              firstName={
                isEdition || post?.helper ? post?.helper?.first_name : currentUser?.first_name
              }
              lastName={
                isEdition || post?.helper ? post?.helper?.last_name : currentUser?.last_name
              }
              backgroundColor={
                isEdition || post?.helper
                  ? post?.helper?.avatar_background_color
                  : currentUser?.avatar_background_color
              }
              avatarClass={isMessage ? classes.avatarMessage : classes.avatarComment}
              size={36}
            />
          )}
          <ClickAwayListener onClickAway={() => setShowEmojiPicker(null)}>
            <TextField
              className={isMessage ? classes.top : classes.topComment}
              id="raw"
              name="raw"
              label={t(isMessage ? 'APP.POST.FIELD.MESSAGE' : 'APP.POST.FIELD.RAW')}
              onChange={event => setRaw(event.target.value)}
              multiline
              fullWidth
              variant="outlined"
              value={raw}
              InputProps={{
                endAdornment: (
                  <Hidden only={['xs']}>
                    <InputAdornment position="end" alignself="center">
                      <IconButton
                        onClick={event => setShowEmojiPicker(event.currentTarget)}
                        size="small"
                      >
                        <SentimentSatisfiedOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  </Hidden>
                ),
              }}
              onKeyPress={e => {
                if (sendOnEnter && e.key === 'Enter' && !e.shiftKey) {
                  if (dialogConfirm) setOpen(true);
                  else handleSubmit(e);
                }
              }}
            />
          </ClickAwayListener>
          {isDialog ? (
            <Popover
              open={Boolean(showEmojiPicker)}
              anchorEl={showEmojiPicker}
              onClose={() => setShowEmojiPicker(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              {renderEmojiPicker()}
            </Popover>
          ) : (
            <Popper open={Boolean(showEmojiPicker)} anchorEl={showEmojiPicker}>
              {renderEmojiPicker()}
            </Popper>
          )}
          {(createPost || updatePost) && !isMessage && (
            <IconButton
              size={!isMessage ? 'small' : 'medium'}
              color="primary"
              disabled={creatingPost || waitingTime || !raw.trim()}
              onClick={handleClickSubmit}
              className={classes.sendBtn}
            >
              {creatingPost || waitingTime ? <CircularProgress /> : <SendIcon />}
            </IconButton>
          )}
        </Grid>

        {iconBar && (
          <>
            <Divider light />
            <Grid
              container
              justifyContent="space-between"
              className={classes.wrapContainer}
              alignItems="center"
            >
              <Grid container className={!withExpertises ? classes.wrap : null}>
                <input
                  accept="image/*"
                  className={classes.input}
                  id={createPost ? 'icon-button-image' : 'icon-button-image-edit'}
                  type="file"
                  onChange={event => {
                    if (event.target.files?.length > 0) {
                      const newImages = images.slice();
                      // eslint-disable-next-line no-plusplus
                      for (let i = 0; i < event.target.files.length; i++) {
                        newImages.push(event.target.files[i]);
                      }
                      setImages(newImages);
                      event.target.value = null;
                    }
                  }}
                  multiple
                />
                {/* eslint-disable-next-line */}
                <label htmlFor={createPost ? 'icon-button-image' : 'icon-button-image-edit'}>
                  <IconButton
                    size="small"
                    aria-label="upload picture"
                    component="span"
                    className={classes.iconButton}
                  >
                    <ImageIcon />
                  </IconButton>
                </label>
                <input
                  accept="application/*"
                  className={classes.input}
                  id={createPost ? 'icon-button-file' : 'icon-button-file-edit'}
                  type="file"
                  onChange={event => {
                    const f = files.slice();
                    f.push(event.target.files[0]);
                    setFiles(f);
                    event.target.value = null;
                  }}
                  multiple
                />
                {/* eslint-disable-next-line */}
                <label htmlFor={createPost ? 'icon-button-file' : 'icon-button-file-edit'}>
                  <IconButton
                    size="small"
                    aria-label="upload picture"
                    component="span"
                    className={classes.iconButton}
                  >
                    <AttachFileIcon />
                  </IconButton>
                </label>
                {withExpertises ? (
                  <>
                    <IconButton
                      size="small"
                      aria-label="add expertises"
                      className={classes.iconButton}
                      onClick={event => setOpenExpertisesDialog(event.currentTarget)}
                    >
                      <CheckCircleIcon />
                    </IconButton>
                    <Popover
                      open={Boolean(isDialog && openExpertisesDialog)}
                      anchorEl={openExpertisesDialog}
                      onClose={() => setOpenExpertisesDialog(null)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      className={classes.popover}
                    >
                      <ExpertisesSelection
                        expertises={expertises}
                        keyWords={keyWords}
                        selectedWords={selectedWords}
                        excludedWords={excludedWords}
                        setKeyWords={setKeyWords}
                        setSelectedWords={setSelectedWords}
                        setExcludedWords={setExcludedWords}
                        onClose={() => setOpenExpertisesDialog(null)}
                      />
                    </Popover>
                    <Divider light orientation="vertical" flexItem className={classes.iconButton} />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isImportant}
                          onClick={() => setIsImportant(!isImportant)}
                        />
                      }
                      label={<Typography variant="caption">{t('APP.POST.IMPORTANT')}</Typography>}
                      className={classes.switch}
                    />
                  </>
                ) : (
                  <ChipInput
                    fullWidth
                    autoFocus
                    disableUnderline
                    value={keyWords}
                    onDelete={chip => setKeyWords(handleRemoveChip(chip, keyWords))}
                    onAdd={chip => setKeyWords(handleAddChip(chip, keyWords))}
                    placeholder={t('APP.POST.KEY_WORDS')}
                    classes={{
                      root: classes.chipRoot,
                      chip: classes.chip,
                      chipContainer: classes.chipContainer,
                    }}
                  />
                )}
              </Grid>
              {isMessage && (
                <Grid alignself="center">
                  <Button
                    color="primary"
                    onClick={handleClickSubmit}
                    endIcon={<SendIcon />}
                    disabled={creatingPost || waitingTime || !raw.trim()}
                  >
                    {t('SEND')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        )}
        <Grid className={classes.filepond}>
          {images && images.length > 0 && (
            <FilePond
              files={images}
              allowMultiple
              maxFiles={6}
              instantUpload={false}
              credits={('', '')}
              acceptedFileTypes={['image/*']}
              labelIdle={t('APP.USER.IMAGE_FIELD.PLACEHOLDER')}
              onupdatefiles={fileItems => {
                setImages(fileItems.map(fileItem => fileItem.file));
              }}
              imageResizeTargetWidth="1200"
              allowImageResize
              imageResizeMode="contain"
              onpreparefile={(file, output) => {
                const newIm = imagesCompressed.slice();
                newIm.push(output);
                setImagesCompressed(newIm);
              }}
              imageResizeUpscale={false}
            />
          )}
          {files && files.length > 0 && (
            <FilePond
              files={files}
              allowMultiple
              maxFiles={5}
              credits={('', '')}
              instantUpload={false}
              acceptedFileTypes={['application/*']}
              labelIdle={t('APP.USER.ATTACHMENT_FIELD.PLACEHOLDER')}
              onupdatefiles={fileItems => {
                setFiles(fileItems.map(fileItem => fileItem.file));
              }}
            />
          )}
        </Grid>
        {allowHealthcarePost && !postType && <Grid className={classes.disabled} />}
      </Grid>

      <Dialog open={open} onClose={() => setOpen(false)}>
        {dialogConfirm && renderDialog()}
      </Dialog>
      <Dialog open={openRestrictionsSelection} onClose={() => setOpenRestrictionsSelection(false)}>
        <RestrictionsSelection
          restrictions={restrictions}
          selectedRestrictions={selectedRestrictions}
          setSelectedRestrictions={setSelectedRestrictions}
          onClose={() => setOpenRestrictionsSelection(false)}
          handleSubmit={handleSubmit}
        />
      </Dialog>
      <Dialog
        open={Boolean(!isDialog && openExpertisesDialog)}
        onClose={() => setOpenExpertisesDialog(null)}
      >
        <ExpertisesSelection
          expertises={expertises}
          keyWords={keyWords}
          selectedWords={selectedWords}
          excludedWords={excludedWords}
          setKeyWords={setKeyWords}
          setSelectedWords={setSelectedWords}
          setExcludedWords={setExcludedWords}
          onClose={() => setOpenExpertisesDialog(null)}
        />
      </Dialog>
    </>
  );
};

export default PostForm;
