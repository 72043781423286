import React from 'react';
import _ from 'lodash';
// import GroupAddIcon from '@material-ui/icons/GroupAdd';

import {
  ToiletteIcon,
  RepasIcon,
  MenageIcon,
  LitIcon,
  CourseIcon,
  AccompagnementIcon,
  HandicapIcon,
  PersonneAgeeIcon,
} from '../../Themes/Icon';

export const PAGE_TYPE_OFFER = 'offer';
export const PAGE_TYPE_ADVICE = 'advice';

export const Offers = {
  UNA: [
    {
      step: 0,
      stepLabel: 'Aide au lever et au coucher',
      stepIcon: <LitIcon />,
      pages: [
        {
          slug: 'aide-lever-coucher',
          key: 'LEVER',
        },
      ],
    },
    {
      step: 1,
      stepLabel: "Aide à l'autonomie",
      stepIcon: <ToiletteIcon />,
      pages: [
        {
          slug: 'aide-autonomie',
          key: 'AUTONOMIE',
        },
        {
          slug: 'aide-a-la-toilette',
          key: 'TOILETTE',
        },
      ],
    },
    {
      step: 2,
      stepLabel: 'Aide aux courses',
      stepIcon: <CourseIcon />,
      pages: [
        {
          slug: 'aide-aux-courses',
          key: 'COURSES',
        },
      ],
    },
    {
      step: 3,
      stepLabel: 'Aide au repas',
      stepIcon: <RepasIcon />,
      pages: [
        {
          slug: 'aide-au-repas',
          key: 'REPAS',
        },
      ],
    },
    {
      step: 4,
      stepLabel: 'Aide au ménage',
      stepIcon: <MenageIcon />,
      pages: [
        {
          key: 'MENAGE',
          slug: 'aide-au-menage',
        },
      ],
    },
    {
      step: 5,
      stepLabel: 'Accompagnement',
      stepIcon: <AccompagnementIcon />,
      pages: [{ key: 'ACCOMPAGNEMENT', slug: 'accompagnement-et-sortie' }],
    },
  ],
  SCALAA: [
    {
      step: 0,
      stepLabel: 'Accompagnement',
      stepIcon: <AccompagnementIcon />,
      pages: [{ key: 'ACCOMPAGNEMENT', slug: 'accompagnement-et-sortie' }],
    },
  ],
  MORMAL: [],
  WELLO: [],
  SLA: [],
};

export const Advices = {
  UNA: [
    {
      step: 0,
      stepLabel: 'Allocation',
      stepIcon: <AccompagnementIcon />,
      pages: [
        {
          slug: 'allocation',
          key: 'ALLOCATION',
        },
      ],
    },
    {
      step: 1,
      stepLabel: 'Handicap',
      stepIcon: <HandicapIcon />,
      pages: [
        {
          slug: 'handicap',
          key: 'HANDICAP',
        },
      ],
    },
    {
      step: 2,
      stepLabel: 'Aide aux personnes âgées',
      stepIcon: <PersonneAgeeIcon />,
      pages: [
        {
          slug: 'personne-agee',
          key: 'PERSONNE_AGEE',
        },
      ],
    },
  ],
  SCALAA: [],
  MORMAL: [],
  WELLO: [],
  SLA: [],
};

export const getItemUrl = (item, type) =>
  type === PAGE_TYPE_ADVICE ? `/nos-conseils/${item.slug}` : `/nos-offres/${item.slug}`;

export const getItemsByType = type => (type === PAGE_TYPE_ADVICE ? Advices : Offers);

export const findItem = (namespace, item, type) => {
  const source = getItemsByType(type);
  const items = _.map(source[namespace], step =>
    _.find(step.pages, page => page.slug === item),
  ).filter(p => !!p);
  if (items && items.length > 0) return items[0];
  return null;
};

export const findStep = (namespace, item, type) => {
  const source = getItemsByType(type);
  const steps = _.filter(
    source[namespace],
    step => !!_.find(step.pages, page => page.slug === item),
  );
  if (steps && steps.length > 0) return steps[0];
  return null;
};
