import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import { Helmet } from 'react-helmet';
import Hidden from '@material-ui/core/Hidden';

// import SectionCta from '../Components/SectionCta';
import Footer from '../../../Components/Layout/Footer';
import FooterUna from '../../../PagesLeagues/Una/Footer';
import FooterScalaa from '../../../PagesLeagues/Scalaa/Footer';
import Routes from '../../../Routing/Routes';
import Seo from '../../../Routing/Seo';

import LayoutStyles from '../../../Components/Layout/Styles/LayoutStyles';
import ImageWithFallback from '../../../Components/Shared/ImageWithFallback';
import Stepper from '../Stepper';
import { findItem, PAGE_TYPE_OFFER } from '../PageHelper';
// import { organisationUnaCTA } from '../../../Services/OrganisationHelper';

type Props = {
  classes: Object,
  item: String,
};

const styles = theme => ({
  stepper: {
    ...LayoutStyles.fullWidthLayout,
    marginTop: '6rem',
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    marginTop: '0.5rem',
    marginBottom: '8rem',
  },
  layoutDiff: {
    display: 'flex',
    position: 'relative',
    alignItems: 'flex-start',
  },
  leftContent: {},
  rightContent: {
    marginTop: '0px',
    position: 'sticky',
    top: '4rem',
  },
  cardContainer: {
    marginBottom: '35px',
    height: '25em',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  root: {
    padding: theme.spacing(3, 2),
    marginBottom: 15,
  },
});

function Template1({ item: offer, classes }: Props) {
  const { t } = useTranslation();
  const type = findItem(Routes.currentNamespace(), offer, PAGE_TYPE_OFFER).key;
  const imagePath = `/images/leagues/${Routes.currentNamespace().toLowerCase()}/offer/${type.toLowerCase()}.jpg`;

  return (
    <>
      <Helmet>
        {Seo.title(
          `${t(`${Routes.currentNamespace()}.OFFER.${type}.TITLE`)}${t(
            `${Routes.currentNamespace()}.SEO.SUFFIX`,
          )}`,
        )}
        {Seo.description(`${t(`${Routes.currentNamespace()}.OFFER.${type}.SUBTITLE`)}`)}
      </Helmet>
      <Hidden only={['xs']}>
        <Grid className={classes.stepper}>
          <Stepper item={offer} type={PAGE_TYPE_OFFER} />
        </Grid>
      </Hidden>
      <Grid container className={classes.layout} spacing={8}>
        <Grid item xs={12}>
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            dangerouslySetInnerHTML={{
              __html: t(`${Routes.currentNamespace()}.OFFER.${type}.TITLE`),
            }}
          />
          <Typography
            variant="subtitle1"
            component="body1"
            gutterBottom
            dangerouslySetInnerHTML={{
              __html: t(`${Routes.currentNamespace()}.OFFER.${type}.SUBTITLE`),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.layoutDiff} spacing={8}>
            <Grid item md={8} className={classes.leftContent}>
              <ImageWithFallback
                src={imagePath}
                successRenderer={
                  <Card
                    className={classes.cardContainer}
                    style={{
                      backgroundImage: `url(${imagePath})`,
                    }}
                  />
                }
                errorRenderer={
                  <Card className={classes.cardContainer} style={{ backgroundColor: '#fff' }} />
                }
              />
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t(`${Routes.currentNamespace()}.OFFER.${type}.CHAP_1`),
                }}
              />
              <Typography
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t(`${Routes.currentNamespace()}.OFFER.${type}.CONTENT_1`),
                }}
              />
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t(`${Routes.currentNamespace()}.OFFER.${type}.CHAP_2`),
                }}
              />
              <Typography
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t(`${Routes.currentNamespace()}.OFFER.${type}.CONTENT_2`),
                }}
              />
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t(`${Routes.currentNamespace()}.OFFER.${type}.CHAP_3`),
                }}
              />
              <Typography
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t(`${Routes.currentNamespace()}.OFFER.${type}.CONTENT_3`),
                }}
              />
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t(`${Routes.currentNamespace()}.OFFER.${type}.CHAP_4`),
                }}
              />
              <Typography
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t(`${Routes.currentNamespace()}.OFFER.${type}.CONTENT_4`),
                }}
              />
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t(`${Routes.currentNamespace()}.OFFER.${type}.CHAP_5`),
                }}
              />
              <Typography
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t(`${Routes.currentNamespace()}.OFFER.${type}.CONTENT_5`),
                }}
              />
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t(`${Routes.currentNamespace()}.OFFER.${type}.CHAP_6`),
                }}
              />
              <Typography
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t(`${Routes.currentNamespace()}.OFFER.${type}.CONTENT_6`),
                }}
              />
            </Grid>
            {/* {Routes.currentNamespace() === 'UNA' && (
              <Grid item md={4} align="center" className={classes.rightContent}>
                <SectionCta
                  title={`${Routes.currentNamespace()}.PAGE.ORDER1.TITLE1`}
                  subTitle={`${Routes.currentNamespace()}.PAGE.ORDER1.SUBTITLE1`}
                  organisationCTA={organisationUnaCTA}
                  buttonLabel="APP.ASK_CONTACT.NAME"
                  descriptionPlaceHolder="APP.UNA.ASK_CONTACT.TEXT_FIELD.LABEL.NEEDS.PLACEHOLDER"
                  withEmail
                />
                <SectionCta
                  title={`${Routes.currentNamespace()}.PAGE.ORDER1.TITLE2`}
                  subTitle={`${Routes.currentNamespace()}.PAGE.ORDER1.SUBTITLE2`}
                  searchPanel
                  select
                />
              </Grid>
            )} */}
          </Grid>
        </Grid>
      </Grid>
      {Routes.currentNamespace() === 'UNA' && <FooterUna />}
      {Routes.currentNamespace() === 'SCALAA' && <FooterScalaa />}
      <Footer />
    </>
  );
}

export default withRouter(withStyles(styles)(Template1));
