import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import PinDropRounded from '@material-ui/icons/PinDropRounded';
import PhoneIcon from '@material-ui/icons/Phone';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import WelloAvatar from '../../Shared/WelloAvatar';

const useStyles = makeStyles(theme => ({
  resumeInfo: {
    padding: theme.spacing(2),
  },
  orgaHeader: {
    alignItems: 'center',
    backgroundColor: '#4D4A47',
    padding: theme.spacing(1.5, 2),
    color: '#fff',
    flexWrap: 'nowrap',
  },
  avatarOrga: {
    marginRight: theme.spacing(1),
  },
  list: {
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingLeft: '0px',
  },
  listItem: {
    marginRight: '3px',
    paddingLeft: '3px',
    minWidth: '40px',
  },
}));

type Props = {
  holding: Object,
  holdingSlug: String,
  organisationSlug: String,
  orgaName: String,
};

const CardHoldingSimplified = ({ holding, holdingSlug, organisationSlug, orgaName }: Props) => {
  const classes = useStyles();

  return (
    <Card elevation={1}>
      <CardActionArea component={Link} to={`/${holdingSlug}/${organisationSlug}`}>
        <Grid container className={classes.orgaHeader}>
          <WelloAvatar avatarUrl={holding?.cover_url} avatarClass={classes.avatarOrga} />
          {holding?.name} - {orgaName}
        </Grid>
        <Grid className={classes.resumeInfo}>
          {holding?.formatted_address && (
            <ListItem className={classes.list}>
              <ListItemIcon className={classes.listItem}>
                <PinDropRounded />
              </ListItemIcon>
              <ListItemText className={classes.listItem}>
                <Typography variant="subtitle2" component="div">
                  {holding?.formatted_address}
                </Typography>
              </ListItemText>
            </ListItem>
          )}
          {holding?.phone && (
            <ListItem className={classes.list}>
              <ListItemIcon className={classes.listItem}>
                <PhoneIcon />
              </ListItemIcon>
              <ListItemText className={classes.listItem}>
                <Typography variant="subtitle2" component="div">
                  {holding?.phone}
                </Typography>
              </ListItemText>
            </ListItem>
          )}
        </Grid>
      </CardActionArea>
    </Card>
  );
};

export default CardHoldingSimplified;
