import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import RepeatIcon from '@material-ui/icons/Repeat';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PinDropRounded from '@material-ui/icons/PinDropRounded';
import PeopleIcon from '@material-ui/icons/People';

import moment from 'moment';
import WelloAvatar from '../../Shared/WelloAvatar';

const useStyles = makeStyles(theme => ({
  firstlist: {
    padding: 0,
  },
  list: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: 0,
    paddingLeft: 0,
  },
  listItemText: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',
    maxHeight: '5.8em',
    whiteSpace: 'normal',
  },
  listItemIcon: {
    marginRight: theme.spacing(-2),
  },
  registrantAvatar: {
    height: '25px',
    width: '25px',
    fontSize: '0.8rem',
    marginRight: theme.spacing(-1),
  },
  chip: {
    margin: theme.spacing(0, 0.5, 0, 0),
    height: '25px',
  },
}));

type Props = {
  task: Object,
  registrantsCount: Number,
  registrants: Array,
  eventStartDate: Date,
  eventLastDate: Date,
  isLargeCard: Boolean,
  hideAddress: Boolean,
};

const ListTaskInfo = ({
  task,
  registrantsCount,
  registrants,
  eventStartDate,
  eventLastDate,
  isLargeCard,
  hideAddress,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const isFamilyTwig = Boolean(!task?.holding_slug);

  return (
    <>
      {task?.max_participants > 0 && task.registration && (
        <ListItem className={classes.firstlist}>
          <ListItemIcon className={classes.listItemIcon}>
            <PeopleIcon />
          </ListItemIcon>
          {!registrants && (
            <ListItemText>
              <Typography variant="subtitle2" component="p">
                {isFamilyTwig
                  ? t('APP.ACTIVITY.NO_PARTICIPANT')
                  : t('APP.ACTIVITY.SEAT_AVAILABLE.NUMBER', {
                      count: task?.max_participants - registrantsCount,
                      context: task?.max_participants - registrantsCount > 1 && 'PLURAL',
                    })}
              </Typography>
            </ListItemText>
          )}

          {registrants?.length > 0 &&
            registrants
              .slice(0, isLargeCard ? 4 : 2)
              .map(helper => (
                <Chip
                  icon={
                    <WelloAvatar
                      avatarUrl={helper.avatar_url}
                      firstName={helper.first_name}
                      lastName={helper.last_name}
                      backgroundColor={helper.avatar_background_color}
                      avatarClass={classes.registrantAvatar}
                      size={36}
                    />
                  }
                  key={helper.id}
                  label={helper.first_name}
                  className={classes.chip}
                />
              ))}
          {isLoggedIn && registrants?.length > (isLargeCard ? 4 : 2) && (
            <Tooltip
              title={registrants.slice(isLargeCard ? 4 : 2).map(helper => (
                <Grid key={JSON.stringify(helper)}>{helper.first_name}</Grid>
              ))}
              placement="bottom"
            >
              <Avatar className={classes.registrantAvatar}>{`+${
                registrants?.length - (isLargeCard ? 4 : 2)
              }`}</Avatar>
            </Tooltip>
          )}
        </ListItem>
      )}

      <ListItem className={classes.list}>
        <ListItemIcon className={classes.listItemIcon}>
          <ScheduleIcon />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="subtitle2" component="p">
            {task?.end_date
              ? `${moment(eventStartDate).format('LL')} ${
                  task?.twig_for_date?.event_start_time
                } - ${task?.twig_for_date?.event_end_time}`
              : `${moment(eventStartDate).format('LL')} ${task?.twig_for_date?.event_start_time}`}
          </Typography>
        </ListItemText>
        {task?.recurrence_type && task?.recurrence_type !== 'NONE' && (
          <Tooltip
            title={
              <>
                {task?.recurrence_interval > 1
                  ? t(`APP.ACTIVITY.RECURRENCE.${task?.recurrence_type.toUpperCase()}.MULTIPLE`, {
                      count: task.recurrence_interval,
                      endDate: moment(eventLastDate).format('L'),
                    })
                  : t(`APP.ACTIVITY.RECURRENCE.${task?.recurrence_type.toUpperCase()}.ONE`, {
                      endDate: moment(eventLastDate).format('L'),
                    })}
              </>
            }
            placement="bottom"
          >
            <ListItemIcon className={classes.listItemIcon} style={{ paddingLeft: 16 }}>
              <RepeatIcon />
            </ListItemIcon>
          </Tooltip>
        )}
      </ListItem>
      {task?.formatted_address && !hideAddress && (
        <ListItem className={classes.list}>
          <ListItemIcon className={classes.listItemIcon}>
            <PinDropRounded />
          </ListItemIcon>
          <ListItemText className={classes.listItemText}>
            <Typography variant="subtitle2" component="p">
              {task?.formatted_address}
            </Typography>
          </ListItemText>
        </ListItem>
      )}
    </>
  );
};

export default ListTaskInfo;
