/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { booleanPointInPolygon, point, polygon as tpolygon } from '@turf/turf';
import TextField from '@material-ui/core/TextField';
import { isClientSide } from '../../Config/ServerConfig';

const useStyles = makeStyles(() => ({
  textField: {
    backgroundColor: '#e6e6e6',
    boxShadow: 'none',
    borderRadius: '4px',
    '& .MuiInputBase-input': {
      color: '#000',
    },
  },
}));

type Props = {
  onSelect: Function,
  onClear: Function,
  placeHolder: String,
  defaultValue: String,
  addressTypes: String,
  polygon: Array,
  id: String,
  style: Object,
  bbox: Array,
  geographicLimits: Object,
  cssClass: String,
  countries: String,
  disabled: boolean,
  setQuery: Function,
};

function AutoCompletePlaces({
  onSelect,
  onClear,
  placeHolder,
  defaultValue,
  addressTypes,
  geographicLimits,
  id,
  style,
  cssClass,
  countries,
  disabled,
  setQuery,
}: Props) {
  const classes = useStyles();
  // function onSuggestionChange(event) {
  //   if (event.path[0].innerText === 'No results found') {
  //     // eslint-disable-next-line no-param-reassign
  //     event.path[0].innerHTML = event.path[0].innerHTML.replace(
  //       'No results found',
  //       'Aucun résultat',
  //     );
  //   }
  // }

  useEffect(() => {
    if (!isClientSide()) return;
    if (disabled) return;

    const pol = geographicLimits?.polygon ? tpolygon(geographicLimits.polygon) : null;

    const geocoder = new MapboxGeocoder({
      accessToken: process.env.RAZZLE_MAPBOX_GEOCODER_API_KEY,
      mapboxgl,
      placeholder: placeHolder,
      countries: countries || 'fr,gp,mq,re,bl,nc,pm,wf,mu,pf,yt,mf,gf, lu',
      types: addressTypes || 'place,address,poi',
      bbox: geographicLimits?.bbox,
      proximity: geographicLimits?.proximity,
      filter: item => {
        if (!pol) return true;
        if (booleanPointInPolygon(point([item.center[1], item.center[0]]), pol)) {
          return true;
        }
        return false;
      },
    });

    geocoder.addTo(`#${id}`);
    geocoder.on('loading', e => {
      if (setQuery && e?.query) setQuery(e.query);
    });
    geocoder.on('result', e => {
      if (!e.result) return;

      let locality = '';
      if (
        e.result.id?.includes('poi') ||
        e.result.id?.includes('address') ||
        e.result.id?.includes('postcode')
      ) {
        locality = e.result.context?.find(cont => cont.id?.includes('place'))?.text || '';
      } else if (e.result.id?.includes('place')) locality = e.result.text;
      const result = {
        formatted_address: e.result.place_name,
        coordinates: { lat: e.result.center[1], lng: e.result.center[0] },
        locality,
        postcode: e.result.context?.find(cont => cont.id?.includes('postcode'))?.text || '',
        bbox: e.result.bbox || [],
      };

      if (setQuery) setQuery(e.result.place_name);
      onSelect(result);
    });
    geocoder.on('clear', () => {
      onClear();
    });

    if (defaultValue) {
      geocoder._inputEl.value = defaultValue;
      geocoder._clearEl.style.display = 'block';
    }

    // document
    //   .getElementsByClassName('suggestions')[0]
    //   .addEventListener('DOMSubtreeModified', onSuggestionChange.bind(this));

    // eslint-disable-next-line consistent-return
    return () => {
      geocoder.onRemove();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, addressTypes]);

  return (
    <>
      {isClientSide() && (
        <div style={style} className={cssClass || 'mapbox-autocomplete-form'} id={id} />
      )}
      {(!isClientSide() || disabled) && (
        <TextField
          label=""
          variant="outlined"
          fullWidth
          multiline
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          placeholder={placeHolder}
          disabled={disabled}
        />
      )}
    </>
  );
}

export default AutoCompletePlaces;
