export const ASK_FOR = {
  ME: 'me',
  OTHER: 'other',
};

export const GENDER = {
  MALE: 'male',
  FEMALE: 'female',
};

export const isSharedLead = lead => lead?.type === 'Leads::Share';
