import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { useHistory, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Hidden from '@material-ui/core/Hidden';
import SectionCta from '../Components/SectionCta';
import Footer from '../../../Components/Layout/Footer';
import FooterUna from '../../../PagesLeagues/Una/Footer';
import FooterScalaa from '../../../PagesLeagues/Scalaa/Footer';
import Routes from '../../../Routing/Routes';
import Seo from '../../../Routing/Seo';

import LayoutStyles from '../../../Components/Layout/Styles/LayoutStyles';
import ImageWithFallback from '../../../Components/Shared/ImageWithFallback';
import Stepper from '../Stepper';
import { findItem, PAGE_TYPE_ADVICE } from '../PageHelper';

type Props = {
  classes: Object,
  item: String,
};

const styles = theme => ({
  stepper: {
    ...LayoutStyles.fullWidthLayout,
    marginTop: '6rem',
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    marginTop: '0.5rem',
    marginBottom: '8rem',
  },
  layoutDiff: {
    display: 'flex',
    position: 'relative',
    alignItems: 'flex-start',
  },
  leftContent: {},
  rightContent: {
    marginTop: '0px',
    position: 'sticky',
    top: '4rem',
  },
  cardContainer: {
    marginBottom: '35px',
    height: '25em',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  root: {
    padding: theme.spacing(3, 2),
    marginBottom: 15,
  },
});

function Temlate2({ item: advice, classes }: Props) {
  const { t } = useTranslation();

  const type = findItem(Routes.currentNamespace(), advice, PAGE_TYPE_ADVICE).key;
  const imagePath = `/images/leagues/${Routes.currentNamespace().toLowerCase()}/advice/${type.toLowerCase()}.jpg`;
  const history = useHistory();

  const organisationCTA = {
    id: 1010,
    name: "Besoin d'aide à domicile",
    description:
      "Trouvez près de chez vous une aide à domicile de confiance. Un savoir-faire, l'humain avant tout, le personnel ...",
    slug: 'una-administratif',
    banner_url: '/images/leagues/una/besoin_aide_a_domicile.jpg',
    holding: {
      id: 57,
      league_id: 1,
      name: 'UNA National',
      cover_url: '/images/leagues/una/logo_circle.jpg',
      slug: 'una-national',
    },
  };

  return (
    <>
      <Helmet>
        {Seo.title(
          `${t(`${Routes.currentNamespace()}.ADVICE.${type}.TITLE`)}${t(
            `${Routes.currentNamespace()}.SEO.SUFFIX`,
          )}`,
        )}
        {Seo.description(`${Routes.currentNamespace()}.ADVICE.${type}.SUBTITLE`)}
      </Helmet>
      <Hidden only={['xs']}>
        <Grid className={classes.stepper}>
          <Stepper item={advice} type={PAGE_TYPE_ADVICE} />
        </Grid>
      </Hidden>
      <Grid container className={classes.layout} spacing={8}>
        <Grid item xs={12}>
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            dangerouslySetInnerHTML={{
              __html: t(`${Routes.currentNamespace()}.ADVICE.${type}.TITLE`),
            }}
          />
          <Typography
            variant="subtitle2"
            component="body1"
            gutterBottom
            dangerouslySetInnerHTML={{
              __html: t(`${Routes.currentNamespace()}.ADVICE.${type}.SUBTITLE`),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.layoutDiff} spacing={8}>
            <Grid item md={8} className={classes.leftContent}>
              <ImageWithFallback
                src={imagePath}
                successRenderer={
                  <Card
                    className={classes.cardContainer}
                    style={{
                      backgroundImage: `url(${imagePath})`,
                    }}
                  />
                }
                errorRenderer={
                  <Card className={classes.cardContainer} style={{ backgroundColor: '#fff' }} />
                }
              />
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t(`${Routes.currentNamespace()}.ADVICE.${type}.CHAP_1`),
                }}
              />
              <Typography
                gutterBottom
                component="div"
                dangerouslySetInnerHTML={{
                  __html: t(`${Routes.currentNamespace()}.ADVICE.${type}.CONTENT_1`),
                }}
              />
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t(`${Routes.currentNamespace()}.ADVICE.${type}.CHAP_2`),
                }}
              />
              <Typography
                gutterBottom
                component="div"
                dangerouslySetInnerHTML={{
                  __html: t(`${Routes.currentNamespace()}.ADVICE.${type}.CONTENT_2`),
                }}
              />
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t(`${Routes.currentNamespace()}.ADVICE.${type}.CHAP_3`),
                }}
              />
              <Typography
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t(`${Routes.currentNamespace()}.ADVICE.${type}.CONTENT_3`),
                }}
              />
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t(`${Routes.currentNamespace()}.ADVICE.${type}.CHAP_4`),
                }}
              />
              <Typography
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t(`${Routes.currentNamespace()}.ADVICE.${type}.CONTENT_4`),
                }}
              />
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t(`${Routes.currentNamespace()}.ADVICE.${type}.CHAP_5`),
                }}
              />
              <Typography
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t(`${Routes.currentNamespace()}.ADVICE.${type}.CONTENT_5`),
                }}
              />
            </Grid>

            {Routes.currentNamespace() === 'UNA' && (
              <Grid item md={4} align="center" className={classes.rightContent}>
                <SectionCta
                  title={`${Routes.currentNamespace()}.PAGE.ORDER2.TITLE1`}
                  subTitle={`${Routes.currentNamespace()}.PAGE.ORDER2.SUBTITLE1`}
                  organisationCTA={organisationCTA}
                  buttonLabel="APP.ASK_CONTACT.NAME"
                  descriptionPlaceHolder="APP.UNA.ASK_CONTACT.TEXT_FIELD.LABEL.NEEDS.PLACEHOLDER"
                  withEmail
                />
                <SectionCta
                  title={`${Routes.currentNamespace()}.PAGE.ORDER2.TITLE2`}
                  subTitle={`${Routes.currentNamespace()}.PAGE.ORDER2.SUBTITLE2`}
                  searchPanel
                />
              </Grid>
            )}

            {Routes.currentNamespace() === 'SLA' && (
              <Grid item md={4} align="center" className={classes.rightContent}>
                <SectionCta
                  title={`${Routes.currentNamespace()}.PAGE.ORDER3.TITLE1`}
                  subTitle={`${Routes.currentNamespace()}.PAGE.ORDER3.SUBTITLE1`}
                  onButtonClick={() => history.push('/recherche/structures')}
                  buttonLabel="Trouver une plateforme de répit"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {Routes.currentNamespace() === 'UNA' && <FooterUna />}
      {Routes.currentNamespace() === 'SCALAA' && <FooterScalaa />}
      <Footer />
    </>
  );
}

export default withRouter(withStyles(styles)(Temlate2));
