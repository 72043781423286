import React from 'react';
import _ from 'lodash';

import Template1 from './Templates/Template1';
import Template2 from './Templates/Template2';

import Routes from '../../Routing/Routes';
import { PAGE_TYPE_OFFER, PAGE_TYPE_ADVICE, getItemsByType } from './PageHelper';

type Props = {
  match: Object,
};

function Page(props: Props) {
  const {
    match: {
      params: { offer, advice },
    },
  } = props;

  const type = offer ? PAGE_TYPE_OFFER : PAGE_TYPE_ADVICE;
  const item = offer || advice;
  switch (`${Routes.currentNamespace()}`) {
    case 'UNA': {
      const currentStep = _.filter(
        getItemsByType(type).UNA,
        step => !!_.find(step.pages, page => page.slug === item),
      );
      if (currentStep.length === 1) {
        return type === PAGE_TYPE_OFFER ? <Template1 item={item} /> : <Template2 item={item} />;
      }
      return null;
    }
    case 'SCALAA': {
      const currentStep = _.filter(
        getItemsByType(type).SCALAA,
        step => !!_.find(step.pages, page => page.slug === item),
      );
      if (currentStep.length === 1) {
        return type === PAGE_TYPE_OFFER ? <Template1 item={item} /> : <Template2 item={item} />;
      }
      return null;
    }
    default:
      return null;
  }
}

export default Page;
