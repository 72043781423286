import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch, useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import { orange } from '@material-ui/core/colors';

import LayoutStyles from '../../Layout/Styles/LayoutStyles';
import AutocompleteHelper from '../../Shared/AutocompleteHelper';
import WelloAvatar from '../../Shared/WelloAvatar';
import Roles, { getFormattedPendingRole, getFormattedRole } from '../../../Acl/Roles';
import OrganisationMembershipActions from '../../../Redux/OrganisationMembershipRedux';
import { getHelpedsFamilies } from '../../../Acl/Controls';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    ...LayoutStyles.centered600Layout,
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  headingContainer: {
    width: '100%',
    margin: '0 0 1rem',
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    margin: theme.spacing(2, 0),
    textAlign: 'center',
  },
  buttonLink: {
    textTransform: 'none',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  selected: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: '5px',
    backgroundColor: theme.palette.grey[300],
  },
  crossIcon: {
    flexGrow: 1,
    textAlign: 'end',
  },
  errorText: {
    display: 'flex',
    '&>*': {
      marginRight: theme.spacing(1),
      color: orange[500],
    },
  },
  configureHelped: {
    margin: theme.spacing(2, 0),
  },
  buttonBack: {
    margin: theme.spacing(0, 1, 1, 0),
  },
}));

type Props = {
  organisation: Object,
  handleClickInvite: Function,
  allowedRoles: Array,
  currentUser: Object,
  showTwig: Boolean,
  seatsAvailable: Number,
  onCancel: Function,
  registeredMembersIds: Array,
};

const RegisterMember = ({
  organisation,
  handleClickInvite,
  allowedRoles,
  currentUser,
  showTwig,
  seatsAvailable,
  onCancel,
  registeredMembersIds,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const {
    holding_slug: holdingSlug,
    organisation_slug: organisationSlug,
    family_slug: familySlug,
  } = match.params;
  const [selectedHelper, setSelectedHelper] = React.useState(currentUser);
  const [selectedHelped, setSelectedHelped] = React.useState(null);
  const [registerHelped, setRegisterHelped] = React.useState(false);
  const members = useSelector(state => state.organisationMembership.members);
  const isFetchingMembers = useSelector(
    state => state.organisationMembership.isFetching.getOrganisationMembership,
  );
  const seatsUnavailable =
    (allowedRoles?.includes(Roles.PRIMARY_HELPED) &&
      selectedHelper &&
      selectedHelped &&
      seatsAvailable < 2) ||
    (selectedHelper && seatsAvailable < 1);
  const helpedsLength = selectedHelper?.helpeds?.length;
  const helpedsFamilies = getHelpedsFamilies(selectedHelper);
  const helpedsFamiliesLength = helpedsFamilies?.length;

  React.useEffect(() => {
    const allMembersFromOrga = members?.every(m => m.organisation_slug === organisation?.slug);
    const allMembersFetch = allMembersFromOrga && members?.length === organisation?.helper_count;

    if (organisation && !familySlug && !allMembersFetch && !isFetchingMembers) {
      dispatch(
        OrganisationMembershipActions.getOrganisationMembershipRequest(
          holdingSlug,
          organisationSlug,
          null,
        ),
      );
    }
    if (organisation && familySlug) {
      dispatch(
        OrganisationMembershipActions.getOrganisationMembershipRequest(null, familySlug, null),
      );
    }
  }, [organisation, holdingSlug, organisationSlug, familySlug, dispatch]);

  React.useEffect(() => {
    if (registerHelped && helpedsLength === 1) {
      setSelectedHelped(selectedHelper?.helpeds[0]);
    }
    if (registerHelped && helpedsFamilies && helpedsFamiliesLength === 1) {
      setSelectedHelped(helpedsFamilies[0]?.admin);
    }
    if (!registerHelped) {
      setSelectedHelped(null);
    }
  }, [registerHelped, selectedHelper, selectedHelped, dispatch]);

  const membersFound =
    members &&
    members.length !== 0 &&
    members[0].organisation_slug === (familySlug || organisationSlug);

  const handleClick = () => {
    if (familySlug) {
      return history.push(`/families/${familySlug}`);
    }

    return history.push(`/${holdingSlug}/${organisationSlug}/primaries`);
  };

  const handleClose = () => {
    setSelectedHelper(null);
    setRegisterHelped(false);
    setSelectedHelped(null);
  };

  const renderSelectedMember = (selectedMember, isHelped) => {
    return (
      <Grid container flexdirection="row" display="flex" spacing={2} className={classes.selected}>
        <Grid item>
          <WelloAvatar
            avatarUrl={selectedMember.avatar_url}
            firstName={selectedMember.first_name}
            lastName={selectedMember.last_name}
            backgroundColor={selectedMember.avatar_background_color}
            size={36}
          />
        </Grid>
        <Grid item>
          <Typography variant="body1">{`${selectedMember.last_name} ${selectedMember.first_name}`}</Typography>
          <Typography variant="subtitle1" component="p">
            {isHelped
              ? getFormattedRole(Roles.PRIMARY_HELPED, t)
              : getFormattedRole(selectedMember.role, t) ||
                getFormattedPendingRole(selectedMember.pendingRole, t)}
          </Typography>
        </Grid>
        {((!isHelped && !showTwig) ||
          (isHelped && (helpedsLength > 1 || helpedsFamiliesLength > 1))) && (
          <Grid item className={classes.crossIcon}>
            <IconButton onClick={() => (isHelped ? setSelectedHelped(null) : handleClose())}>
              <CloseIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    );
  };
  return (
    <Paper className={classes.paper}>
      <Grid item xs={12} className={classes.headingContainer}>
        <Typography variant="h4" align="left" gutterBottom>
          {showTwig ? t('ASK_REGISTER') : t('APP.ACTIVITY.REGISTER_MEMBER.TITLE')}
        </Typography>
      </Grid>

      <form onSubmit={event => handleClickInvite(event, selectedHelper, selectedHelped?.id)}>
        {selectedHelper && renderSelectedMember(selectedHelper)}
        {!selectedHelper && (
          <FormControl component="fieldset" fullWidth className={classes.formControl}>
            <AutocompleteHelper
              members={
                (membersFound &&
                  members.map(member => ({
                    ...member.helper,
                    role: member.role,
                    validated: member.validated,
                  }))) ||
                []
              }
              onSuggestionSelected={value =>
                setSelectedHelper({
                  ...value,
                  role: value.validated ? value.role : Roles.VISITOR,
                  pendingRole: value.validated ? Roles.VISITOR : value.role,
                })
              }
              allowedRoles={allowedRoles?.length > 1 && allowedRoles}
              registeredMembersIds={registeredMembersIds}
            />
          </FormControl>
        )}
        {selectedHelper && selectedHelper.role === Roles.PRIMARY && (
          // !selectedHelper.has_carenotebook &&
          <FormControlLabel
            control={
              <Switch
                checked={registerHelped}
                onClick={() => setRegisterHelped(!registerHelped)}
                color="primary"
              />
            }
            label={
              showTwig
                ? t('APP.ACTIVITY.REGISTER_MYSELF.PRIMARY_HELPED')
                : t('APP.ACTIVITY.REGISTER_MEMBER.PRIMARY_HELPED', {
                    userName: `${selectedHelper?.last_name} ${selectedHelper.first_name}`,
                  })
            }
          />
        )}
        {registerHelped && !selectedHelped && (helpedsLength > 0 || helpedsFamiliesLength > 0) && (
          <FormControl component="fieldset" fullWidth className={classes.formControl}>
            <AutocompleteHelper
              members={
                selectedHelper?.helpeds
                  ? selectedHelper?.helpeds
                  : helpedsFamilies.map(member => member.admin) || []
              }
              onSuggestionSelected={value => setSelectedHelped(value)}
            />
          </FormControl>
        )}
        {registerHelped && selectedHelped && (
          <>
            {renderSelectedMember(selectedHelped, true)}
            {showTwig ? (
              <Typography variant="body1" gutterBottom>
                {allowedRoles?.includes(Roles.PRIMARY_HELPED)
                  ? t('APP.ACTIVITY.REGISTER_MYSELF.REGISTER.PRIMARY_HELPED')
                  : t('APP.ACTIVITY.REGISTER_MYSELF.NO_REGISTER.PRIMARY_HELPED')}
              </Typography>
            ) : (
              <Typography variant="body1" gutterBottom>
                {allowedRoles?.includes(Roles.PRIMARY_HELPED)
                  ? t('APP.ACTIVITY.REGISTER_MEMBER.REGISTER.PRIMARY_HELPED')
                  : t('APP.ACTIVITY.REGISTER_MEMBER.NO_REGISTER.PRIMARY_HELPED', {
                      helped: `${selectedHelped?.last_name} ${selectedHelped.first_name}`,
                    })}
              </Typography>
            )}
          </>
        )}

        {registerHelped && helpedsLength === 0 && (
          <Grid className={classes.configureHelped}>
            <>
              <Typography variant="body1" className={classes.errorText}>
                <ErrorIcon />
                {t('APP.ACTIVITY.REGISTER_MEMBER.NO_PRIMARY_HELPED')}
              </Typography>
              {showTwig ? (
                <Typography variant="body1">
                  {t('APP.ACTIVITY.REGISTER_MYSELF.CONFIGURE.PRIMARY_HELPED')}
                  <a
                    href={`/families/${selectedHelper?.family?.slug}`}
                    className={classes.buttonLink}
                  >
                    {t('FAMILY_HOME')}
                  </a>
                  {t('APP.ACTIVITY.REGISTER_MYSELF.CONFIGURE.PRIMARY_HELPED.CARENOTEBOOK')}
                </Typography>
              ) : (
                <Typography variant="body1">
                  {t('APP.ACTIVITY.REGISTER_MEMBER.CONFIGURE.PRIMARY_HELPED', {
                    user: `${selectedHelper?.last_name} ${selectedHelper.first_name}`,
                  })}
                  <a
                    href={`/families/${selectedHelper?.family_slug}`}
                    className={classes.buttonLink}
                  >
                    {t('FAMILY_HOME')}
                  </a>
                </Typography>
              )}
            </>
          </Grid>
        )}
        <Grid className={classes.buttonContainer}>
          <Button className={classes.buttonBack} onClick={onCancel}>
            {t('BACK')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!selectedHelper || (registerHelped && !selectedHelped) || seatsUnavailable}
            className={classes.buttonBack}
          >
            {showTwig ? t('ASK_REGISTER') : t('REGISTER')}
          </Button>
          {seatsUnavailable && (
            <Typography variant="subtitle2" component="p" color="error">
              {t(t('APP.ACTIVITY.SEAT_UNAVAILABLE.LABEL'))}
              {' - '}
              {t('APP.ACTIVITY.SEAT_AVAILABLE.NUMBER', {
                count: Math.max(seatsAvailable, 0),
              })}
            </Typography>
          )}
        </Grid>
        {!showTwig && (
          <Grid className={classes.buttonContainer} flexdirection="row">
            <Typography variant="body1" align="center" gutterBottom>
              {t('APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.NEW.NOT_FOUND')}
            </Typography>
            <Button className={classes.buttonLink} color="primary" onClick={handleClick}>
              {t(
                holdingSlug
                  ? 'APP.ACTIVITY.REGISTER_MEMBER.GO.GRID_MEMBERS'
                  : 'APP.ACTIVITY.REGISTER_MEMBER.GO.FAMILY_MEMBERS',
              )}
            </Button>
          </Grid>
        )}
      </form>
    </Paper>
  );
};

export default RegisterMember;
