import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import LayoutStyles from '../Components/Layout/Styles/LayoutStyles';
// import { Images } from '../Themes';

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
    paddingTop: theme.spacing(5),
    height: '90vh',
  },
  marginAuto: {
    margin: 'auto',
  },
  marginText: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(0.5),
  },
  button: {
    backgroundColor: theme.wello.primary.main,
    color: '#fff',
    margin: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: theme.wello.primary.dark,
    },
  },
  img: {
    width: '100%',
  },
}));

const NoMatch401 = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Route
      render={({ staticContext }) => {
        // eslint-disable-next-line no-param-reassign
        if (staticContext) staticContext.status = 401;
        return (
          <Grid container spacing={4} className={classes.layout}>
            <Grid item md={6} xs={12} align="left" className={classes.marginAuto}>
              <Typography variant="h2" component="h1" className={classes.marginText}>
                {t('APP.401.TITLE')}
              </Typography>
              <Typography variant="h4" component="p" className={classes.marginText}>
                {t('APP.401.SUBTITLE')}
              </Typography>
              <Typography variant="subtitle1" component="p" className={classes.marginText}>
                {t('APP.401.BODY')}
              </Typography>
              <Button
                onClick={history.goBack}
                variant="contained"
                disableElevation
                className={classes.button}
              >
                {t('APP.404.BACK')}
              </Button>
              <Button href="/" variant="contained" disableElevation className={classes.button}>
                {t('APP.404.HOME')}
              </Button>
            </Grid>
            <Grid item md={6} xs={12} align="left" className={classes.marginAuto}>
              {/* <img src={Images.app.no_match} alt="Page 404" className={classes.img} /> */}
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default NoMatch401;
