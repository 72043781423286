import React from 'react';
import UnaCandidate from './Una/Candidate';

type Props = {
  league: Object,
};

function Candidate(props: Props) {
  return <UnaCandidate {...props} />;
}

export default Candidate;
