import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

type Props = {
  openDialog: Boolean,
  dialogTitle: Object,
  dialogContent: Object,
  onClickConfirm: Function,
  onCancel: Function,
};

const ConfirmationDialog = ({
  openDialog,
  dialogTitle,
  dialogContent,
  onClickConfirm,
  onCancel,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Dialog open={openDialog} onClose={onCancel}>
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h4" align="center">
          {dialogTitle}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{dialogContent}</DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={onCancel} color="primary">
          {t('CANCEL')}
        </Button>
        <Button onClick={onClickConfirm}>{t('CONFIRM')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
