import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import LayoutStyles from '../../../Components/Layout/Styles/LayoutStyles';
import HoldingForm from '../../../Components/Holdings/HoldingForm';
import HoldingActions from '../../../Redux/HoldingRedux';
import Routes from '../../../Routing/Routes';

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.centered600Layout,
    marginTop: 0,
  },
  container: {
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(2),
  },
  headingContainer: {
    width: '100%',
  },
}));

type Props = {
  holding: Object,
};

const HoldingNew = ({ holding }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { league_id: leagueId } = match.params;

  const handleSubmit = (currentUser, holdingAttributes, image, charter, email, type) => {
    let holdingData = { ...holdingAttributes };
    if (holding?.algolia_id) holdingData = { ...holdingData, algolia_id: holding.algolia_id };
    let data = {
      league_id: leagueId || Routes.currentLeague().id,
      holding: holdingData,
      type,
    };
    if (email) data = { ...data, user: { email } };
    dispatch(HoldingActions.createHoldingRequest(data, image, charter, currentUser.id));
  };

  return (
    <Grid container className={classes.layout}>
      <Paper className={classes.paper}>
        <Grid item xs={12} className={classes.headingContainer}>
          <Grid item xs={12}>
            <Typography variant="h4" align="left" gutterBottom>
              {t('APP.HOLDING.SETTINGS.GENERAL.NEW.TITLE')}
            </Typography>
          </Grid>
        </Grid>
        <HoldingForm onSubmit={handleSubmit} holding={holding} />
      </Paper>
    </Grid>
  );
};

export default HoldingNew;
