// https://www.keene.edu/campus/maps/tool/
import values from 'lodash/values';
import { booleanPointInPolygon, point, polygon as tpolygon, distance } from '@turf/turf';
import api from '../../Services/Api';

export const MORMAL_ZONES = {
  start: {
    polygon: [
      [
        [50.3541412, 3.5755949],
        [50.2784595, 3.4850776],
        [50.188646, 3.4631229],
        [50.0819917, 3.4889954],
        [50.0018449, 3.6749252],
        [50.0322661, 3.8550953],
        [50.1167566, 3.9295753],
        [50.2612161, 4.0199049],
        [50.3440671, 4.0045971],
        [50.3667078, 3.8910682],
        [50.3889084, 3.7671003],
        [50.3701123, 3.7215482],
        [50.3460903, 3.7046561],
        [50.3946164, 3.673428],
        [50.3780258, 3.6086468],
        [50.3541412, 3.5755949],
      ],
    ],
    bbox: [3.418559, 49.947365, 4.025604, 50.431319],
    proximity: [3.700433, 50.219909],
  },
  end: {
    polygon: [
      [
        [50.70705124326229, 3.244944334579469],
        [50.46329295646165, 3.676194101572037],
        [50.54329258639981, 4.357137233018876],
        [50.476785201085505, 4.726354535669089],
        [49.976288926908076, 4.162710428787232],
        [50.01039361361612, 3.0987399816513066],
        [50.163402304885, 3.103076778352261],
        [50.24913573274631, 3.041252195835114],
        [50.47227516183428, 2.960710991173983],
        [50.52553711016891, 2.7995823031196605],
        [50.61690140115643, 2.779138684272766],
        [50.61747662916982, 2.452912330627442],
        [51.003728044755576, 2.088220993606211],
        [51.100092784308075, 2.5310933589935307],
        [50.826972541510784, 2.7277478948235516],
        [50.79439520440888, 3.120610713958741],
        [50.70705124326229, 3.244944334579469],
      ],
    ],
    bbox: [2.088220993606211, 49.976288926908076, 4.726354535669089, 51.100092784308075],
    proximity: [3.4072877646376503, 50.53819085560808],
  },
};

export const TURF_POLYGON = [
  [
    [50.3541412, 3.5755949],
    [50.2784595, 3.4850776],
    [50.188646, 3.4631229],
    [50.0819917, 3.4889954],
    [50.0018449, 3.6749252],
    [50.0322661, 3.8550953],
    [50.1167566, 3.9295753],
    [50.2612161, 4.0199049],
    [50.3440671, 4.0045971],
    [50.3667078, 3.8910682],
    [50.3889084, 3.7671003],
    [50.3701123, 3.7215482],
    [50.3460903, 3.7046561],
    [50.3946164, 3.673428],
    [50.3780258, 3.6086468],
    [50.3541412, 3.5755949],
  ],
];

export const DEPARTMENT_CODES = {
  Nord: 59,
};

export const zoneToPolygon = city => {
  let polygons = city.zone
    .replace(/POLYGON \(\(/, '')
    .replace(/\)\)$/, '')
    .split(', ');
  polygons = polygons.map(polygon => {
    const coordinates = polygon.split(' ');
    return [coordinates[1], coordinates[0]].map(latLng => parseFloat(latLng, 10));
  });
  return polygons;
};

export const askTransport = (
  addressFrom,
  addressTo,
  transportDate,
  transportTime,
  upsertForm,
  showToaster,
  onHistoryPush,
) => {
  api.getCities(addressFrom.locality, values(DEPARTMENT_CODES)).then(resp => {
    // console.log(addressFrom, addressTo, transportDateTime, resp);
    const searchCity =
      (resp?.data?.cities || []).filter(city => {
        const zone = zoneToPolygon(city);
        // console.log(zone, TURF_POLYGON);
        let inPoly = false;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < zone.length; i++) {
          if (booleanPointInPolygon(point(zone[i]), tpolygon(TURF_POLYGON))) {
            inPoly = true;
            break;
          }
        }
        return inPoly;
      }) || resp.data.cities;
    const km = distance(
      point([addressFrom.latitude, addressFrom.longitude]),
      point([addressTo.latitude, addressTo.longitude]),
      { units: 'kilometers' },
    );
    upsertForm({ addressFrom, addressTo, transportDate, transportTime, distance: km });
    if (searchCity && searchCity.length > 0) {
      onHistoryPush(searchCity[0].slug);
    } else {
      console.log(
        `City '${addressFrom.locality}' not found in polygon (${resp?.data?.cities?.length} cities tested)`,
      );
      showToaster();
    }
  });
};
