import React from 'react';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import _ from 'lodash';
import CitiesActions from '../../Redux/CitiesRedux';
import { getCityName } from '../../PagesLeagues/Una/UnaHelper';

type Props = {
  value: String,
  classes: Object,
  className: Object,
  history: Function,
  getCities: Function,
  resetCities: Function,
  isFetching: false,
  allowedZipCodes: Array,
  suggestions: Array,
  onSuggestionSelected: Function,
  fullWidth: Boolean,
  error: Boolean,
};

const styles = theme => ({
  container: {
    position: 'relative',
  },
  searchIcon: {
    width: '1em!important',
    height: '1em!important',
    color: grey[400],
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
  textFieldRoot: {
    borderStyle: 'solid',
    borderWidth: 'thin',
    borderColor: '#c5c5c5',
    height: '56px',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
});

const DELAY_BEFORE_SEARCH_IN_MS = 150;

function getPathWhereToRedirectTo(citySlug) {
  return `/cities/${citySlug}`;
}

function renderInputComponent(isFetching) {
  return inputProps => {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps;

    return (
      <TextField
        InputProps={{
          disableUnderline: true,
          classes: {
            root: classes.textFieldRoot,
          },
          startAdornment: (
            <InputAdornment position="start">
              {isFetching ? (
                <CircularProgress className={classes.searchIcon} />
              ) : (
                <SearchIcon className={classes.searchIcon} />
              )}
            </InputAdornment>
          ),
          inputRef: node => {
            ref(node);
            inputRef(node);
          },
        }}
        {...other}
      />
    );
  };
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion.name, matches);

  return (
    <MenuItem selected={isHighlighted}>
      {parts.map(part => (
        <span key={JSON.stringify(part)} style={{ fontWeight: part.highlight ? 500 : 300 }}>
          {part.text}
        </span>
      ))}
    </MenuItem>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.name;
}

class AutoCompleteCity extends React.Component<Props> {
  popperNode = null;

  constructor(props) {
    super(props);
    this.state = {
      value: getCityName(props.value).toUpperCase(),
    };
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    const { getCities, allowedZipCodes } = this.props;

    getCities(value, allowedZipCodes);
  };

  handleSuggestionSelected =
    () =>
    (event, { suggestion }) => {
      const { onSuggestionSelected, history } = this.props;
      if (onSuggestionSelected) {
        onSuggestionSelected(suggestion?.slug);
      } else {
        const path = getPathWhereToRedirectTo(suggestion.slug);
        history.push(path);
      }
    };

  handleSuggestionsClearRequested = () => {
    const { resetCities } = this.props;

    resetCities();
  };

  handleChange =
    () =>
    (event, { newValue, method }) => {
      const { suggestions, onSuggestionSelected } = this.props;
      if (onSuggestionSelected && (method === 'down' || method === 'up')) {
        const params = { suggestion: _.find(suggestions, city => city.name === newValue), method };
        this.handleSuggestionSelected()(event, params);
      }
      this.setState({
        value: newValue || '',
      });
    };

  render() {
    const { className, classes, suggestions, isFetching, fullWidth, error } = this.props;
    const { value } = this.state;

    const autosuggestProps = {
      suggestions,
      renderInputComponent: renderInputComponent(isFetching),
      onSuggestionSelected: this.handleSuggestionSelected(),
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion,
    };

    return (
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          className,
          classes,
          placeholder: 'Votre ville',
          value,
          onChange: this.handleChange(),
          error,
          fullWidth,
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps}>{options.children}</Paper>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  suggestions: state.cities.cities,
  isFetching: state.cities.isFetching,
});

const mapDispatchToProps = dispatch => ({
  getCities: debounce(
    (term, allowedZipCodes) => dispatch(CitiesActions.getCitiesRequest(term, allowedZipCodes)),
    DELAY_BEFORE_SEARCH_IN_MS,
  ),
  resetCities: () => dispatch(CitiesActions.resetCities()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(AutoCompleteCity)));
