import React from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import LayoutStyles from '../../../Components/Layout/Styles/LayoutStyles';
import AuthActions from '../../../Redux/AuthRedux';
import { checkEmailValidity } from '../../../Services/DataHelper';
import Roles from '../../../Acl/Roles';
import SignUpForm from '../SignUpForm';
import { isCommunity } from '../../../Acl/Controls';

const useStyles = makeStyles(() => ({
  layout: LayoutStyles.centered600Layout,
  paper: LayoutStyles.centered400Paper,
}));

type Props = {
  organisation: Object,
  isDialog: Boolean,
  signUpRole: String,
  onSubmitClick: Function,
  subtitle: String,
  title: String,
  preventSubmitSignUp: boolean,
  hideFieldsIfCurrentUser: Boolean,
};

const OrganisationSignUp = ({
  organisation,
  isDialog,
  signUpRole,
  onSubmitClick,
  subtitle,
  title,
  preventSubmitSignUp,
  hideFieldsIfCurrentUser,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const { userRole } = match.params;
  const currentUser = useSelector(state => state.auth.currentUser);
  const pendingUser = useSelector(state => state.auth.pendingUser);

  const role = isCommunity(organisation) ? Roles.MEMBER : userRole || signUpRole || Roles.VOLUNTEER;

  const handleSubmit = (
    event,
    firstName,
    lastName,
    phone,
    countryCode,
    phoneError,
    email,
    description,
  ) => {
    if ((!currentUser && !checkEmailValidity(email)) || phoneError) return;

    event.preventDefault();
    const user = {
      phone,
      country_code: countryCode,
      email: email?.toLowerCase(),
      description,
      role,
      first_name: firstName,
      last_name: lastName,
    };
    if (!preventSubmitSignUp && organisation.holding_slug && organisation.slug) {
      dispatch(
        AuthActions.signUpOrganisationRequest(organisation.holding_slug, organisation.slug, user),
      );
    }
    if (onSubmitClick) {
      onSubmitClick(email, user);
    }
  };

  if (pendingUser && !isDialog) {
    return <Redirect to={`/signup/pending/${organisation.holding_slug}/${organisation.slug}`} />;
  }

  return (
    <>
      <Grid container className={classes.layout} style={{ marginTop: isDialog ? 0 : 100 }}>
        <Paper className={classes.paper}>
          <Typography gutterBottom align="center" variant="h5">
            {title || t('WELLO.SIGN_UP.TITLE')}
          </Typography>
          <Typography align="center" variant="subtitle1">
            {subtitle ||
              t(`WELLO.SIGN_UP.SUBTITLE.ROLE.${role.toUpperCase()}`, {
                organisationName: `${organisation.holding?.name} - ${organisation.name}`,
              })}
          </Typography>
          <SignUpForm
            handleSubmit={handleSubmit}
            hideFieldsIfCurrentUser={hideFieldsIfCurrentUser}
          />
        </Paper>
      </Grid>
    </>
  );
};

export default OrganisationSignUp;
