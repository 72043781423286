import React from 'react';
import Routes from '../../../Routing/Routes';
import Breadcrumb from './Breadcrumb';
import { FILTERING_ATTRIBUTES, LEAGUES_WITH_RESULTS_ALGO } from '../../../Services/AlgoliaHelper';
import Grid from '@material-ui/core/Grid';

type Props = {
  task: Object,
};

const TaskBreadcrumb = ({ task }: Props) => {
  const rootName = Routes.currentLeague().rootName;
  let searchUrl = '/recherche/activites';

  const links = [
    { name: rootName, link: '/' },
    { name: 'Activités', link: searchUrl },
  ];

  if (task.locality && task.formatted_address && task.coordinates) {
    searchUrl = `${searchUrl}?city=${task.locality}&address=${task.formatted_address}&lat=${task.coordinates?.latitude}&long=${task.coordinates?.longitude}`;
    if (LEAGUES_WITH_RESULTS_ALGO.includes(Routes.currentNamespace()))
      searchUrl += `&bbox=${task.coordinates?.longitude - 0.01};${
        task.coordinates?.latitude - 0.01
      };${task.coordinates?.longitude + 0.01};${task.coordinates?.latitude + 0.01}`;

    links.push({ name: task.locality, link: searchUrl });
  }

  if (task.expertise) {
    const expertiseUrl = `${searchUrl}${links.length > 2 ? '&' : '?'}${
      FILTERING_ATTRIBUTES.TWIG_EXPERTISE_SLUG_ATTRIBUTE
    }=${task.expertise.slug}`;
    links.push({ name: task.expertise.label, link: expertiseUrl });
  }

  const current = { name: task.description };

  return (
    <Grid style={{ marginLeft: -32 }}>
      <Breadcrumb previousLinks={links} currentLink={current} />
    </Grid>
  );
};

export default TaskBreadcrumb;
