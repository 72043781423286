import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginGetFile from 'filepond-plugin-get-file';
import CircularProgress from '@material-ui/core/CircularProgress';

import AutoCompletePlaces from '../Shared/AutoCompletePlaces';
// import PathologiesSettings from './PathologiesSettings';
import WelloAvatar from '../Shared/WelloAvatar';
import Roles, {
  getDefaultHoldingApplicationRoles,
  getPossibleHoldingApplicationRoles,
} from '../../Acl/Roles';
import HoldingActions from '../../Redux/HoldingRedux';
import { getCdnUrl } from '../../Services/ImageResizer';
import PhoneControl from '../Shared/PhoneControl';
import { grey } from '@material-ui/core/colors';

registerPlugin(FilePondPluginFilePoster, FilePondPluginGetFile);

type Props = {
  onSubmit: Function,
  holding: Object,
};

const useStyles = makeStyles(theme => ({
  selectLabel: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff',
  },
  advanced: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    justifyContent: 'center',
  },
  button: {
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  formLabel: {
    paddingLeft: '3px',
    paddingRight: '3px',
    zIndex: '1002',
    backgroundColor: 'white',
  },
  avatar: {
    width: 80,
    height: 80,
    fontSize: '2.5rem',
  },
  cameraButton: {
    backgroundColor: theme.palette.grey[300],
    padding: '5px',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.primary.main,
    },
  },
  badge: {
    width: 'fit-content',
    marginTop: theme.spacing(1),
  },
  label: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  radioGroup: {
    display: 'flex',
    flexFlow: 'column wrap',
    paddingLeft: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(3),
  },
  helpIcon: {
    color: grey[600],
    marginTop: theme.spacing(1),
    padding: 2,
  },
}));

const HoldingForm = ({ onSubmit, holding }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useSelector(state => state.auth.currentUser);
  const isFetching = useSelector(state => state.holding.isFetching.createHolding);

  const [name, setName] = React.useState(holding?.name || '');
  const [siret, setSiret] = React.useState(holding?.siret || '');
  const [shortDescription, setShortDescription] = React.useState(holding?.short_description || '');
  const [type, setType] = React.useState(holding?.type || '');
  const [phone, setPhone] = React.useState(holding?.phone || '');
  const [countryCode, setCountryCode] = React.useState(holding?.country_code || 'fr');
  const [phoneError, setPhoneError] = React.useState(null);
  const [externalLink, setExternalLink] = React.useState(holding?.external_link || '');
  const [formattedAddress, setFormattedAddress] = React.useState(holding?.formatted_address || '');
  const [locality, setLocality] = React.useState(holding?.locality || '');
  const [latitude, setLatitude] = React.useState(holding?.coordinates?.latitude || '');
  const [longitude, setLongitude] = React.useState(holding?.coordinates?.longitude || '');
  const [email, setEmail] = React.useState(holding?.contact_email || '');
  // const [agreementCertificate, setAgreementCertificate] = React.useState(
  //   holding?.agreement_certificate || '',
  // );
  // const [paymentMethods, setPaymentsMethods] = React.useState(holding?.payment_methods || '');
  // const [holdingPathologyIds, setHoldingPathologyIds] = React.useState(
  //   holding?.pathologies?.map(pathology => pathology.id) || [],
  // );
  const [charters, setCharters] = React.useState([]);
  const [logoPreview, setLogoPreview] = React.useState(getCdnUrl(holding?.cover_url, 130));
  const [logo, setLogo] = React.useState();
  const [openedApplicationRoles, setOpenedApplicationRoles] = React.useState(
    holding?.opened_application_roles ||
      getDefaultHoldingApplicationRoles({ type: holding?.type || type }),
  );

  const possibleApplicationRoles = getPossibleHoldingApplicationRoles({
    type: holding?.type || type,
  });

  const allEstablishmentTypes = useSelector(state => state.holding.establishmentTypes);
  const filteredEstablishment = allEstablishmentTypes?.filter(e => e.category === type);
  const hasEstablishmentToShow = filteredEstablishment?.length > 0;
  const [establishmentId, setEstablishmentId] = React.useState(holding?.establishment?.id || '');

  const isInvalidForm =
    phoneError !== null ||
    !formattedAddress ||
    !type ||
    (hasEstablishmentToShow && !establishmentId) ||
    !shortDescription;

  const handleCheckRole = event => {
    const roles = openedApplicationRoles.slice();
    if (event.target.checked) {
      const index = roles.indexOf(event.target.name);
      if (index === -1) {
        roles.push(event.target.name);
        setOpenedApplicationRoles(roles);
      }
    } else {
      const index = openedApplicationRoles.indexOf(event.target.name);
      if (index > -1) {
        roles.splice(index, 1);
        setOpenedApplicationRoles(roles);
      }
    }
  };

  React.useEffect(() => {
    if (!allEstablishmentTypes) dispatch(HoldingActions.getAllEstablishmentTypesRequest());
  }, []);

  React.useEffect(() => {
    const cover = holding?.cover_url;
    if (cover) {
      setLogo({
        source: cover,
        options: {
          type: 'local',
          file: { name: cover, size: 0 },
          metadata: { poster: cover },
        },
      });
    }

    if (holding?.charters?.length > 0) {
      setCharters([
        {
          source: holding.charters[0].download_url,
          options: {
            type: 'local',
            file: {
              id: holding.charters[0].id,
              name: holding.charters[0].filename,
              size: 0,
              url: holding.charters[0].download_url,
            },
            metadata: { poster: holding.charters[0] },
          },
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickSubmit = event => {
    event.preventDefault();

    const holdingAttributes = {
      name,
      short_description: shortDescription,
      // agreement_certificate: agreementCertificate,
      // payment_methods: paymentMethods,
      // pathology_ids: holdingPathologyIds,
      formatted_address: formattedAddress,
      latitude,
      longitude,
      locality,
      phone,
      country_code: countryCode,
      siret,
      external_link: externalLink,
      opened_application_roles: openedApplicationRoles,
      establishment_id: establishmentId,
      contact_email: email,
    };
    onSubmit(currentUser, holdingAttributes, logo, charters[0], holding?.email || '', type);
  };

  const handleLoadAvatar = ev => {
    const file = ev.target.files[0];
    const reader = new FileReader();
    reader.onload = e => {
      const img = document.createElement('img');
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const MAX_WIDTH = 130;
        const MAX_HEIGHT = 130;
        let { width, height } = img;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
        canvas.width = width;
        canvas.height = height;
        ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        const dataurl = canvas.toDataURL('image/png');
        setLogoPreview(dataurl);
      };
      img.src = e.target.result;
      setLogoPreview(null);
      setLogo(file);
    };
    if (file) reader.readAsDataURL(file);
  };

  return (
    <form onSubmit={handleClickSubmit}>
      <FormControl component="fieldset" fullWidth>
        <TextField
          name="name"
          value={name}
          onChange={event => setName(event.target.value)}
          label={t('APP.HOLDING.SETUP.TEXT_FIELD.LABEL.NAME')}
          margin="normal"
          variant="outlined"
          fullWidth
          required
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
      <FormControl component="fieldset" fullWidth>
        <TextField
          name="siret"
          value={siret}
          type="number"
          onChange={event => {
            setSiret(event.target.value);
          }}
          label={t('APP.HOLDING.SETUP.TEXT_FIELD.LABEL.SIRET')}
          margin="normal"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          disabled={!!holding?.siret}
        />
      </FormControl>
      <FormControl component="fieldset" fullWidth disabled={holding?.type} required margin="normal">
        <InputLabel variant="outlined" shrink htmlFor="type-simple" className={classes.selectLabel}>
          {t('APP.ORGANISATION.SETTINGS.GENERAL.TYPE')}
        </InputLabel>
        <Select
          fullWidth
          value={type}
          onChange={event => {
            setType(event.target.value);
            setEstablishmentId(null);
            setOpenedApplicationRoles(
              getDefaultHoldingApplicationRoles({ type: event.target.value }),
            );
          }}
          input={<OutlinedInput labelWidth={1} name="type" id="type-simple" />}
          variant="outlined"
        >
          <MenuItem value="Holdings::Association">
            {t('APP.ORGANISATION.SETTINGS.GENERAL.TYPE.Organisations::Association')}
          </MenuItem>
          <MenuItem value="Holdings::Pfr">
            {t('APP.ORGANISATION.SETTINGS.GENERAL.TYPE.Organisations::Pfr')}
          </MenuItem>
          <MenuItem value="Holdings::Establishment">
            {t('APP.ORGANISATION.SETTINGS.GENERAL.TYPE.Organisations::Establishment')}
          </MenuItem>
          <MenuItem value="Holdings::Healthcare">
            {t('APP.ORGANISATION.SETTINGS.GENERAL.TYPE.Organisations::Healthcare')}
          </MenuItem>
          <MenuItem value="Holdings::Sap">
            {t('APP.ORGANISATION.SETTINGS.GENERAL.TYPE.Organisations::Sap')}
          </MenuItem>
          <MenuItem value="Holdings::Other">
            {t('APP.ORGANISATION.SETTINGS.GENERAL.TYPE.Organisations::Other')}
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl
        component="fieldset"
        fullWidth
        disabled={!hasEstablishmentToShow}
        required={hasEstablishmentToShow}
        margin="normal"
      >
        <InputLabel variant="outlined" shrink htmlFor="type-simple" className={classes.selectLabel}>
          {t('APP.ORGANISATION.SETTINGS.GENERAL.ESTABLISHMENT')}
        </InputLabel>
        <Select
          fullWidth
          value={hasEstablishmentToShow ? establishmentId : 'noType'}
          onChange={event => setEstablishmentId(event.target.value)}
          input={<OutlinedInput labelWidth={1} name="type" id="type-simple" />}
          variant="outlined"
        >
          {!hasEstablishmentToShow && (
            <MenuItem value="noType">
              <Typography style={{ fontSize: '0.8rem', fontStyle: 'italic', color: 'grey' }}>
                {t('APP.ORGANISATION.SETTINGS.GENERAL.ESTABLISHMENT.NO_TYPE')}
              </Typography>
            </MenuItem>
          )}
          {filteredEstablishment?.map(estab => (
            <MenuItem key={estab.id} value={estab.id}>
              {estab.short_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl component="fieldset" fullWidth>
        <TextField
          name="shortDescription"
          value={shortDescription}
          onChange={event => setShortDescription(event.target.value)}
          label={t('APP.HOLDING.SETUP.TEXT_FIELD.LABEL.SHORT_DESCRIPTION')}
          margin="normal"
          variant="outlined"
          fullWidth
          required
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            maxLength: 230,
          }}
          multiline
        />
      </FormControl>
      <FormControl margin="normal" component="fieldset" fullWidth required>
        <InputLabel
          variant="outlined"
          shrink
          className={classes.selectLabel}
          style={{
            zIndex: '1011',
          }}
        >
          {t('APP.HOLDING.SETUP.TEXT_FIELD.LABEL.ADRESS')}
        </InputLabel>
        <AutoCompletePlaces
          onSelect={value => {
            setFormattedAddress(value.formatted_address);
            setLatitude(value.coordinates?.lat);
            setLongitude(value.coordinates?.lng);
            setLocality(value.locality);
          }}
          onClear={() => {
            setFormattedAddress('');
            setLatitude('');
            setLongitude('');
            setLocality('');
          }}
          placeHolder={t('APP.ACTIVITY.ADDRESS.PLACE_HOLDER')}
          defaultValue={formattedAddress}
          id="holdingAddress"
        />
      </FormControl>
      <PhoneControl
        phone={phone}
        phoneError={phoneError}
        countryCode={countryCode}
        setPhone={setPhone}
        setPhoneError={setPhoneError}
        setCountryCode={setCountryCode}
      />
      <FormControl component="fieldset" fullWidth>
        <TextField
          required={false}
          name="email"
          value={email}
          type="text"
          onChange={event => setEmail(event.target.value)}
          label={t('APP.HOLDING.SETTINGS.EMAIL')}
          margin="normal"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
      <FormControl component="fieldset" fullWidth>
        <TextField
          name="externalLink"
          value={externalLink}
          onChange={event => setExternalLink(event.target.value)}
          label={t('APP.HOLDING.SETUP.TEXT_FIELD.LABEL.EXTERNAL_LINK')}
          margin="normal"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend" className={classes.formLabel}>
          <Typography variant="caption" component="div">
            {t('APP.HOLDING.SETUP.TEXT_FIELD.PLACEHOLDER.AVATAR')}
          </Typography>
        </FormLabel>
        <Badge
          overlap="circular"
          className={classes.badge}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <label htmlFor="avatar-file">
                <input
                  // accept="image/*"
                  accept=".png, .jpeg, .webp"
                  className={classes.input}
                  style={{ display: 'none' }}
                  id="avatar-file"
                  type="file"
                  onChange={e => handleLoadAvatar(e)}
                />
                <IconButton
                  className={classes.cameraButton}
                  aria-label="upload picture"
                  component="span"
                  size="medium"
                >
                  <PhotoCamera />
                </IconButton>
              </label>
            </div>
          }
        >
          <WelloAvatar avatarUrl={logoPreview} avatarClass={classes.avatar} />
        </Badge>
      </FormControl>
      {/* <FormControl component="fieldset" fullWidth>
          <TextField
            name="agreementCertificate"
            value={agreementCertificate}
            onChange={event => setAgreementCertificate(event.target.value)}
            label={t('APP.HOLDING.SETUP.TEXT_FIELD.LABEL.AGREEMENT_CERTIFICATE')}
            margin="normal"
            variant="outlined"
            fullWidth
            multiline
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl component="fieldset" fullWidth>
          <TextField
            name="paymentMethods"
            value={paymentMethods}
            onChange={event => setPaymentsMethods(event.target.value)}
            label={t('APP.HOLDING.SETUP.TEXT_FIELD.LABEL.PAYMENT_METHODS')}
            margin="normal"
            variant="outlined"
            fullWidth
            multiline
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl> */}
      <Divider light className={classes.divider} />
      {/* <FormControl component="fieldset" className={classes.formControl} fullWidth>
        <PathologiesSettings
          onChangePathologies={ids => setHoldingPathologyIds(ids)}
          pathologiesIds={holdingPathologyIds}
        />
      </FormControl> */}
      <FormControl component="fieldset" fullWidth margin="normal">
        <Typography variant="h4" align="left" gutterBottom>
          {t('APP.HOLDING.SETTINGS.GENERAL.APPLICATION')}
        </Typography>
        <FormGroup>
          {possibleApplicationRoles.map(role => (
            <Grid key={role} style={{ display: 'flex' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={openedApplicationRoles.includes(role)}
                    onChange={handleCheckRole}
                    name={role}
                    color="primary"
                    disabled={role === Roles.PRIMARY}
                  />
                }
                label={t(`APP.ROLE.VALIDATED.${role.toUpperCase()}`)}
              />

              <Tooltip
                title={t(`APP.HOLDING.SETTINGS.GENERAL.APPLICATION.${role.toUpperCase()}`)}
                placement="bottom"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Grid>
          ))}
        </FormGroup>
      </FormControl>
      <FormControl component="fieldset" fullWidth margin="normal">
        <FormLabel component="legend" className={classes.formLabel}>
          <Typography variant="caption" component="div">
            {t('APP.HOLDING.SETUP.TEXT_FIELD.LABEL.CHARTER')}
          </Typography>
        </FormLabel>
        <FilePond
          className={classes.filepond}
          files={charters}
          maxFiles={1}
          allowMultiple={false}
          instantUpload={false}
          credits={('', '')}
          labelIdle={t('APP.USER.ONE_ATTACHMENT_FIELD.PLACEHOLDER')}
          onupdatefiles={fileItems => {
            setCharters(fileItems.map(fileItem => fileItem.file));
          }}
        />
      </FormControl>

      <Grid style={{ textAlign: 'center' }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isInvalidForm || isFetching}
        >
          {isFetching ? <CircularProgress size={24} /> : t('SEND')}
        </Button>
      </Grid>
    </form>
  );
};

export default HoldingForm;
